import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
} from "../../modUtils/apis/httpConfig";
import {
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
  decodeToken,
} from "../../modUtils/functions/handleTokens";

//create_tokens_from_email Permet de créer les tokens de connexion à partir de l'email et le password
export const create_tokens_from_email = createAsyncThunk(
  "mod_authentication/create_tokens_from_email",
  async (values, { rejectWithValue }) => {
    try {
      const response = await httpPost(
        `/mod_authentication/tokens/from_email/`,
        values
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);
//create_tokens_from_refresh permet de créer les tokens de connexion à partir du refresh token
export const create_tokens_from_refresh = createAsyncThunk(
  "mod_authentication/create_tokens_from_refresh",
  async (value, { rejectWithValue }) => {
    let refresh;
    try {
      if (value) {
        refresh = value;
      } else {
        refresh = getRefreshToken();
      }
      const response = await httpPost(
        `/mod_authentication/tokens/from_refresh/`,
        { refresh }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

// get_user_details retourne les détails d'un utilisateur à partir de son userId
export const get_user_details = createAsyncThunk(
  "mod_authentication/get_user_details",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await httpGet(`/mod_authentication/users/${userId}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

// delete_users_password Lance la réinitialisation du mot de passe
export const delete_users_password = createAsyncThunk(
  "mod_authentication/delete_users_password",
  async (email, { rejectWithValue }) => {
    try {
      const response = await httpDelete(
        `/mod_authentication/users/passwords/`,
        {
          data: { email: email },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

// modifier mot de passe utilisateur avec ancien mot de passe
export const update_user_password = createAsyncThunk(
  "mod_authentication/update_user_password",
  async (values, { rejectWithValue }) => {
    try {
      const response = await httpPut(
        `mod_authentication/users/${values.userId}/password/`,
        {
          previous_password: values.previousPassword,
          next_password: values.nextPassword,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

const authenticationSlice = createSlice({
  name: "authentication",
  initialState: {
    user: {},
    loading: true,
    // requestError: null,
    // requestStatus: null
  },
  extraReducers: (builder) => {
    builder
      .addCase(create_tokens_from_email.fulfilled, (state, action) => {
        state.loading = false;
        setRefreshToken(action.payload?.refresh);
        setAccessToken(action.payload?.access);
        var jwt_token_decoded = decodeToken(action.payload?.access);
        localStorage.setItem("user", jwt_token_decoded.user_id);
      })
      .addCase(create_tokens_from_email.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(create_tokens_from_refresh.fulfilled, (state, action) => {
        state.loading = false;
        setRefreshToken(action.payload.refresh);
        setAccessToken(action.payload.access);
        var jwt_token_decoded = decodeToken(action.payload.access);
        localStorage.setItem("user", jwt_token_decoded.user_id);
      })
      .addCase(create_tokens_from_refresh.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(get_user_details.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(get_user_details.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(update_user_password.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(update_user_password.rejected, (state, action) => {
        state.loading = false;
        // state.requestError = action.payload.data.detail
        // state.requestStatus = action.payload.status
      })
      .addCase(delete_users_password.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(delete_users_password.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default authenticationSlice.reducer;
