import React from "react";
import BuildingPage from "ck_commun/src/app/modUtils/components/errorPage/BuildingPage";

export default function AccessGroup() {
  return (
    <div className="p-4">
      <BuildingPage />
    </div>
  );
}
