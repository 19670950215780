import React , {FC} from "react";
import { ReactComponent as BuildingPageSvg } from '../../assets/images/building-page.svg';

const BuildingPage : FC = () => {
    return (
        <div className="grid mt-40 bg-white">
            <div className="text-center flex items-center flex-col">
                <BuildingPageSvg className={`max-w-sm`}/>
                <h2 className="mt-10 text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Page en cours de création
                </h2>
                <p className="mt-4 text-xl text-gray-500">Cette fonctionnalité arrive bientot sur votre application !</p>
            </div>
        </div>
    );
};

export default BuildingPage;