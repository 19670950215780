import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CKToolTip from "../../../modUtils/components/CKToolTip/CKToolTip";
import { useTranslation } from "react-i18next";
import { eEquipmentType } from "ck_commun/src/commun_global/enums.js";
import arrowIcon from "../../../modUtils/assets/images/arrowIcon.svg";
import { ReactComponent as VPNOn } from '../../../modUtils/assets/images/VPN-On.svg';
import { Spinner } from "../../../modUtils/components/componentsLibrary/componentsLibrary";
import { ReactComponent as CheckIcon } from '../../../modUtils/assets/images/checkIcon.svg';
import { ReactComponent as NotCheckIcon } from '../../../modUtils/assets/images/notCheckIcon.svg';
import { create_access_link } from "../../../modEquipment/slices/equipmentSlice";
import ButtonDisabled from "../../../modUtils/components/buttons/ButtonDisabled";
import Button from "../../../modUtils/components/buttons/Button";
import { hasPermission } from "../../../modUtils/functions/handlePermissions";

export default function SharedEquipmentList({ equipment, loading }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [url, setUrl] = useState(null);

     // permissions
     const { selectedGroup } = useSelector((state) => state.manager);
     const permissions = selectedGroup?.permissions;
     const verifyPermission = hasPermission('mod_equipment:can_access', permissions);
     const groupPermissions = selectedGroup?.plan?.permissions;
     const verifyGroupPermission = hasPermission('mod_equipment:can_access', groupPermissions);

    async function fetchUrl() {
        try {
            // Dispatch the create_access_link API call to create the access link
            const response = await dispatch(
                create_access_link({
                    groupId: -1,
                    equipmentId: equipment.id
                })
            );
            const url = response.payload.url;
            setUrl(url);
            // redirect to the URL
            window.open(url, '_blank');
        } catch (error) {
          console.error("Error handling connection to server:", error);
        }
    };

    function handleConnectionToServer() {
        if (url) {
            window.open(url, '_blank');
        }else {
            fetchUrl();
        }
    };

    return (
        <>
        {loading ? (
            <Spinner/>
        ) : (
            <div className="border-solid border-[0.5px] border-primary-color rounded-[3px] mb-5">
                <div key={equipment?.id} className="flex items-start justify-between flex-wrap p-[10px]">
                    {/* equipment type */}
                    <div className="flex flex-row items-center truncate w-[200px]">
                        <div className="flex justify-center mr-2.5 min-w-[30px] min-h-[30px]">
                            <img src={equipment?.equipment_type_image}/>
                        </div>
                        <span className="font-semibold">
                            {t(eEquipmentType[equipment?.type])}
                        </span>
                    </div>

                    {/* serial number */}
                    <div className="truncate w-[110px]">
                        <ul className="list-none text-xs leading-[17px] text-left font-semibold m-0 mr-2.5">
                            <li className="text-primary-color">
                                {t("sharedEquipmentList.serialNumber")}
                            </li>

                            <li>
                                {equipment?.serial_number ? equipment?.serial_number : t("sharedEquipmentList.unknown")}
                            </li>
                        </ul>
                    </div>

                    {/* hardware */}
                    <div className="truncate w-[110px]">
                        <ul className="list-none text-xs leading-[17px] text-left font-semibold m-0 mr-2.5">
                            <li className="text-primary-color">
                                {t("sharedEquipmentList.material")}
                            </li>

                            <li>
                                {equipment?.hardware_id ? (
                                    <span>
                                        {equipment?.hardware_id}&nbsp;
                                        {equipment?.hardware_version && equipment?.hardware_version}
                                    </span>
                                ) : (
                                    <span>{t("sharedEquipmentList.unknown")}</span>
                                )}  
                            </li>
                        </ul>
                    </div>
                    
                    {/* software */}
                    <div className="truncate w-[110px]">
                        <ul className="list-none text-xs leading-[17px] text-left font-semibold m-0 mr-2.5">
                            <li className="text-primary-color">
                                {t("sharedEquipmentList.software")}
                            </li>
                            
                            <li>
                                {equipment?.software_id ? (
                                    <span>
                                        {equipment?.software_id}&nbsp;
                                        {equipment?.software_version && equipment?.software_version}
                                    </span>
                                ) : (
                                    <span>{t("sharedEquipmentList.unknown")}</span>
                                )}
                            </li>
                        </ul>
                    </div>
                    
                    {/* VPN */}
                    <div className="flex flex-col justify-center items-center mr-2.5">
                        <div className="h-[30px] w-[30px]">
                            {equipment?.ck_vpn_active && (
                                <CKToolTip 
                                    placement="topLeft"
                                    trigger="hover"
                                    content={t("sharedEquipmentList.usesCertificate")}
                                >
                                    <VPNOn/>
                                </CKToolTip>
                            )}
                        </div>
                    </div>
                    
                    {/* connected */}
                    <div className="w-[120px]">
                        {equipment?.is_connected ?
                            <CKToolTip placement="topLeft" trigger="hover" content={t("sharedEquipmentList.communicate")}>
                                <div className="flex flex-row items-center mr-2.5 h-8">
                                    <CheckIcon/>
                                    <span className={`ml-1 text-success-color`}>{t("sharedEquipmentList.connected")}</span>
                                </div>
                            </CKToolTip>
                        :
                            <CKToolTip placement="topLeft" trigger="hover" content={t("sharedEquipmentList.notCommunicate")}>
                                <div className="flex flex-row items-center mr-2.5 h-8">
                                    <NotCheckIcon/>
                                    <span className={`ml-1 text-error-color`}>{t("sharedEquipmentList.disconnected")}</span>
                                </div>
                            </CKToolTip>
                        }
                    </div>

                    {/* button connexion */}
                    <div className="w-[140px]">
                        {equipment?.connection_is_configured && verifyPermission && verifyGroupPermission ? (
                            <Button
                                onClick={() => handleConnectionToServer()}
                                color="primary"
                            >
                                <span>
                                    {t("sharedEquipmentList.connection")}
                                    <img
                                        className="rotate-180 h-2.5 w-[5px] ml-2.5"
                                        src={arrowIcon}
                                        alt="Arrow icon"
                                    />
                                </span>
                            </Button>
                        ) : (
                            <CKToolTip 
                                placement="topLeft"
                                trigger="click"
                                content={t("sharedEquipmentList.equipmentIsDisconnected")}
                            >
                                <ButtonDisabled onClick={equipment?.connection_is_configured && verifyPermission ? () => handleConnectionToServer() : null}>
                                    <span>
                                        {t("sharedEquipmentList.connection")}
                                        <img
                                            className="rotate-180 h-2.5 w-[5px] ml-2.5"
                                            src={arrowIcon}
                                            alt="Arrow icon"
                                        />
                                    </span>
                                </ButtonDisabled>
                            </CKToolTip>
                        )}
                    </div>
                </div>
            </div>
        )}
        </>
    );
};