import React from 'react';
import Input from './Input';

export default function InputSearch({id, placeholder}) {
    
    return (
        <div className={`
            flex flex-row items-center justify-between relative cursor-pointer
            border border-solid border-[#A8C4E5] rounded-md bg-[#F7F9FC]
            mt-6 mr-2.5 mb-4 px-4 py-2 w-48 text-base`}
        >
            <Input
                id={id}
                placeholder={placeholder}
                style={{all: "unset", width: "130px"}}
            />

            <img
                src={process.env.PUBLIC_URL + "/img/design/zoom.svg"}
                alt="zoom"
            />
        </div>
    );
};