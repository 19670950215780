import { useRef } from "react";

export default function useScrollHandler(onScrollEnd) {
  const tableRef = useRef();

  const handleScroll = () => {
    const tableElement = tableRef.current;
    if (
      tableElement.scrollTop + tableElement.clientHeight >=
        tableElement.scrollHeight - 10
    ) {
      onScrollEnd();
    }
  };

  return { tableRef, handleScroll };
}
