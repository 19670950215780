import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpGet } from "../../modUtils/apis/httpConfig";

// récuperer le cache météo d'un site pour le jour actuel, on définis le nombre de jours que l'on souhaite récuperer dans le param forecast_day_nb
export const get_weather_by_site_list = createAsyncThunk(
  "mod_weather/get_weather_by_site_list",
  async (values, { rejectWithValue }) => {
    try {
      const response = await httpGet(
        `/mod_weather/site/${values.siteId}/weather_cache/`,
        {
          params: {
            limit: 100,
            offset: 0,
            forecast_day_nb: values.forecastDayNb,
          },
        }
      );
      return response.data.results;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

const weatherSlice = createSlice({
  name: "weather",
  initialState: {
    siteWeather: [],
    loading: true,
  },
  extraReducers: (builder) => {
    builder
      // get_weather_by_site_list
      .addCase(get_weather_by_site_list.fulfilled, (state, action) => {
        state.loading = false;
        state.siteWeather = action.payload;
      })
      .addCase(get_weather_by_site_list.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default weatherSlice.reducer;
