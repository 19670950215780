import React from 'react';
import Option from './Option';
import OptionsList from './OptionsList';

export default function Options({options, id, onClick}) {

    return (
        <OptionsList>
            {options.length === 1 && options[0]['name'] === "no data" ? (
                <Option
                    className={`cursor-not-allowed`}
                >
                    No data
                </Option>
            ) : (
                <>
                <Option
                    onClick={() => onClick({id: null, name: "Tous"})}
                    className={`cursor-pointer`}
                >
                    Tous
                </Option>
                {options?.map((option) =>
                    <Option
                        onClick={() => onClick(option)}
                        className={`cursor-pointer`}
                    >
                        {option.name}
                    </Option>
                )}
                </>
            )}
            
            <input id={id}
                className="hidden"
            />
        </OptionsList>
    );
};