import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Error404Svg } from "../assets/images/error404.svg";

const Error404Page: FC<{}> = ({}) => {
  const navigate = useNavigate();

  return (
    <div className="grid bg-white">
      <div className="text-center mt-20 flex items-center flex-col">
        <Error404Svg className={`max-w-sm`} />
        <h2 className="mt-10 text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Erreur 404
        </h2>
        <p className="mt-2 text-xl text-gray-500">
          La page recherchée est introuvable
        </p>

        <button
          onClick={() => navigate("/home")}
          className="bg-primary-color text-xl text-white border-none font-bold p-4 mt-2 rounded cursor-pointer shadow-xl items-center hover:bg-primary-color-600"
        >
          <span>Retour vers l'accueil</span>
        </button>
      </div>
    </div>
  );
};

export default Error404Page;
