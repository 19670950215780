import React from 'react';
import { Form, Input, Radio, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import csvExportSvg from "../../assets/images/exportButtons/csvExport.svg";
// import pdfExportSvg from "../../assets/images/exportButtons/pdfExport.svg";
// import xlsExportSvg from "../../assets/images/exportButtons/xlsExport.svg";

export default function ExportModalForm({onChangeFileType}) {
    const { t } = useTranslation();

    return (
        <Form
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 16 }}
            layout="vertical"
        >
            {/* <Form.Item
                label={t('exportData.transExportNumber')}
                name="exportNumber"
            >
                <Input/>
            </Form.Item> */}

            {/* <span>{t('exportData.transExportDuration')}</span> */}

            <Form.Item
                name="radio-group"
                label={t('exportData.transExportType')}
            >
                <Radio.Group onChange={onChangeFileType} defaultValue="csv">
                    {/* <div className="flex items-center my-2.5">
                        <img
                            src={imgSrc + 'xlsExport' + extImg}
                            alt="img"
                            className="mx-5"
                        />

                        <Radio value="xls">
                            {t('exportData.transExportTypeXls')}
                        </Radio>
                    </div> */}

                    <div className="flex items-center my-2.5">
                        <img
                            src={csvExportSvg}
                            alt="img"
                            className="mx-5"
                        />
                        <Radio value="csv">
                            {t('exportData.transExportTypeCsv')}
                        </Radio>
                    </div>

                    {/* <div className="flex items-center my-2.5">
                        <img
                            src={pdfExportSvg}
                            alt="img"
                            className="mx-5"
                        />

                        <Radio value="pdf">
                            {t('exportData.transExportTypePdf')}
                        </Radio>
                    </div> */}
                </Radio.Group>
            </Form.Item>

            {/* <Form.Item
                name="radio-group"
                label={t('exportData.transExportMode')}
            >
                <Radio.Group onChange={onChangeFileType} defaultValue="">
                    <Radio value="exportModeDownload">
                        {t('exportData.transExportModeDownload')}
                    </Radio>

                    <Radio value="exportModeEmail">
                        {t('exportData.transExportModeMail')}
                    </Radio>
                </Radio.Group>
            </Form.Item> */}

            {/* <span>{t('exportData.transExportModalNote')}</span> */}
        </Form>
    );
};