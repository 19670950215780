import moment from "moment";

//la fonction setMomentLocale met à jour moment.locale() selon la langue choisie dans le switcher du rightSiderBar
export function setMomentLocale(){
if(localStorage.getItem("i18nextLng")=="fr") moment.locale("fr")
else if (localStorage.getItem("i18nextLng")=="en") moment.locale("en")
}
/**
 *la fonction dateFormat retourne, à partir d'un @param timestamp , la date sous format @param format s'il est passé à la fonction sinon le format sera par défaut DD/MM/yyyy HH:mm , 
  ** si timestamp est null la fonction retourne une chaine vide
 */
export function dateFormat(timestamp, format) {
  const formatDate = format || 'DD/MM/yyyy HH:mm'
  if (timestamp == null) return "";
  else {
    const d = parseInt(timestamp);
    return moment.unix(d).format(formatDate);
  }
}

/**
 *la fonction dureeFormat retourne, à partir d'un @param timestamp , la durée passée entre la date passée en paramètres et la date actuelle
  ** si timestamp est null la fonction retourne une chaine vide
 */
  export function dureeFormat(timestamp) {
    if (timestamp === null) return "";
    else {
      const d = parseInt(timestamp);
      const momentDuration = moment.duration(moment().diff(moment.unix(d)));
      const jours = Math.floor(momentDuration.asDays());
      const heures = Math.floor(momentDuration.asHours() % 24);
      return `${jours}${localStorage.getItem("i18nextLng")=="en" ? "D" : "J"} ${(heures > 0 ? heures + 'H' : '')}`;
    }
  }

/**
 *la fonction getDateSubtractedFromDateSource prend comme paramètre  @param dateSource de type timestamp et  @param numberOfDays de type int et retourne la date correspondante au calcul ( dateSource - numberOfDays )
 ** si timestamp est null la fonction retourne une chaine vide
 */
export function getDateSubtractedFromDateSource(dateSource, numberOfDays) {
  if (dateSource === null) return "";
  if(numberOfDays == 0) return dateSource;
  else {
    const d = moment.unix(parseInt(dateSource)).subtract(numberOfDays, 'days');
    const substractedDate = moment(d).unix()
    return substractedDate;
  }
}



