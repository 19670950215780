import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Line, Pie } from "react-chartjs-2";
import { amountFormat } from "../../modUtils/functions/numberFormat";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
//* debut chiffre d'affaire global
export const barOptions1 = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
    // title: {
    //   display: true,
    //   text: "NOMBRE DE NOUVEAUX BADGES (MENSUEL)",
    // },
  },
};

const barLabels1 = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
];
const barDatasetGlobal1 = [10, 20, 30, 40, 50, 60, 70];
const barDatasetGlobal2 = [70, 60, 50, 40, 30, 10];
export const barData1 = {
  labels: barLabels1,
  datasets: [
    {
      label: "Période prédéfinie",
      data: barDatasetGlobal1,
      backgroundColor: "#005DFF",
    },
    {
      label: "Période prédéfinie -1",
      data: barDatasetGlobal2,
      backgroundColor: "#C2C2C2",
    },
  ],
};
//* end chiffre d'affaire global

//* debut activité moyenne par heure
const lineOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },

    // title: {
    //   display: true,
    //   text: "EVOLUTION DU MONTANT TOTAL DES CRÉDITS (MENSUEL)",
    // },
  },
  elements: {
    line: {
      tension: 0.5,
    },
  },
};

const lineLabels = [
  "00h",
  "1h",
  "2h",
  "3h",
  "4h",
  "5h",
  "6h",
  "7h",
  "8h",
  "9h",
  "10h",
  "11h",
  "12h",
  "13h",
  "14h",
  "15h",
  "16h",
  "17h",
  "18h",
  "19h",
  "20h",
  "21h",
  "22h",
  "23h",
];
const lineDatasetOp1 = [
  10, 200, 30, 450, 150, 60, 70, 50, 50, 70, 150, 500, 100, 100, 200, 300, 450,
  250, 60, 90, 100, 150, 230, 500,
];
const lineDatasetOp2 = [
  50, 50, 70, 150, 500, 100, 450, 150, 60, 70, 50, 50, 70, 150, 500, 10, 200,
  30, 450, 150, 60, 70, 50, 50,
];
const lineDatasetOp3 = [
  100, 200, 300, 450, 250, 60, 90, 10, 200, 30, 450, 150, 60, 70, 50, 50, 450,
  150, 60, 70, 50, 50, 70, 150,
];
export const lineData = {
  labels: lineLabels,
  datasets: [
    {
      label: "Tous les jours",
      data: lineDatasetOp1,
      borderColor: "#004CFF",
      backgroundColor: "#004CFF",
    },
    {
      label: "Du lundi au vendredi",
      data: lineDatasetOp2,
      borderColor: "#01A73E",
      backgroundColor: "#01A73E",
    },
    {
      label: "Les weekends",
      data: lineDatasetOp3,
      borderColor: "#FFC857",
      backgroundColor: "#FFC857",
    },
  ],
};

//* end activité moyenne par heure

//* debut activité moyenne par jour
export const barOptions2 = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
};

//* end  activité moyenne par jour

//* debut répartition chiffre d'affaire par site
export const pieoptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "left",
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          const label = context.label || "";
          const value = context.parsed || 0;
          const total = context.dataset.data.reduce(
            (acc, current) => acc + current,
            0
          );
          const percentage = amountFormat((value / total) * 100) + "%";
          return `${label}: ${value} (${percentage})`;
        },
      },
    },
  },
};
//* fin répartition chiffre d'affaire par site

export default function StatisticsGlobal({
  dataStatisticGlobalBySites,
  dataStatisticGlobalByDay,
}) {
  //REPARITION CHIFFRE D'AFFAIRE PAR SITE
  const [statisticGlobalBySitesLabels, setStatisticGlobalBySitesLabels] =
    useState([]);
  const [statisticGlobalBySitesData, setStatisticGlobalBySitesData] = useState(
    []
  );

  useEffect(() => {
    if (dataStatisticGlobalBySites) {
      const newChartLabels = [];
      const newChartData = [];
      [...dataStatisticGlobalBySites].forEach((item) => {
        newChartLabels.push(item.site);
        newChartData.push(item.total_global);
      });
      setStatisticGlobalBySitesLabels(newChartLabels);
      setStatisticGlobalBySitesData(newChartData);
    }
  }, [dataStatisticGlobalBySites]);

  const piedata = {
    labels: statisticGlobalBySitesLabels,
    datasets: [
      {
        data: statisticGlobalBySitesData,
        backgroundColor: ["#FF7F00", "#005DFF", "#01A73E"],
        borderColor: ["#FF7F00", "#005DFF", "#01A73E"],
        borderWidth: 1,
      },
    ],
  };
  //ACTIVITE MOYENNE PAR JOUR
  const [statisticGlobalByDayLabels, setStatisticGlobalByDayLabels] = useState(
    []
  );
  const [statisticGlobalByDayData, setStatisticGlobalByDayData] = useState([]);
  useEffect(() => {
    if (dataStatisticGlobalByDay) {
      const newChartLabels = [];
      const newChartData = [];
      [...dataStatisticGlobalByDay].forEach((item) => {
        newChartLabels.push(item.day);
        newChartData.push(item.total);
      });
      setStatisticGlobalByDayLabels(newChartLabels);
      setStatisticGlobalByDayData(newChartData);
    }
  }, [dataStatisticGlobalByDay]);

  const barData2 = {
    labels: statisticGlobalByDayLabels,
    datasets: [
      {
        data: statisticGlobalByDayData,
        backgroundColor: ["#FF7F00"],
        borderColor: ["#FF7F00"],
        borderWidth: 1,
      },
    ],
  };
  return (
    <div>
      <div>
        <div className="APP_h1_text">STATISTIQUE GLOBALE</div>
        <div className="flex-display flex-row justify-content-between">
          <div className="chart-container" style={{ width: "49%" }}>
            <div className="chart-title">CHIFFRE D'AFFAIRE GLOBAL</div>
            <Bar options={barOptions1} data={barData1} />
          </div>
          <div className="chart-container" style={{ width: "49%" }}>
            <div className="chart-title">
              REPARITION CHIFFRE D'AFFAIRE PAR SITE
            </div>
            <Pie options={pieoptions} data={piedata} />
          </div>
        </div>

        <div className="flex-display flex-row justify-content-between ">
          <div className="chart-container" style={{ width: "49%" }}>
            <div className="chart-title">ACTIVITE MOYENNE PAR JOUR</div>
            <Bar options={barOptions2} data={barData2} />
          </div>
          {/* <div className="chart-container" style={{ width: "49%" }}>
            <div className="chart-title">ACTIVITE MOYENNE PAR HEURE</div>
            <Line options={lineOptions} data={lineData} />
          </div> */}
        </div>
      </div>
      {/* <div className="chart-container" style={{ width: "100%" }}>
        <div className="chart-title">CHIFFRE D'AFFAIRES REEL</div>
        <table></table>
      </div> */}
    </div>
  );
}
