import React from 'react';
import Spinner from "../spinner/Spinner";

export default function AlertMessage({statusMessage, defaultMessage, loading}) {

    function setStyleAlert(color) {
        const colorList = ["success", "error"];
        let style = "border px-4 py-3 rounded relative mb-5 mt-2.5";
    
        if (colorList.includes(color)) {
            style = style + " bg-" + color + "-color-100 border-" + color + "-color-400 text-" + color + "-color-700";
        }
        
        return style;
    };

    return (
        <>
        {loading ? 
            <Spinner/> 
        :
            statusMessage !== null && 
                <div class={`${setStyleAlert(statusMessage)}`}> 
                    <span>{defaultMessage}</span>
                </div>
        }
        </>
    );
};