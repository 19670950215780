import React from "react";
import { Route } from "react-router-dom";
import ActivityCA from "../pages/activities/ActivityCA";
import ActivityDashboard from "../pages/activities/ActivityDashboard";
import ActivityMarketing from "../pages/activities/marketing/ActivityMarketing";
import ActivityReporting from "../pages/activities/reporting/ActivityReporting";
import ActivityStatistics from "../pages/activities/statistics/ActivityStatistics";
import CkRoutes from "ck_commun/src/app/modUtils/routes/CkRoutes";

export default function ActivitesRoutes() {
  return (
    <CkRoutes>
      <Route path="dashboard" element={<ActivityDashboard />} />
      <Route path="ca" element={<ActivityCA />} />
      <Route path="marketing" element={<ActivityMarketing />} />
      <Route path="reporting" element={<ActivityReporting />} />
      <Route path="statistics" element={<ActivityStatistics />} />
    </CkRoutes>
  );
}
