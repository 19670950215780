/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/machines/e_machines_operating_status.py
Aucune modification manuelle acceptée
*/

// Statut fonctionnel d'une machine
const eMachinesOperatingStatus = Object.freeze({
  // Non installé
  0: 'eMachinesOperatingStatus.notInstalled',

  // Non défini
  1: 'eMachinesOperatingStatus.notDefined',

  // Hors Service
  2: 'eMachinesOperatingStatus.hs',

  // Fermé
  3: 'eMachinesOperatingStatus.close',

  // Vérification avant échec
  4: 'eMachinesOperatingStatus.checkBeforeFailed',

  // En Attente
  5: 'eMachinesOperatingStatus.ready',

  // En cours d'exécution occupé
  6: 'eMachinesOperatingStatus.runningBusy',

  // Pause occupée
  7: 'eMachinesOperatingStatus.pauseBusy',

  // En Fonctionnement
  8: 'eMachinesOperatingStatus.runningReady',

  // Pause prête
  9: 'eMachinesOperatingStatus.pauseReady',

  // reservée
  10: 'eMachinesOperatingStatus.reserved',
});

export default eMachinesOperatingStatus;
