/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/device/e_device_code.py
Aucune modification manuelle acceptée
*/

// Codes lors de l'envoie des status
// (0 à 49 sont des codes génériques)
const eDeviceCode = Object.freeze({
  // Ok
  0: 'eDeviceCode.ok',

  // Pas prêt
  1: 'eDeviceCode.noOk',

  // En cours
  2: 'eDeviceCode.inProgress',

  // Occupé
  3: 'eDeviceCode.busy',

  // Erreur d'initialisation
  4: 'eDeviceCode.pbInit',

  // Pas de communication
  5: 'eDeviceCode.noComm',

  // Pertes de communication
  6: 'eDeviceCode.lostComm',

  // Pas de réponse
  7: 'eDeviceCode.timeout',

  // Erreur mineur
  8: 'eDeviceCode.minorError',

  // Erreur
  9: 'eDeviceCode.error',

  // Echec de la commande
  10: 'eDeviceCode.commandFailed',

  // Erreur de cycle
  11: 'eDeviceCode.cycleError',

  // Corruption
  12: 'eDeviceCode.corruption',

  // Erreur critique
  13: 'eDeviceCode.criticalError',

  // Attention
  14: 'eDeviceCode.warning',

  // Vide
  15: 'eDeviceCode.empty',

  // Plein
  16: 'eDeviceCode.full',

  // Boucle dans la machine à état
  17: 'eDeviceCode.loopback',

  // Enregistrement refusé
  18: 'eDeviceCode.registrationDenied',

  // Borne fermée
  19: 'eDeviceCode.terminalClose',

  // Erreur de driver
  20: 'eDeviceCode.driverError',

  // CCTALK TCPIP n'a pas trouvé ses esclaves
  21: 'eDeviceCode.notFound',

  // CCTALK TCPIP a trouvé plusieurs esclave sur la même adresse
  22: 'eDeviceCode.manyFound',

  // CksCode en maintenance
  23: 'eDeviceCode.inMaintenance',

  // Fin courrante
  100: 'eDeviceCode.commonEnd',
});

export default eDeviceCode;
