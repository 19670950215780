import React from "react";
import PropTypes from "prop-types";
import "./BrandHeader.scss";

const BrandHeader = ({ logoSrc, appName, collapsed , style={}}) => {
  return (
    <div className="app-name-container flex-display align-items-center" style={{...style.appNameContainer}}>
      <img className="app-logo" src={logoSrc} alt="App Logo" style={{...style.appLogo}}/>
      {!collapsed && <h2 className="app-name" style={{...style.appName}} >{appName}</h2>}
    </div>
  );
};

BrandHeader.defaultProps = {
    collapsed: false, 
  }
  
BrandHeader.propTypes = {
    appName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    logoSrc: PropTypes.string,
    collapsed: PropTypes.bool
  }
export default BrandHeader;
