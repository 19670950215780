import React from 'react';
import { useSelector } from 'react-redux';
import ButtonAdmin from '../buttons/ButtonAdmin';

interface AdminLinkProps {
    label?: string;
    type: string;
    adminPath?: string;
    objectId?: number;
    onClick?: () => void;
    className?: string;
}

interface RootState {
    authentication: {
        user: {
            is_admin: boolean;
        };
    };
}

const AdminLink: React.FC<AdminLinkProps> = ({
    label = "Lien Admin",
    type,
    adminPath = "",
    onClick,
    className = ""
}) => {
    const { user } = useSelector((state: RootState) => state.authentication);
    const userIsAdmin = user && user.is_admin === true;

    const openAdminLink = () => {
        if (typeof onClick === "function") {
            onClick();
        }

        const url =`${window.location.origin}/admin/${adminPath}`;
        window.open(url, '_blank');
    };

    return (
        userIsAdmin && (
            type === "button" ? (
                <ButtonAdmin
                    onClick={openAdminLink}
                    className={className}
                >
                    <span>{label}</span>
                </ButtonAdmin>
            ) : (
                <p
                    onClick={openAdminLink}
                    className={`cursor-pointer ${className}`}
                >
                    {label}
                </p>
            )
        )
    );
};

export default AdminLink;