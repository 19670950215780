import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Spinner } from "../componentsLibrary/componentsLibrary";
import ExportButton from '../buttons/exportButton/ExportButton';
import ExportModalFilter from './ExportModalFilter';
import ExportModalForm from './ExportModalForm';

export default function ExportModal({selectForm, getExport, loading, onChangeFileType}) {
    const [modalVisible, setModalVisible] = useState(false);
    const { t } = useTranslation();
    
    return (
        <>
        <ExportButton
            onClick={() => setModalVisible(true)}
        />

        <Modal
            title={t('exportData.transExportModalTitle')}
            isOpen={modalVisible}
            onClose={() => setModalVisible(false)}
            onOk={()=> getExport()}
            onCancel={()=> setModalVisible(false)}
            okText={t('common.transButtonExport')}
        >
            {loading ? 
                <Spinner />
             : 
                <>
                    <ExportModalFilter
                        selectForm={selectForm}
                    />
                    <ExportModalForm
                        onChangeFileType={onChangeFileType}
                    />
                </>
            }
        </Modal>
        </>
    )
};