import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { update_user_password } from "../slices/authenticationSlice";
import { Form, Input, message } from "antd";
import {
  Spinner,
  Button,
} from "../../modUtils/components/componentsLibrary/componentsLibrary";
import SubmitButton from "../../modUtils/components/buttons/submitButton/SubmitButton";
import AlertMessage from "../../modUtils/components/AlertMessage";
import FormLegend from "../../modUtils/components/FormLegend";

const passwordInputTooltip = (
  <ul>
    <li>- Minimum 8 caractères requis.</li>
    <li>- Ne peut pas être entièrement numérique.</li>
    <li>- Évitez les mots de passe courants.</li>
  </ul>
);
// Fonction de validation personnalisée pour vérifier si le mot de passe n'est pas entièrement numérique
const validatePassword = (_, value) => {
  if (/^\d+$/.test(value)) {
    return Promise.reject(
      "Le mot de passe ne doit pas être entièrement numérique"
    );
  } else {
    return Promise.resolve();
  }
};
//les régles de validations communes pour le champs mot de passe
const commonPasswordRules = [
  {
    required: true,
    message: "Veuillez entrer votre nouveau mot de passe",
  },
  { validator: validatePassword },
  {
    min: 8,
    max: 68,
    message: "Le mot de passe doit contenir au moins 8 caractères",
  },
];

function PasswordUpdateForm() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem("user");

  const updateUserPassword = async (values) => {
    try {
      setLoading(true);
      await dispatch(update_user_password(values)).unwrap();
      setLoading(false);
      message.success({
        content: (
          <AlertMessage alertMessage="Mot de passe mis à jour avec succès!" />
        ),
      });
    } catch (error) {
      let detail=error.data.detail
      message.error({
        content: (
          <AlertMessage
            status={error.status}
            alertMessage="Modification de mot de passe échouée!"
            errorDetail={
              <div className="flex flex-col justify-start mt-4">
                {!detail.previous_password &&
                  !detail.next_password && (
                    <div className="text-md font-bold text-error-color">
                      {detail}
                    </div>
                  )}
                {detail?.previous_password && (
                  <div>
                    <div className="text-md font-bold">
                      Mot de passe actuel :
                      <div />
                      <ul className="list-outside	list-disc text-error-color text-sm font-normal text-start">
                        {detail?.previous_password?.map((msg) => (
                          <li>{msg}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
                {detail?.next_password && (
                  <div>
                    <div className="text-md font-bold">
                      Nouveau mot de passe :
                      <div />
                      <ul className="list-outside	list-disc text-error-color text-sm font-normal text-start">
                        {detail?.next_password?.map((msg) => (
                          <li>{msg}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            }
          />
        ),
      });
      setLoading(false);
      form.resetFields();
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [dispatch, form]);

  const handleSubmit = (values) => {
    updateUserPassword({
      userId,
      previousPassword: values.previousPassword,
      nextPassword: values.nextPassword,
    });
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Form
          form={form}
          name="passwordUpdateForm"
          autoComplete="off"
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Form.Item
            label="Mot de passe actuel"
            name="previousPassword"
            rules={commonPasswordRules}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Nouveau mot de passe"
            name="nextPassword"
            dependencies={["previousPassword"]}
            tooltip={passwordInputTooltip}
            rules={[
              ...commonPasswordRules,

              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("previousPassword") !== value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "Le nouveau mot de passe ne doit pas être identique à votre mot de passe actuel"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirmation mot de passe"
            name="validationNextPassword"
            dependencies={["nextPassword"]}
            rules={[
              {
                required: true,
                message: "Veuillez confirmer votre nouveau mot de passe",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("nextPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Les mots de passe ne correspondent pas !")
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <FormLegend requiredFormItemLegend={true} />
          <div className="mt-4 flex justify-content-center">
            <Form.Item>
              {/* <SubmitButton label="MODIFIER MOT DE PASSE" /> */}
              <Button htmlType="submit" type="primary">
                MODIFIER MOT DE PASSE
              </Button>
            </Form.Item>
          </div>
        </Form>
      )}
    </>
  );
}

export default PasswordUpdateForm;
