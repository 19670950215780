import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { Form, Radio, Row, Upload } from "antd";
import { Modal, Input } from "../../../modUtils/components/componentsLibrary/componentsLibrary"
import { UploadOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { hasPermission } from "../../../modUtils/functions/handlePermissions";
import PermissionDeniedPage from "../../../modUtils/components/errorPage/PermissionDeniedPage";
//import { updateSite } from "redux/slices/managerSlice"

import ButtonDisabled from "../../../modUtils/components/buttons/ButtonDisabled";
import "./SiteUpdateModal.scss";
import Button from "../../../modUtils/components/buttons/Button";

//const { TextArea } = Input;

export default function SiteUpdateModel({ closeModal, site, mapModal }) {
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const { selectedGroup } = useSelector((state) => state.manager);
  const permissions = selectedGroup?.permissions;
  const verifyPermission = hasPermission('mod_manager:manage_company', permissions);
  const groupPermissions = selectedGroup?.plan?.permissions;
  const verifyGroupPermission = hasPermission('mod_manager:manage_company', groupPermissions);
  // close SiteManageModel when opening SiteUpdateModel
  const openModal = () => {
    setModalVisible(true);
    closeModal();
  };
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      id: site.id,
      name: site.name,
      // adresseSite: site.adres,
      // cpSite: site.cpSite,
      // villeSite: site.villeSite,
      // latitudeSite: site.location_latitude,
      // longitudeSite: site.location_longitude,
      // code: site.code,
      // descriptionSite: site.descriptionSite,
      // photoSite: site.photoSite,
      // meteoSite: site.meteoSite,
      // equipement: site.equipement,
    },
    onSubmit: (values) => {
      console.log(values);
      //dispatch(updateSite(values));
    },
  });
  const normFile = (e) => {

    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  };
  const handleCancel =()=>{
    setModalVisible(false)
  }

    return (
        <>
        {verifyPermission && verifyGroupPermission ?
            <Button
                onClick={openModal}
                color="primary"
                className="h-10 w-full mx-[5px]"
                size="sm"
            >
                {t("site.transUpdateSiteButton")}
            </Button>
        :
            <ButtonDisabled
                onClick={openModal}
                className="h-10 w-full mx-[5px]"
                size="sm"
            >
                {t("site.transUpdateSiteButton")}
            </ButtonDisabled>
        }
      
      <Modal
        title={t("site.transUpdateSiteModalTitle")}
        isOpen={modalVisible}
        onClose={() => setModalVisible(false)}
        onCancel={verifyPermission && handleCancel}
        onOk={verifyPermission && formik.handleSubmit}     
      >
        {!verifyPermission ?
            <PermissionDeniedPage
                permission={verifyGroupPermission}
            />
        :
            <div className="siteUpdateInfos">
            <Input
                name="name"
                label={t("site.transSiteNameLabel")}
                value={site?.name}
                //onChange={formik.handleChange}
            ></Input>
            <Input
                name="adresseSite"
                label={t("site.transSiteAddressLabel")}
                value={site?.location_address?.street_number + site?.location_address?.street}
            // onChange={formik.handleChange}
            />
            <div className="flex-display  justify-content-between">
                <Input
                name="cpSite"
                label={t("site.transSiteCpLabel")}
                value={site?.location_address?.zipcode}
                //onChange={formik.handleChange}
                />
                <Input
                name="villeSite"
                label={t("site.transSiteCityLabel")}
                value={ site?.location_address?.city}
                // onChange={formik.handleChange}
                
                />
            </div>
            <div className="flex-display justify-content-between">
                <Input
                name="latitudeSite"
                label={t("site.transSiteLatLabel")}
                value={site?.location_latitude}
                // onChange={formik.handleChange}
                />
                <Input
                name="longitudeSite"
                label={t("site.transSiteLongLabel")}
                value={site?.location_longitude}
                //onChange={formik.handleChange}
                />
                {/* <FaLocationArrow></FaLocationArrow> */}
            </div>
            <Input
                name="code"
                label={t("site.transSiteCodeLabel")}
                //onChange={formik.handleChange}
            />
            <Form
                name="siteUpdateForm"
                onSubmitCapture={formik.handleSubmit}
                initialValues={formik.values}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
            >
                <div>
                {mapModal && (
                    <div>
                    {/* <Form.Item
                        name="descriptionSite"
                        label={t("site.transSiteDescriptionLabel")}
                    >
                        <TextArea
                        name="descriptionSite"
                        rows={4}
                        onChange={formik.handleChange}
                        />
                    </Form.Item> */}
                    <Form.Item
                        name="photoSite"
                        label={t("site.transSitePhotoLabel")}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                    >
                        <Upload
                        name="photoSite"
                        listType="picture"
                        maxCount={1}
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        >
                        {/* <Upload name="photoSite" listType="picture" maxCount={1} onChange={formik.handleChange} action="https://www.mocky.io/v2/5cc8019d300000980a055e76"> */}
                        <Button icon={<UploadOutlined />}>
                            {t("site.transSiteUploadPhotoButton")}
                        </Button>
                        </Upload>
                    </Form.Item>
                    </div>
                )}

                <Form.Item
                    label={
                    <img
                        className="meteoIcon"
                        src={process.env.PUBLIC_URL + "/img/meteoIcon.png"}
                        alt="img"
                    />
                    }
                >
                    <Radio.Group
                    name="meteoSite"
                    onChange={formik.handleChange}
                    value={formik.values.meteoSite}
                    >
                    <Radio value="false">
                        {t("site.transSiteMeteoOptionFalse")}
                    </Radio>
                    <Radio value="true">
                        {t("site.transSiteMeteoOptionTrue")}
                    </Radio>
                    </Radio.Group>
                </Form.Item>
                </div>
            </Form>
            </div>
        }
      </Modal>
    </>
  );
};