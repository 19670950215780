/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/device/e_device_protocol.py
Aucune modification manuelle acceptée
*/

// Protocole de communication utilisé par le device
const eDeviceProtocol = Object.freeze({
  // Inutilisé
  0: 'eDeviceProtocol.unused',

  // INPUT/OUTPUT
  1: 'eDeviceProtocol.ios',

  // CCTALK
  2: 'eDeviceProtocol.cctalk',

  // MDB
  3: 'eDeviceProtocol.mdb',

  // SÉRIE
  4: 'eDeviceProtocol.serial',

  // USB
  5: 'eDeviceProtocol.usb',

  // TCPIP
  6: 'eDeviceProtocol.tcpip',
});

export default eDeviceProtocol;
