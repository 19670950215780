/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/device/e_device_code_category.py
Aucune modification manuelle acceptée
*/

// Codes erreur pour les catégories (sécheresse, réduction de bruit...)
// 
// On peu pas avoir des meilleurs nom ici ?
// 
// Anciennement EFEATURECID_CATEGORY_ERRORCODE
const eDeviceCodeCategory = Object.freeze({
  // State 1
  100: 'eDeviceCodeCategory.state1',

  // State 2
  101: 'eDeviceCodeCategory.state2',

  // State 3
  102: 'eDeviceCodeCategory.state3',

  // State 4
  103: 'eDeviceCodeCategory.state4',
});

export default eDeviceCodeCategory;
