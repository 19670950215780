
import React , {FC} from "react";

interface MobilePageProps {
    appleStoreLink: string;
    googleStoreLink: string;
    logo: string;
  }

const MobilePage : FC<MobilePageProps> = ({  
    appleStoreLink,
    googleStoreLink,
    logo,
}) => {

    return (
    <section className="bg-white p-4">
        <div className="container flex flex-col items-center px-4 py-12 mx-auto xl:flex-row">
            <img src={logo} className="w-64 h-64" alt="logo" />
            <div className="flex flex-col items-center mt-6 xl:items-start xl:w-1/2 xl:mt-0">
                <h2 className="text-xl font-bold tracking-tight xl:text-4xl text-primary-color-800 text-center">
                    Télécharger notre application sur mobile
                </h2>

                
                <div className="mt-6 sm:-mx-2">
                    <div className="inline-flex w-full overflow-hidden rounded-lg shadow sm:w-auto sm:mx-2">
                        <a href={appleStoreLink} className="inline-flex items-center justify-center w-full px-5 py-3 text-xs font-bold text-white bg-gradient-to-r from-thirty-color-400 to-thirty-color-600 sm:w-auto hover:text-neutral-300">
                        <svg className="w-6 h-6 mx-2 fill-current"  x="0px" y="0px" viewBox="0 0 512 512">
                                <g>
                                    <g>
                                        <path d="M407,0H105C47.103,0,0,47.103,0,105v302c0,57.897,47.103,105,105,105h302c57.897,0,105-47.103,105-105V105
                                            C512,47.103,464.897,0,407,0z M482,407c0,41.355-33.645,75-75,75H105c-41.355,0-75-33.645-75-75V105c0-41.355,33.645-75,75-75h302
                                            c41.355,0,75,33.645,75,75V407z"></path>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path d="M305.646,123.531c-1.729-6.45-5.865-11.842-11.648-15.18c-11.936-6.892-27.256-2.789-34.15,9.151L256,124.166
                                            l-3.848-6.665c-6.893-11.937-22.212-16.042-34.15-9.151h-0.001c-11.938,6.893-16.042,22.212-9.15,34.151l18.281,31.664
                                            L159.678,291H110.5c-13.785,0-25,11.215-25,25c0,13.785,11.215,25,25,25h189.86l-28.868-50h-54.079l85.735-148.498
                                            C306.487,136.719,307.375,129.981,305.646,123.531z"></path>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path d="M401.5,291h-49.178l-55.907-96.834l-28.867,50l86.804,150.348c3.339,5.784,8.729,9.921,15.181,11.65
                                            c2.154,0.577,4.339,0.863,6.511,0.863c4.332,0,8.608-1.136,12.461-3.361c11.938-6.893,16.042-22.213,9.149-34.15L381.189,341
                                            H401.5c13.785,0,25-11.215,25-25C426.5,302.215,415.285,291,401.5,291z"></path>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path d="M119.264,361l-4.917,8.516c-6.892,11.938-2.787,27.258,9.151,34.15c3.927,2.267,8.219,3.345,12.458,3.344
                                            c8.646,0,17.067-4.484,21.693-12.495L176.999,361H119.264z"></path>
                                    </g>
                                </g>
                            </svg>
                            <span className="mx-2">
                                Télécharger sur l'Apple Store
                            </span>
                        </a>
                    </div>

                    <div className="inline-flex w-full mt-4 overflow-hidden rounded-lg shadow sm:w-auto sm:mx-2 sm:mt-0 ">
                        <a href={googleStoreLink} className="inline-flex items-center justify-center w-full px-5 py-3 text-xs font-xs text-white transition-colors duration-150 transform sm:w-auto bg-gradient-to-r from-primary-color-400 to-primary-color-600 hover:text-neutral-300">
                            <svg className="w-6 h-6 mx-2 fill-current" viewBox="-28 0 512 512.00075"><path d="m432.320312 215.121094-361.515624-208.722656c-14.777344-8.53125-32.421876-8.53125-47.203126 0-.121093.070312-.230468.148437-.351562.21875-.210938.125-.421875.253906-.628906.390624-14.175782 8.636719-22.621094 23.59375-22.621094 40.269532v417.445312c0 17.066406 8.824219 32.347656 23.601562 40.878906 7.390626 4.265626 15.496094 6.398438 23.601563 6.398438s16.214844-2.132812 23.601563-6.398438l361.519531-208.722656c14.777343-8.53125 23.601562-23.8125 23.601562-40.878906s-8.824219-32.347656-23.605469-40.878906zm-401.941406 253.152344c-.21875-1.097657-.351562-2.273438-.351562-3.550782v-417.445312c0-2.246094.378906-4.203125.984375-5.90625l204.324219 213.121094zm43.824219-425.242188 234.21875 135.226562-52.285156 54.539063zm-6.480469 429.679688 188.410156-196.527344 54.152344 56.484375zm349.585938-201.835938-80.25 46.332031-60.125-62.714843 58.261718-60.773438 82.113282 47.40625c7.75 4.476562 8.589844 11.894531 8.589844 14.875s-.839844 10.398438-8.589844 14.875zm0 0"></path></svg>
                            <span className="mx-2">
                                Télécharger sur Google Play
                            </span> 
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>                            
    )
  }

export default MobilePage;
