import React, { useState, useEffect } from "react";
import {
  Button,
  Spinner,
} from "ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { get_plans_by_group_paginated } from "../slices/managerSlice";
import { dateFormat } from "ck_commun/src/app/modUtils/functions/dateFormat";
import iconAccessFormuleSvg from "../assets/images/IconAccessFormule.svg";
import { ReactComponent as PlanDefaultIcon } from "../assets/images/planDefaultIcon.svg";
import { message } from "antd";
import AlertMessage from "ck_commun/src/app/modUtils/components/AlertMessage";

export default function GroupPlans() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { groupId, selectedGroup, groupPlans } = useSelector(
    (state) => state.manager
  );
  const [loading, setLoading] = useState(false);
  const [activePlanName, setActivePlanName] = useState();
  const [activePlanId, setActivePlanId] = useState();
  const planOnRenewal = groupPlans.find(
    (plan) => plan.id == selectedGroup?.company?.plan_on_renewal
  );
  const planOnRenewalLabel = planOnRenewal?.name;
  const formatDate = "DD/MM/yyyy";
  const phone = "04 73 28 92 46";
  const email = "commercial@cksquare.fr";
  //appel api get_plans_by_group_paginated
  const getGroupPlans = async () => {
    setLoading(true);
    try {
      await dispatch(
        get_plans_by_group_paginated({
          groupId
        })
      ).unwrap();
    } catch (error) {
      console.log(error)
      message.error({
        content: (
          <AlertMessage
            status={error?.status}
            alertMessage={`Une erreur est survenue lors de la récupération des formules d'abonnements`}
            errorDetail={error?.data?.detail}
          />
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (groupId) {
      getGroupPlans();
      setActivePlanName(selectedGroup?.company?.plan_name);
      setActivePlanId(selectedGroup?.company?.plan);
    }
  }, [groupId, selectedGroup]);

  //formater la description du plan en liste
  const renderPlanDescriptionList = (description) => {
    const permissions = description.split("- ");
    return (
      <ul className={`text-[1em] leading-[1.5em] tracking-[1px]`}>
        {permissions.map((permission, index) => {
          if (permission.trim() !== "") {
            return (
              <li key={index} className={`mb-[1em]`}>
                <img src={iconAccessFormuleSvg} />
                <span className="ml-2">{permission}</span>
              </li>
            );
          } else return null;
        })}
      </ul>
    );
  };
  //retourne une colonne qui contient les détails d'un plan
  const RenderPlanCol = (plan, index) => {
    return (
      <div
        key={index}
        className="flex flex-col items-center h-full relative grow"
      >
        {plan?.icon ? (
          <img src={plan?.icon} alt={plan?.name} className="w-12 h-12" />
        ) : (
          <PlanDefaultIcon className="w-12 h-12" />
        )}
        <div className={`my-8 text-center text-lg uppercase font-bold`}>
          {plan?.name}
        </div>
        <div className="flex flex-col justify-start items-center">
          <div
            className={`text-center text-xs md:text-sm h-[2em] font-light truncate`}
          >
            {
              activePlanName == plan?.name ? (
                <span>
                  {selectedGroup?.company?.plan_on_renewal == activePlanId ||
                  selectedGroup?.company?.plan_on_renewal == null
                    ? "Renouvellement "
                    : `Changement vers  ${planOnRenewalLabel} `}
                  le{" "}
                  {dateFormat(
                    selectedGroup?.company?.plan_period_stop,
                    formatDate
                  )}
                </span>
              ) : null
              // TODO a rajouter quand fonctionnalité prete en back
              //  t("userSubscriptionPlan.transTestSubscriptionPlan")
            }
          </div>

          {activePlanName == plan?.name ? (
            <Button
              className={`w-full h-[3em] bg-success-color hover:bg-success-color border-success-color hover:border-success-color focus:bg-success-color  focus:border-success-color`}
              type={"primary"}
            >
              <>
                <span className="mr-2">
                  {t("userSubscriptionPlan.transSubscriptionPlanActive")}
                </span>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/img/subscriptionPlanIcons/circleCheckIcon.svg"
                  }
                  alt="img"
                />
              </>
            </Button>
          ) : (
            <div className="h-[3em]"></div>
          )}
          {/* TODO a rajouter quand fonctionnalité prete en back */}
          {/* t("userSubscriptionPlan.transSendSubscriptionPlanRequestButton") */}
        </div>
        <div className={`w-full h-px bg-[#dddddd] opacity-100 m-[1em]`}></div>
        <div className="subscription-plan-permissions">
          {renderPlanDescriptionList(plan?.description)}
        </div>
      </div>
    );
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {groupPlans.length > 0 ? (
            <div className="flex flex-row justify-between mx-[1em]">
              {groupPlans?.map((plan, index) => (
                <div
                  className="flex"
                  style={{ flex: `0 0 calc(100% / ${groupPlans.length})` }}
                >
                  {RenderPlanCol(plan, index)}
                  {index !== groupPlans.length - 1 && (
                    <div className={`w-px bg-[#dddddd] opacity-100 m-[2em]`} />
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="text-error-color text-xl text-center my-6">Formules d'abonnements non disponibles !</div>
          )}
          {/* footer */}
          <div className="flex flex-row justify-center mt-2">
            <div className={`w-3/5 text-center text-lg`}>
              {t("userSubscriptionPlan.transContactPhone")}
              <b className="truncate">{phone}</b>
              {t("userSubscriptionPlan.transContactEmail")}
              <b>{email}</b>
            </div>
          </div>
        </>
      )}{" "}
    </>
  );
}
