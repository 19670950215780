/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/device/e_device_status.py
Aucune modification manuelle acceptée
*/

// Status d'un évènement sur un périphérique
const eDeviceStatus = Object.freeze({
  // Non installé
  0: 'eDeviceStatus.noInstalled',

  // Erreur
  3: 'eDeviceStatus.error',

  // Ok
  4: 'eDeviceStatus.ok',
});

export default eDeviceStatus;
