/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/system/e_equipment_type.py
Aucune modification manuelle acceptée
*/

// Type d'équipement
const eEquipmentType = Object.freeze({
  // Centrale de paiement et de commandes
  10: 'eEquipmentType.laundryMachinesDriver',

  // Pilote d'une machine de laverie
  11: 'eEquipmentType.laundryMachineDriver',

  // Laverie distributeur anti-tâche
  12: 'eEquipmentType.laundryStainRemover',

  // Paiement centralisé
  20: 'eEquipmentType.centralizedPayment',

  // Portique
  21: 'eEquipmentType.carWash',

  // Distributeur de jetons
  22: 'eEquipmentType.tokenDispenser',

  // Piste haute pression
  23: 'eEquipmentType.highPressureTrack',

  // Aspirateur
  24: 'eEquipmentType.vacuumCleaner',

  // Gonfleur
  25: 'eEquipmentType.airPump',

  // Autre accessoire (parfum)
  26: 'eEquipmentType.accessoriesOther',

  // Distributeur de produits (ck-shop)
  27: 'eEquipmentType.dispenserProduct',

  // Autre marché non spécifié
  30: 'eEquipmentType.marketNotSpecified',

  // Distributeur de granulé de bois
  31: 'eEquipmentType.dispenserWoodPellet',

  // Borne arrêt-minute
  32: 'eEquipmentType.arretMinuteTerminal',

  // Borne de paiement aire de service
  33: 'eEquipmentType.serviceAreaTerminal',

  // Borne parking
  34: 'eEquipmentType.parkingTerminal',

  // Borne énergie
  35: 'eEquipmentType.energyTerminal',

  // Borne à eau
  36: 'eEquipmentType.waterTerminal',

  // Borne d'accès (totem, tourniquet)
  37: 'eEquipmentType.accessTerminal',

  // Journal lumineux
  38: 'eEquipmentType.ledBoard',

  // Outils (sniffer, carte de tests)
  39: 'eEquipmentType.tools',

  // Gestion d'énergie
  40: 'eEquipmentType.energyManagement',

  // Serveur code
  41: 'eEquipmentType.codeServer',

  // EasyKey
  42: 'eEquipmentType.easyKey',

  // Lave siège
  50: 'eEquipmentType.washerSeat',

  // Lave tapis
  51: 'eEquipmentType.washerCarpet',

  // Parfumeur
  52: 'eEquipmentType.perfumer',

  // Distributeur de lingettes
  53: 'eEquipmentType.dispenserWipes',

  // Distributeur de lave-glace
  54: 'eEquipmentType.dispenserScreenWasher',

  // Boutique
  98: 'eEquipmentType.shop',

  // Web
  99: 'eEquipmentType.web',
});

export default eEquipmentType;
