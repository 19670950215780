/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/device/e_target_type.py
Aucune modification manuelle acceptée
*/

// Enumération des types de cibles d'une vente
// 
// ANCIENNEMENT TTARGET_
const eTargetType = Object.freeze({
  // Fin
  0: 'eTargetType.end',

  // Défaut
  1: 'eTargetType.default',

  // Relevé
  2: 'eTargetType.statement',

  // Remboursement
  3: 'eTargetType.repay',

  // Sélection générique
  10: 'eTargetType.selectionGeneric',

  // Sélection de programme
  11: 'eTargetType.selectionProgramme',

  // Sélection de machines
  12: 'eTargetType.selectionMachine',

  // Sélection esclave
  13: 'eTargetType.selectionSlave',

  // Sélection gratuite
  14: 'eTargetType.selectionFree',

  // Distribution générique
  30: 'eTargetType.deliveryGeneric',

  // Distribution générique par Lv
  31: 'eTargetType.deliveryGenericByLv',

  // Distribution de badges
  32: 'eTargetType.deliveryBadge',

  // Distribution de badges par Lv
  33: 'eTargetType.deliveryBadgeByLv',

  // Distribution de clefs
  34: 'eTargetType.deliveryKey',

  // Distribution de clefs par Lv
  35: 'eTargetType.deliveryKeyByLv',

  // Distribution de produits
  36: 'eTargetType.deliveryProduct',

  // Distribution de produits par Lv
  37: 'eTargetType.deliveryProductByLv',

  // Distribution monétaire
  40: 'eTargetType.deliveryMoney',

  // Distribution monétaire par Lv
  41: 'eTargetType.deliveryMoneyByLv',

  // Distribution de jetons
  42: 'eTargetType.deliveryToken',

  // Distribution de jeton par Lv
  43: 'eTargetType.deliveryTokenByLv',

  // Distribution de pièces
  44: 'eTargetType.deliveryCoin',

  // Distribution de pièces par Lv
  45: 'eTargetType.deliveryCoinByLv',

  // Distribution de billets
  46: 'eTargetType.deliveryBill',

  // Distribution de billets par Lv
  47: 'eTargetType.deliveryBillByLv',

  // Ticket générique
  50: 'eTargetType.ticketGeneric',

  // Ticket numéraire
  51: 'eTargetType.ticketNumeric',

  // Ticket de pré-vente
  52: 'eTargetType.ticketPresale',

  // Ticket serveur
  53: 'eTargetType.ticketServer',

  // Badge générique
  100: 'eTargetType.badgeGeneric',

  // Badge création
  101: 'eTargetType.badgeCreation',

  // Badge rechargement
  102: 'eTargetType.badgeReload',

  // Enumération commenté en C. Comprendre pourquoi, et ce qu'il faut faire côté serveur
  103: 'eTargetType.badgeCaution',

  // Achat badge
  104: 'eTargetType.badgeBuy',

  // Supression badge
  105: 'eTargetType.badgeDelete',

  // Effacement de pièces générique
  120: 'eTargetType.erasingCoinGeneric',

  // Effacement surplus crédit
  121: 'eTargetType.erasingOverCredit',

  // Effacement par le WebServer
  122: 'eTargetType.erasingByWebServer',

  // Effacement par Lv
  123: 'eTargetType.erasingByLv',

  // Effacement par timeout
  124: 'eTargetType.erasingByTimeout',

  // Utilisation externe
  125: 'eTargetType.externalUse',

  // Effacement surplus paiement
  126: 'eTargetType.erasingOverPayments',

  // Effacement de pièces par timeout
  127: 'eTargetType.erasingCoinByTimeout',

  // Effacement après redémarrage
  128: 'eTargetType.erasingAfterReboot',

  // Magasin
  130: 'eTargetType.shopDefault',
});

export default eTargetType;
