/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/payment/e_monetique_repay.py
Aucune modification manuelle acceptée
*/

// Enumération des réponses possible lors d'un remboursement monétique
const eMonetiqueRepay = Object.freeze({
  // Ok
  0: 'eMonetiqueRepay.repayOk',

  // Occupé
  1: 'eMonetiqueRepay.repayBusy',

  // Pas terminé
  2: 'eMonetiqueRepay.repayNotdone',

  // Terminé
  3: 'eMonetiqueRepay.repayDone',
});

export default eMonetiqueRepay;
