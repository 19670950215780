import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Spinner,
  Card,
} from "../../../modUtils/components/componentsLibrary/componentsLibrary";
import {
  get_dashboard_cumulative_by_group,
  get_dashboard_cumulatives_latest_by_group,
} from "../../slices/cumulativeSlice";
import ActivityCard from "../../components/activityCard/ActivityCard";
import SubmitButton from "../../../modUtils/components/buttons/submitButton/SubmitButton";
import FilterBySearchSite from "ck_commun/src/app/modManager/components/filterBySearchSite/FilterBySearchSite";
import TopSitesByGroupTable from "../../components/TopSitesByGroupTable";
import TopSitesGrowthByGroupTable from "../../components/TopSitesGrowthByGroupTable";
import { message } from "antd";
import AlertMessage from "../../../modUtils/components/AlertMessage";
import "./ActivityDashboardPage.scss";

const duree = [
  { label: "7 derniers jours", value: 7 },
  { label: "30 derniers jours", value: 30 },
  { label: "365 derniers jours", value: 365 },
];
const numberOfSites = 100;
const range = 7;
export default function ActivityDashboardPage() {
  const { t } = useTranslation();
  const periods = [
    t("dates.period_today"),
    t("dates.period_week"),
    t("dates.period_month"),
    t("dates.period_year"),
  ]; // periods=["aujourd'hui","cette semaine","ce mois", "cette année"]
  const dispatch = useDispatch();
  const [loadingCumulative, setLoadingCumulative] = useState(true);
  const [loadingCumulativeLatest, setLoadingCumulativeLatest] = useState(true);
  const [loadingSelect, setLoadingSelect] = useState(true);
  const { groupId, groupDetail } = useSelector((state) => state.manager);
  const [siteList, setSiteList] = useState();
  const [siteListLength, setSiteListLength] = useState();
  //récupérer les données des card
  const getDashboardCumulative = async () => {
    setLoadingCumulative(true);
    try {
     await dispatch(
        get_dashboard_cumulative_by_group({
          groupId,
          sites: siteList?.join(","),
        })
      ).unwrap();
    } catch (error) {
      message.error({
        content: (
          <AlertMessage
            status={error?.status}
            alertMessage={`Une erreur est survenue lors de la récupération des cumulatives`}
            errorDetail={error?.data?.detail}
          />
        ),
      });
    } finally {
        setLoadingCumulative(false);
    }
  };
//récupérer les données des graphes
  const getDashboardCumulativeLatest = async () => {
    setLoadingCumulativeLatest(true);
    try {
      await dispatch(
        get_dashboard_cumulatives_latest_by_group({
          groupId,
          range,
          sites: siteList?.join(","),
        })
      ).unwrap();
    } catch (error) {
      message.error({
        content: (
          <AlertMessage
            status={error?.status}
            alertMessage={`Une erreur est survenue lors de la récupération des dernières cumulatives`}
            errorDetail={error?.data?.detail}
          />
        ),
      });
    } finally {
        setLoadingCumulativeLatest(false);
    }
  };
   const fetchData = async () => {
      try {
        await Promise.all([getDashboardCumulative(), getDashboardCumulativeLatest()]);
      } catch (error) {
        // Error handling is already done within the individual functions
      } finally {
        setLoadingCumulative(false);
        setLoadingCumulativeLatest(false);
        setLoadingSelect(false);
      }
    };
  
  useEffect(() => {
    setLoadingSelect(true);
    setLoadingCumulative(true);
    setLoadingCumulativeLatest(true);
    setSiteList(null);
  
   
    if (groupId) {
      fetchData();
    }
  }, [groupId]);
  //filtre par sites
  function handleSitesValueChange(selectedList) {
    setSiteList(selectedList?.length > 0 ? selectedList : null);
  }

  const onExecuteSubmitBtn = () => {
    setSiteListLength(siteList?.length);
    fetchData();
  };

  return (
    <>
        <div className="flex-display flex-row align-items-end filters min-h-20">
            {!loadingSelect && groupDetail.site_count > 1 &&
                <>
                <FilterBySearchSite
                    onValueChange={handleSitesValueChange}
                />
                <SubmitButton onClick={() => onExecuteSubmitBtn()} />
                </>
            }
        </div>
        
        
      {loadingCumulative || loadingCumulativeLatest ? (
        <Spinner />
      ) : (
        <>
          <div className="flex-display flex-column justify-center">
            <div className="APP_h1_text">
              {t("activity.activityDashboardLabel")}
            </div>
            <div className="grid grid-cols-4 grid-rows-1 gap-4 justify-between justify-items-center mx-auto">
              {periods.map((period, index) => {
                return (
                  <div className="col-span-4 md:col-span-2 2xl:col-span-1 min-w-[250px] max-w-[250px] ">
                    <ActivityCard
                      title={period}
                      indexOfPeriod={index}
                      key={period}
                    />
                  </div>
                );
              })}

            {groupDetail.site_count > 1 && siteListLength !== 1 &&
              <>
              <div className="col-span-4 2xl:col-span-2 w-full min-w-[250px]">
                <Card>
                  <TopSitesByGroupTable
                    numberOfSites={numberOfSites}
                    duree={duree}
                    siteList={siteList}
                  />
                </Card>
              </div>
              <div className="col-span-4 2xl:col-span-2 2xl:col-start-3 w-full min-w-[250px]">
                <Card>
                  <TopSitesGrowthByGroupTable
                    numberOfSites={numberOfSites}
                    duree={duree}
                    siteList={siteList}
                  />
                </Card>
              </div>
              </>
            }
            </div>
          </div>
        </>
      )}
    </>
  );
}
