import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { dureeFormat } from "../../../modUtils/functions/dateFormat";
import EquipmentManageModal from "../equipmentManageModal/EquipmentManageModal";
import EquipmentActionsMenu from "../equipmentActionsMenu/EquipmentActionsMenu";
import { hasPermission } from "ck_commun/src/app/modUtils/functions/handlePermissions";
import { eEquipmentType } from "../../../../commun_global/enums";
import CKToolTip from "../../../modUtils/components/CKToolTip/CKToolTip";
import PermissionFadeContainer from "../../../modUtils/components/permissionFadeContainer/PermissionFadeContainer";

export default function EquipmentCard({ siteId, siteName, equipment }) {
  const { selectedGroup } = useSelector((state) => state.manager);
  const { t } = useTranslation();
  const permissions = selectedGroup.permissions;
  const verifyPermission = hasPermission("mod_equipment:show_device",permissions);
  const groupPermissions = selectedGroup?.plan?.permissions;
  const verifyGroupPermission = hasPermission('mod_equipment:show_device', groupPermissions);

    const textContentEquipmentInfo = (
        <ul className="list-disc pl-4 m-0 text-xs">
            <li>Numéro de série (version CKS Protocol)</li>
            <li>ID hardware (version hard) - ID software (version soft)</li>
        </ul>
    );

  //renderSwitchStatus retourne l'icône correspondante au status de l'équipement et prend en paramètres devices_error_count,  out_of_order (!devices_error_severe_count deprecated)
  function renderSwitchStatus(is_connected, out_of_order, devices_error_count) {
    const srcImg = process.env.PUBLIC_URL + "/img/equipmentStatusIcons/";
    const extImg = ".svg";
    let img, textContent;

    if (is_connected && out_of_order) {
      img = "offlineConnected";
      textContent = "Équipement hors service.";
    } else if (is_connected && !out_of_order && devices_error_count > 0) {
      img = "errorConnected";
      textContent = "Équipement en erreur.";
    } else if (!is_connected && out_of_order) {
      img = "offlineNotConnected";
      textContent = "Équipement hors ligne.";
    } else if (!is_connected && !out_of_order && devices_error_count > 0) {
      img = "errorNotConnected";
      textContent = "Équipement hors ligne.";
    }

    if (typeof img === "string") {
        return (
            <CKToolTip placement="topLeft" content={textContent}>
                <img width={40} height={40} src={srcImg + img + extImg} alt={img}/>
            </CKToolTip>
        );
    }
  }

  //renderConnectionType retourne l'affichage correspondant au type de connexion et prend en paramètres is_connected, last_seen, connection_type, connection_type_image
  function renderConnectionType(
    connection_type_image,
    connection_type,
    is_connected,
    last_seen
  ) {
    if (is_connected) {
      //si l'équipement est connecté , j'affiche l'icône  et le type de connexion en bleu
      return (
        <div className="flex flex-col items-center">
          {connection_type_image && (
            <img
              width={20}
              className="cnxIcon"
              src={connection_type_image}
              alt="img"
            />
          )}
          <div className="text-xs">
            {connection_type == 0 && "ADSL"}
            {connection_type == 1 && "FIBRE"}
            {connection_type == 2 && "GPRS"}
            {connection_type == 3 && "3G"}
            {connection_type == 4 && "4G"}
            {connection_type == 5 && "5G"}
            {connection_type == (null || undefined) && "Non défini"}
            {/**{connection_type ? eConnectionType[connection_type] : "Non défini"} */}
          </div>
        </div>
      );
    } else if (!is_connected) {
      //si l'équipement n'est pas connecté , j'affiche l'icône + HORS LIGNE + durée passé depuis dernière vue (last_seen) en rouge
      return (
        <>
          {connection_type_image && (
            <img
              width={20}
              className="cnxIcon"
              src={connection_type_image}
              alt="img"
            />
          )}
          <div className="flex flex-col items-center text-xs text-[#e30613]">
            <span>HORS LIGNE </span>
            <span>{dureeFormat(last_seen)}</span>
          </div>
        </>
      );
    }
  }

  return (
    <div
      key={siteId + "-" + equipment.id}
      className="odd:bg-[#ECF6FC] odd:border odd:border-solid odd:border-[#00CFF933]"
    >
      <div
        className="flex items-center text-[#1b8add] h-[70px] px-5"
        key={equipment.id}
      >
        <div className="flex flex-col w-1/2">
          <div className="flex flex-row items-center">
            <div className="flex flex-col pr-5">
              <div className="w-10">
                {verifyPermission &&
                    <PermissionFadeContainer
                        permission={verifyGroupPermission}
                    >
                        {renderSwitchStatus(
                            equipment.is_connected,
                            equipment.out_of_order,
                            equipment.devices_error_count
                        )}
                    </PermissionFadeContainer>
                }
              </div>
            </div>

            <div className="flex flex-col">
              <div className="flex flex-row items-center w-full mb-[5px]">
                <div className="w-[50px] text-center mr-[25px]">
                  <img className="max-w-7 max-h-6" src={equipment?.equipment_type_image} />
                </div>

                <div className="flex flex-col">
                  <div className="w-full">
                    <EquipmentManageModal
                      equipment={equipment}
                      siteName={siteName}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-row">
                <div className="text-sm text-[#174A84] text-center">
                  {t(eEquipmentType[equipment.type])}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center w-[10%]">
          {renderConnectionType(
            equipment.connection_type_image,
            equipment.connection_type,
            equipment.is_connected,
            equipment.last_seen
          )}
        </div>

        <div className="flex item-center justify-center w-[10%]">
          {equipment?.ck_vpn_active && (
            <img
              width={25}
              src={
                process.env.PUBLIC_URL + "/img/connexionType/vpnConnected.svg"
              }
              alt="vpnConnected"
            />
          )}
        </div>

        <div className="flex flex-col w-[30%] font-semibold text-xs text-left text-[#174A84]">
          {/**
           * 1ère ligne : numéro de série de l'équipement (Version CK protocol)
           * 2ème ligne : Type de carte (version carte) - programme installé sur la carte (version programme)
           */}
          <CKToolTip placement="topLeft" trigger="hover" content={textContentEquipmentInfo}>
            {(equipment?.serial_number || equipment?.ckspro_version) && (
              <li>
                {equipment?.serial_number && equipment?.serial_number}
                {equipment?.ckspro_version && ` (${equipment?.ckspro_version})`}
              </li>
            )}
            {(equipment?.hardware_id ||
              equipment?.hardware_version ||
              equipment?.software_id ||
              equipment?.software_version) && (
              <li>
                {equipment?.hardware_id}
                {equipment?.hardware_version &&
                  ` (${equipment?.hardware_version})`}
                {equipment?.software_id && "-" + equipment?.software_id}
                {equipment?.software_version &&
                  ` (${equipment?.software_version})`}
              </li>
            )}
          </CKToolTip>
        </div>

        <div>
          <EquipmentActionsMenu
            equipment={equipment}
            siteName={siteName}
            siteId={siteId}
          />
        </div>
      </div>
    </div>
  );
}
