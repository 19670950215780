import React from 'react'
import { ArrowRightOutlined } from '@ant-design/icons'
import './SubmitButton.scss'

export default function SubmitButton({ onClick, label }) {
  return (
    <button
      htmltype="submit"
      onClick={onClick}
      className='bg-success-color px-4 py-2 rounded shadow cursor-pointer font-bold ease-in-out border-0 text-white text-nowrap hover:bg-success-color-600'
    >
      {label ? (
        label
      ) : (
        <>
          EXÉCUTER <ArrowRightOutlined />
        </>
      )}
    </button>
  )
}
