import React, { useState, useEffect } from "react";
import {
  Modal,
  Alert,
  Table,
  Spinner,
  Button,
  Pagination,
} from "../../../modUtils/components/componentsLibrary/componentsLibrary";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { get_entity_paginated } from "../../../modEntity/slices/entitySlice";
import SupportCustomerInfosModal from "../supportCustomerInfosModal/SupportCustomerInfosModal";
import { update_support_detail_by_group } from "../../slices/walletLocalSlice";
import { message } from "antd";
import AlertMessage from "../../../modUtils/components/AlertMessage";
import "./SupportLinkToCustomerModal.scss";

export default function SupportLinkToCustomerModal({
  modalVisible,
  setModalVisible,
  support,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { entities, entitiesCount, entititesCustomer, entitiesCompany } =
    useSelector((state) => state.entity);
  const { groupId } = useSelector((state) => state.manager);
  const [searchValue, setSearchValue] = useState("");
  const [entityId, setEntityId] = useState();
  const defaultLimit = 100;
  const defaultOffset = 0;
  const defaultCurrentPage = 1;
  const [limit, setLimit] = useState(defaultLimit);
  const [offset, setOffset] = useState(defaultOffset);
  const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
  const [isAddNewCustomerModalVisible, setIsAddNewCustomerModalVisible] =
    useState(false);
  const orderingFilter = "name";
  const typeFilter = "customer,company";

  const getEntitites = async ({ limit, offset, name }) => {
    setLoading(true);
    try {
      await dispatch(
        get_entity_paginated({
          groupId,
          limit: limit,
          offset: offset,
          name: name,
          type: typeFilter,
          ordering: orderingFilter,
        })
      ).unwrap();
    } catch (error) {
      message.error({
        content: (
          <AlertMessage
            status={error?.status}
            alertMessage={`Une erreur est survenue lors de la récupération de la liste des clients`}
            errorDetail={error?.data?.detail}
          />
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setEntityId();
    setSearchValue();
    setLimit(defaultLimit);
    setOffset(defaultOffset);
    setCurrentPage(defaultCurrentPage);
    groupId && getEntitites({ limit, offset });
  }, [groupId, support, entititesCustomer, entitiesCompany]);

  // // Fonction de gestion de changement de pagination
  // const handlePaginationChange = (page, pageSize) => {
  //   // Mettre à jour les valeurs de limit et offset en fonction de la page et de la taille de page
  //   const newOffset = (page - 1) * pageSize;
  //   setOffset(newOffset);
  //   if (newOffset < 0) {
  //     setCurrentPage(1);
  //   } else {
  //     setCurrentPage(page);
  //   }
  //   setLimit(pageSize);
  //   // appel à l'API avec les nouvelles valeurs de limit et offset pour obtenir les données correspondantes
  //   getEntitites({ limit: pageSize, offset: newOffset });
  // };
  const handleRowClick = (rowKey) => {
    setEntityId(rowKey); //récupérer l'id de l'entité que l'utilisateur a cliqué au dessus
  };

  const handleLinkSupportToCustomer = async () => {
    // si l'utilisateur clique sur associer client alors qu'il n'a pas choisi une entité de la liste afficher un msg 'choisir une entité"
    if (!entityId) {
      message.warning({
        content: (
          <AlertMessage alertMessage="Aucun client n'est séléctionné, veuillez choisir un client tout d'abord !" />
        ),
      });
    } else {
      try {
        setConfirmLoading(true);
        await dispatch(
          update_support_detail_by_group({
            groupId,
            supportId: support?.id,
            entity: entityId,
          })
        ).unwrap();
        setConfirmLoading(false);
        setModalVisible(false);
      } catch (error) {
        message.error({
          content: (
            <AlertMessage
              status={error?.status}
              alertMessage={`Une erreur est survenue lors de l'asscoiation du badge N° ${support.badge_number} à ce client`}
              errorDetail={error?.data?.detail}
            />
          ),
        });
        setConfirmLoading(false);
      } finally {
        setEntityId();
      }
    }
  };

  const handleKeyPressOnSearchEntityInput = (event) => {
    setCurrentPage(defaultCurrentPage);
    if (event.key === "Enter") {
      getEntitites({
        limit: defaultLimit,
        offset: defaultOffset,
        name: searchValue,
      });
    }
  };

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  const columns = [
    {
      title: "Nom",
      dataIndex: "name",
      key: "name",
      width: "50%",
      render: (text, record) =>
        record.entity_customer
          ? `${record?.entity_customer?.name} ${record?.entity_customer?.first_name} `
          : record?.entity_company?.name,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "50%",
      render: (text, record) =>
        record.entity_customer
          ? record?.entity_customer?.email
          : record?.entity_company?.email,
    },
  ];
  return (
    <>
      {confirmLoading && <Spinner />}
      <Modal
        // title={t("badge.badgeAddModalTitle")}
        title={`ASSOCIER LE BADGE ${support.badge_number} AU CLIENT :`}
        isOpen={modalVisible}
        onClose={() => setModalVisible(false)}
        width={"60%"}
        onCancel={() => setModalVisible(false)}
        onOk={handleLinkSupportToCustomer}
        okText={
          <span>
            Associer client{" "}
            <img src={process.env.PUBLIC_URL + "/img/design/link.svg"} />{" "}
          </span>
        }
      >
        <div
          className="flex-display flex-row justify-content-between align-items-center"
          style={{ marginBottom: "1em" }}
        >
          <div className="search-site flex-display flex-row">
            <input
              id="searchInput"
              type="text"
              className="search-site"
              placeholder="Client, Société"
              value={searchValue}
              onChange={handleChange}
              onKeyPress={handleKeyPressOnSearchEntityInput}
            />
            <img
              src={process.env.PUBLIC_URL + "/img/design/zoom.svg"}
              alt="zoom"
            />
          </div>
          <Button
            type="primary"
            onClick={() => setIsAddNewCustomerModalVisible(true)}
          >
            Nouveau client +
          </Button>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <Table
            columns={columns}
            dataSource={entities}
            onRowClick={handleRowClick}
            rowClassName={(record, index) =>
              record.id == entityId ? "table-row-clicked" : ""
            }
          />
        )}
        {
          //   entitiesCount > 0 && (
          //   <div className="flex flex-row justify-end mt-4">
          //     <Pagination
          //       defaultCurrent={currentPage}
          //       defaultPageSize={limit}
          //       showQuickJumper={true}
          //       showTotal={false}
          //       total={entitiesCount}
          //       //hideOnSinglePage={true}
          //       onChange={handlePaginationChange}
          //       showSizeChange={false}
          //       onShowSizeChange={handlePaginationChange}
          //     />
          //   </div>
          //  )
        }
      </Modal>
      {isAddNewCustomerModalVisible && (
        <SupportCustomerInfosModal
          modalVisible={isAddNewCustomerModalVisible}
          setModalVisible={setIsAddNewCustomerModalVisible}
        />
      )}
    </>
  );
}
