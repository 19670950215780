/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/file/e_file_type.py
Aucune modification manuelle acceptée
*/

// Type de fichier cdb
const eFileType = Object.freeze({
  // Évènement produit sur l'équipement
  1: 'eFileType.events',

  // CB
  2: 'eFileType.cb',

  // TELECOLLECT
  3: 'eFileType.telecollect',

  // BADGE
  4: 'eFileType.badge',

  // Liste des badges local blacklisté
  5: 'eFileType.blacklist',

  // TICKETCODE
  6: 'eFileType.ticketCode',

  // CURRENCY
  7: 'eFileType.currency',

  // MACHINES
  8: 'eFileType.machines',

  // HISTORIC_DAY
  9: 'eFileType.historicDay',

  // HISTORIC_MONTH
  10: 'eFileType.historicMonth',

  // HISTORIC_RELEVE
  11: 'eFileType.historicReleve',

  // Historique des ventes remonté aux serveur (obligatoire loi finance)
  12: 'eFileType.payment',

  // DEBUG
  13: 'eFileType.debug',

  // SELECTION
  14: 'eFileType.selection',

  // SALES
  15: 'eFileType.sales',

  // AUDIT
  16: 'eFileType.audit',

  // COUNTERS_MANAGER
  17: 'eFileType.countersManager',

  // ACCESS_MGT
  18: 'eFileType.accessMgt',

  // PARKING
  19: 'eFileType.parking',

  // PLANNING
  20: 'eFileType.planning',

  // PARKING_EVENT
  21: 'eFileType.parkingEvent',

  // SHOW
  22: 'eFileType.show',

  // CKSSYSCODE
  23: 'eFileType.ckssyscode',

  // SERVICE_START
  24: 'eFileType.serviceStart',

  // SERVICE_END
  25: 'eFileType.serviceEnd',

  // SERVICETYPE_PRESALE
  26: 'eFileType.serviceTypePresale',

  // PAYMENT_TRANSAC
  27: 'eFileType.paymentTransac',

  // Historique des changement de status d'un périphérique
  28: 'eFileType.device',

  // HISPOWER_LEASE
  29: 'eFileType.hispowerLease',

  // MKICE
  30: 'eFileType.mkice',

  // MOTOR_POSITION
  31: 'eFileType.motorPosition',

  // SESSION
  33: 'eFileType.session',

  // DAILYREPORT
  34: 'eFileType.dailyReport',

  // CKSLVSEND
  35: 'eFileType.cksLvsend',

  // SERVICES_EVENTS
  36: 'eFileType.servicesEvents',

  // DISTRIBUTION
  37: 'eFileType.distribution',

  // CCTALK_TCPIP
  38: 'eFileType.cctalkTcpip',

  // SENSOR
  39: 'eFileType.sensor',

  // PAC195X
  40: 'eFileType.pac195X',
});

export default eFileType;
