/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/domotic/e_domotic_mode.py
Aucune modification manuelle acceptée
*/

// Mode d'utilisation de l'évènement
const eDomoticMode = Object.freeze({
  // Normal
  0: 'eDomoticMode.normal',

  // Forcé on
  1: 'eDomoticMode.forcedOn',

  // Forcé off
  2: 'eDomoticMode.forcedOff',
});

export default eDomoticMode;
