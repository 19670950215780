import React from "react";

function FormLegend({ legend, requiredFormItemLegend }) {
  return (
    <div className="flex flex-col items-start align-self-start text-xs">
      {requiredFormItemLegend && (
        <div>
          <span className="text-error-color text-sm">* </span>champs
          obligatoires
        </div>
      )}
      <div>{legend}</div>
    </div>
  );
}

export default FormLegend;
