import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { update_action_detail_by_support_by_group } from "../slices/walletLocalSlice";
import SupportActionDetailsModal from "./SupportActionDetailsModal";
import DropdownActionsMenu from "../../modUtils/components/dropdownActionsMenu/DropdownActionsMenu";
import { Spinner } from "../../modUtils/components/componentsLibrary/componentsLibrary";

export default function SupportActionMenuButton({
  supportId,
  actionId,
  actionStatus,
}) {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const { groupId } = useSelector((state) => state.manager);
  const [isActionDetailModalVisible, setIsActionDetailModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };
  
//fonction appelée pour annuler une action
  const cancelAction = async () => {
    setConfirmLoading(true);
    try {
      await dispatch(
        update_action_detail_by_support_by_group({
          groupId,
          supportId: supportId,
          actionId: actionId,
          status: 1, //status 1 pour annuler une action
        })
      ).unwrap();
    } catch (error) {
        message.error({
          content: (
            <AlertMessage
              status={error?.status}
              alertMessage={
                "Une erreur est survenue lors de l'annulation de l'action!"
              }
              errorDetail={error?.data?.detail}
            />
          ),
        });
    } finally {
      setConfirmLoading(false);
      setVisible(false);
    }
  };

  const handleOpenActionDetailModal = () => {
    setVisible(false);
    setIsActionDetailModalVisible(true);
  };

  const menuItems = [
    <span onClick={() => handleOpenActionDetailModal()}>Détails</span>,
    actionStatus == 0 && <span onClick={cancelAction}>Annuler l'action</span>,
    //on ne peut annuler une action que si elle en cours (status 0)
  ];

  return (
    <>
      {confirmLoading && <Spinner />}
      <DropdownActionsMenu
        menuItems={menuItems}
        handleVisibleChange={handleVisibleChange}
        visible={visible}
      />
      {isActionDetailModalVisible && (
        <SupportActionDetailsModal
          supportId={supportId}
          actionId={actionId}
          modalVisible={isActionDetailModalVisible}
          setModalVisible={setIsActionDetailModalVisible}
        />
      )}
    </>
  );
}
