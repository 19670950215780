/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/machines/e_machines_status.py
Aucune modification manuelle acceptée
*/

// Statut d'une machine
const eMachinesStatus = Object.freeze({
  // En Attente
  0: 'eMachinesStatus.ready',

  // Occupé
  1: 'eMachinesStatus.busy',

  // Hors Service
  2: 'eMachinesStatus.hs',

  // Pas de réponse
  3: 'eMachinesStatus.timeout',

  // Erreur avant commande
  4: 'eMachinesStatus.errorBeforeCommand',

  // Non installé
  5: 'eMachinesStatus.noInstall',

  // En pause
  6: 'eMachinesStatus.paused',

  // En cours d'exécution
  7: 'eMachinesStatus.running',

  // Commandé
  8: 'eMachinesStatus.ordered',

  // Sélectionné
  9: 'eMachinesStatus.selected',

  // Terminé
  10: 'eMachinesStatus.ended',

  // Bientôt terminé
  11: 'eMachinesStatus.soonEnded',

  // Installé
  12: 'eMachinesStatus.installed',

  // Est défini
  13: 'eMachinesStatus.isDefined',

  // Démarré
  14: 'eMachinesStatus.started',

  // Délai de démarrage
  15: 'eMachinesStatus.startTimeout',

  // Vérification démarrage
  16: 'eMachinesStatus.checkStarted',

  // Tous occupés
  17: 'eMachinesStatus.allBusy',

  // Introuvable
  18: 'eMachinesStatus.notFound',

  // N'est pas en cours d'exécution
  19: 'eMachinesStatus.notRunning',

  // Erreur de groupe actif
  20: 'eMachinesStatus.activeGroupError',

  // Fermé
  21: 'eMachinesStatus.closed',

  // Reservée
  22: 'eMachinesStatus.reserved',

  // Changement de durée
  23: 'eMachinesStatus.durationChange',

  // Au moins une machine démarrée
  24: 'eMachinesStatus.oneRunning',
});

export default eMachinesStatus;
