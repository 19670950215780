import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Modal } from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import MachineList from '../machineList/MachineList';
import MachineAction from '../machineAction/MachineAction';
import { delete_alert_message } from "../../slices/machineSlice";
import ButtonDisabled from '../../../modUtils/components/buttons/ButtonDisabled';
import Button from '../../../modUtils/components/buttons/Button';

export default function MachineManageModal({siteId, closeModal, equipment, buttonType}) {
    const dispatch = useDispatch();
    const { machines } = useSelector((state) => state.machine);
    const [selectedMachine, setSelectedMachine] = useState(machines.length > 0 ? machines : null);
    const [modalVisibleEtatMachine, setmodalVisibleEtatMachine] = useState(false);
    const [modalVisibleMachineAction, setModalVisibleMachineAction] = useState(false);
    const connexionToServerIsConfigured = equipment.connection_is_configured;
    
    const deleteAlertMessage = () => {
        dispatch(
            delete_alert_message()
        );
    };

    const openModalEtatMachine = () => {
        setmodalVisibleEtatMachine(true);
        closeModal();
    };
    const closeModalEtatMachine = () => {
        setmodalVisibleEtatMachine(false);
    };

    const openModalMachineAction = () => {
        setModalVisibleMachineAction(true);
        closeModal();
    };
    const closeModalMachineAction = () => {
        setModalVisibleMachineAction(false);
        deleteAlertMessage();
    };

    return (
        <>
        {buttonType === 'button' ? (
            connexionToServerIsConfigured ? 
                <Button onClick={openModalEtatMachine} color="primary">
                    GESTIONNAIRE MACHINES
                </Button>
            :
                <ButtonDisabled onClick={null}>
                    GESTIONNAIRE MACHINES
                </ButtonDisabled>
        ) : (
            <p className={!connexionToServerIsConfigured && "disabled"}
                onClick={connexionToServerIsConfigured ? openModalEtatMachine : null}
            >
                Gestionnaire machines
            </p>
        )}

            {modalVisibleEtatMachine && (
                <Modal
                    title="Gestionnaire des machines"
                    isOpen={modalVisibleEtatMachine}
                    onClose={closeModalEtatMachine}
                    width={1100}
                    height={800}
                >
                    <>
                        <MachineList
                            siteId={siteId}
                            equipment={equipment}
                            setSelectedMachine={setSelectedMachine}
                            openModalMachineAction={openModalMachineAction}
                        />
                        
                        {modalVisibleMachineAction && (
                            <Modal
                                isOpen={modalVisibleMachineAction}
                                onClose={closeModalMachineAction}
                                width={1100}
                                height={800}
                            >
                                <MachineAction
                                    siteId={siteId}
                                    equipment={equipment}
                                    machineOnClick={selectedMachine}
                                />
                            </Modal>
                        )}
                    </>
                </Modal>
            )}
        </>
    );
};