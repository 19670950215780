/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/domotic/e_domotic_type.py
Aucune modification manuelle acceptée
*/

// Type d'appareil controlé par la domotique
const eDomoticType = Object.freeze({
  // Ne pas utiliser sur les équipements. Utile pour le serveur qui peux recevoir ceci.
  0: 'eDomoticType.undefined',

  // Porte
  1: 'eDomoticType.door',

  // Lumière
  2: 'eDomoticType.lights',

  // Alarme
  3: 'eDomoticType.alarm',

  // WiFi
  4: 'eDomoticType.wifi',

  // Prise électrique
  5: 'eDomoticType.plug',

  // Vocal
  6: 'eDomoticType.vocal',
});

export default eDomoticType;
