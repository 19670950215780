import React, { useState } from "react";
import PropTypes from "prop-types";
import MenuItem from "./MenuItem";
import "./Menu.scss";
import PermissionDeniedPage from "../../errorPage/PermissionDeniedPage";
import { Modal } from '../componentsLibrary';

const Menu = ({
    items,
    openKeys,
    onOpenChange,
    collapsed,
    defaultActiveMenuKey,
    currentActiveMenuKey
}) => {
    const [currentLink, setCurrentLink] = useState(currentActiveMenuKey || defaultActiveMenuKey || items[0].itemKey);
    const handleOpenChange = (key) => {
        if (openKeys.includes(key)) {
            onOpenChange(openKeys.filter((k) => k !== key));
        } else {
            onOpenChange([key]);
        }
    };

    const [modalVisible, setModalVisible] = useState(false);

    function verifyGroupPermission() {
        let permission = false;

        items?.map((item) => {
            item?.submenus?.map((submenu) => {
                if(submenu?.itemKey === currentLink) {
                    if (submenu?.groupPermission !== undefined) {
                        permission = submenu?.groupPermission;
                    }
                }
            })
        })

        return permission;
    };
    const groupPermission = verifyGroupPermission();

    return (
        <ul className={`menu ${collapsed ? "collapsed" : ""}`}>
            <Modal
                isOpen={modalVisible}
                onClose={() => setModalVisible(false)}
                width={1100}
                zIndex={999}
            >
                <PermissionDeniedPage
                    closeModal={() => setModalVisible(false)}
                    permission={groupPermission}
                />
            </Modal>
            {items.map((item) => (
                <MenuItem
                    key={item.itemKey}
                    itemKey={item.itemKey}
                    {...item}
                    openKeys={openKeys}
                    collapsed={collapsed}
                    onOpenChange={handleOpenChange}
                    currentLink={currentLink} // Pass the current active menu item as prop
                    setCurrentLink={setCurrentLink} // Pass the function to update the active menu item
                    openModal={() => setModalVisible(true)}
                />
            ))}
        </ul>
    );
};

Menu.defaultProps = {
    collapsed: false
};

Menu.propTypes = {
    collapsed: PropTypes.bool, //définit l'état courant du menu (s'il est collapsed ou non)
    items: PropTypes.array.isRequired, //liste des élements du menu
    openKeys: PropTypes.array.isRequired //les clés des sous-menus actuellement ouverts
};
export default Menu;

// getMenuItem une fonction qui prend en paramètres le label, la clé, l'icône d'un élement du menu et ses sous-menus s'il en avait et les retourne
export function getMenuItem(
    label,
    itemKey,
    icon = null,
    submenus = null,
    onClick = null,
    component = null,
    permission
) {
    return {
        itemKey,
        icon,
        submenus,
        label,
        onClick,
        component,
        permission
    };
};