import React, { useState, useEffect, useRef } from "react";
import { Table } from "../../../modUtils/components/componentsLibrary/componentsLibrary";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  get_transactions_by_support_by_group_paginated,
  update_transaction_detail_by_group,
} from "../../slices/walletLocalSlice";
import { dateFormat } from "../../../modUtils/functions/dateFormat";
import { amountFormatWithCurrencySymbol } from "../../../modUtils/functions/numberFormat";
import { FaEdit } from "react-icons/fa";
import ConfirmationModal from "../../../modUtils/components/confirmationModal/ConfirmationModal";
import { message } from "antd";
import AlertMessage from "../../../modUtils/components/AlertMessage";
import "./SupportTransactionsList.scss";
import useScrollHandler from "../../../modUtils/hooks/useScrollHandler";

export default function SupportTransactionsList({ supportId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { groupId, loadingGroup } = useSelector((state) => state.manager);
  const { supportTransactionsById } = useSelector((state) => state.walletLocal);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false); // État pour le modal de confirmation
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [transactionToEdit, setTransactionToEdit] = useState();
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const defaultOffset = 0;
  const defaultLimit = 10;
  const incrementalLimit = 2;
  const [limit, setLimit] = useState(defaultLimit); // Nombre d'éléments à charger à chaque fois
  const [offset, setOffset] = useState(defaultOffset); // Offset actuel pour la pagination
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMoreDataToLoad, setHasMoreDataToLoad] = useState(false);
  const getSupportTransactions = async (offset, limit) => {
    setLoading(true);
    if (offset == defaultOffset) {
      setTransactions([]);
    }

    try {
      setLoading(true);
      if (offset !== defaultOffset) {
        setIsLoadingMore(true);
      }

      const response = await dispatch(
        get_transactions_by_support_by_group_paginated({
          groupId,
          supportId,
          ordering: "-date",
          limit,
          offset,
        })
      ).unwrap();
      if (response.results.length === 0) {
        setHasMore(false);
      } else {
        setTransactions((prevData) => [...prevData, ...response.results]);
        setOffset(offset + limit);
        let hasMore = response.next !== null ? true : false; //tester si y'a encore de données à récupérer
        setHasMoreDataToLoad(hasMore);
      }
    } catch (error) {
      message.error({
        content: (
          <AlertMessage
            status={error?.status}
            alertMessage={
              "Une erreur est survenue lors du chargement de la liste des transcations de ce badge"
            }
            errorDetail={error?.data?.detail}
          />
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    //appel à l'api support_transaction_paginated_get pour récupérer la liste des transactions d'un badge
    setTransactions([]);
    setOffset(0);
    setLimit(defaultLimit);
    if (groupId && supportId) {
      getSupportTransactions(defaultOffset, defaultLimit);
    }
  }, [groupId, supportId]);
  const onScrollEnd = () => {
    if (!loading && hasMore) {
      getSupportTransactions(offset, incrementalLimit);
    }
  };

  const { tableRef, handleScroll } = useScrollHandler(onScrollEnd);
  const updateTransactionIsSuspectedFraud = async () => {
    setConfirmLoading(true);
    try {
      await dispatch(
        update_transaction_detail_by_group({
          groupId,
          supportId,
          transactionId: transactionToEdit?.id,
          isSuspectedFraud: !transactionToEdit.is_suspected_fraud,
        })
      ).unwrap();
    } catch (error) {
      message.error({
        content: (
          <AlertMessage
            status={error?.status}
            alertMessage={"Une erreur est survenue !"}
            errorDetail={error?.data?.detail}
          />
        ),
      });
    } finally {
      setConfirmLoading(false);
      setConfirmModalVisible(false);
      setTransactionToEdit();
    }
  };

  const handleEditTransaction = (transaction) => {
    setTransactionToEdit(transaction);
    setConfirmModalVisible(true);
  };

  const handleConfirmCancel = () => {
    setConfirmModalVisible(false);
    setTransactionToEdit();
  };

  const columns = [
    {
      title: t("table.tabColLastVisit"),
      dataIndex: "date_probable",
      key: "date_probable",
      width: "10%",
      render: (text) => dateFormat(text),
    },
    {
      title: t("table.tabColEquipment"),
      dataIndex: "equipment_name",
      key: "equipment_name",
      width: "20%",
      align: "left",
    },
    {
      title: t("table.tabColAmount"),
      dataIndex: "amount",
      key: "amount",
      width: "10%",
      bold: true,
      render: (text) => <span>{amountFormatWithCurrencySymbol(text)}</span>,
    },
    {
      title: t("table.tabColBonus"),
      dataIndex: "prime",
      key: "prime",
      width: "10%",
      bold: true,
      render: (text) => <span>{amountFormatWithCurrencySymbol(text)}</span>,
    },
    {
      title: t("table.tabColAccountBalanceBefore"),
      dataIndex: "account_balance_before",
      key: "account_balance_before",
      width: "10%",
      bold: true,
      render: (text) => <span>{amountFormatWithCurrencySymbol(text)}</span>,
    },
    {
      title: t("table.tabColAccountBalance"),
      dataIndex: "account_balance",
      key: "account_balance",
      className: "table-column-accent",
      headerClassName: "table-column-accent",
      width: "10%",
      render: (text) => <span>{amountFormatWithCurrencySymbol(text)}</span>,
    },
    {
      title: "FRAUDES",
      dataIndex: "is_suspected_fraud",
      key: "is_suspected_fraud",
      width: "5%",
      render: (text, record) => (
        <div className="flex items-center justify-between">
          {text == true && (
            <img
              className="mr-2"
              width={20}
              src={process.env.PUBLIC_URL + "/img/badgesIcons/fraudeIcone.svg"}
            />
          )}
          <span
            className="ml-auto"
            onClick={() => handleEditTransaction(record)}
          >
            <FaEdit color="#338EF5" size={20} />
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      <div ref={tableRef}>
        <Table
          columns={columns}
          dataSource={transactions}
          pagination={false}
          scroll={{ y: 350 }}
          onScroll={handleScroll}
        />
      </div>{" "}
      {/* Modal de confirmation pour modifier état suspect_fraud d'une transaction*/}
      <ConfirmationModal
        onOk={updateTransactionIsSuspectedFraud}
        onCancel={handleConfirmCancel}
        visible={confirmModalVisible}
        confirmLoading={confirmLoading}
        content={
          <div>
            {transactionToEdit?.is_suspected_fraud == false ? (
              <span>
                {" "}
                Voulez-vous vraiment signaler que cette vente est suspecte de
                fraude ?
              </span>
            ) : (
              <span>
                Voulez-vous vraiment retirer cette vente de la liste des
                suspects de fraude ?
              </span>
            )}
          </div>
        }
      />
    </>
  );
}
