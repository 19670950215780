import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import "./Popover.scss"

const Popover = ({ placement, content, trigger, children, open, onOpenChange, style, className }) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(open);
  const popoverRef = useRef(null);
  const handleIsPopoverVisibleChange = () => {  
    if (onOpenChange) {
      onOpenChange();
    }
    setIsPopoverVisible(!isPopoverVisible);    
  };

  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setIsPopoverVisible(false);
    }
  };

  const attachTriggerHandlers = () => {
    const triggerHandlers = {
      click: { onClick: handleIsPopoverVisibleChange },
      hover: { onMouseEnter: handleIsPopoverVisibleChange, onMouseLeave: handleIsPopoverVisibleChange },
      focus: { onFocus: handleIsPopoverVisibleChange, onBlur: handleIsPopoverVisibleChange },
      contextMenu: { onContextMenu: handleIsPopoverVisibleChange },
    };
  
    if (Array.isArray(trigger)) {
      return trigger.reduce((acc, t) => ({ ...acc, ...triggerHandlers[t] }), {});
    }
  
    return triggerHandlers[trigger] || {};
  };
  //add useffect
  useEffect(() => {
    setIsPopoverVisible(open);  // Update the state when the external prop changes
  }, [open]);
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={popoverRef} style={{ position: 'relative', display: 'inline-block' }} {...attachTriggerHandlers()}>
      <div
        style={{ cursor: 'pointer' }}
        onClick={handleIsPopoverVisibleChange}
      >
        {children}
      </div>
      {isPopoverVisible && (
        
        <div role="tooltip" className={`popover ${placement}`}>
         {/* <div className='popover-arrow'><span className="popover-arrow-content"></span></div>  */}
          <div className='popover-content'>{content}</div>
        </div>
      )}
    </div>
  );
};

Popover.propTypes = {
  placement: PropTypes.oneOf(['topLeft', 'topRight', 'bottomLeft', 'bottomRight', 'middle']),
  content: PropTypes.node,
  trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};
  
Popover.defaultProps = {
    placement: 'bottomLeft',
    trigger: 'click',
  }

export default Popover;
