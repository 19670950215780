import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_group_detail } from "../slices/managerSlice";
import { Form, Input, Button, message } from "antd";
import { Spinner } from "../../modUtils/components/componentsLibrary/componentsLibrary";
import SubmitButton from "../../modUtils/components/buttons/submitButton/SubmitButton";
import CityInput from "../../modLocation/components/CityInput";

import AlertMessage from "../../modUtils/components/AlertMessage";
function GroupInformationsForm() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const { selectedGroup, groupDetail } = useSelector((state) => state.manager);
  const group = localStorage.getItem("group");
  const groupId = selectedGroup?.id || group;
  const [selectedCity, setSelectedCity] = useState();

  const getGroupDetail = async () => {
    try {
      setLoading(true);
      await dispatch(get_group_detail({ groupId })).unwrap();
      setLoading(false);
    } catch (error) {
      message.error({
        content: (
          <AlertMessage
            status={error.status}
            alertMessage="Une erreur est surevnue lors de la récupération des informations de votre société"
            errorDetail={error.data.detail}
          />
        ),
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    groupId && getGroupDetail();
    setLoading(false);
  }, [groupId]);

  useEffect(() => {
    form.resetFields();
    if (groupDetail) {
      let address = groupDetail?.site_default?.location_address || "";

      setSelectedCity({
        id: address?.city_id,
        postalCode: address?.zipcode,
        name: address?.city,
      });
      form.setFieldsValue({
        name: groupDetail?.name || "",
        locationStreet:
          groupDetail?.site_default?.location_address?.street || "",
        locationNumber:
          groupDetail?.site_default?.location_address?.street_number || "",
        country: groupDetail?.site_default?.location_address?.country || "",
        locationAdditional:
          groupDetail?.site_default?.location_address?.additional || "",
      });
    }
  }, [groupDetail, groupId, form]);

  const handleSubmit = (values) => {
    console.log(values);
  };
  console.log(groupDetail?.site_default?.location_address);
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div>
            <div> Raison sociale : </div>
            <div className="text-secondary-color font-semibold">
              {groupDetail?.name}
            </div>
            <div> Adresse : </div>
            <div className="text-secondary-color font-semibold">
              {groupDetail?.site_default?.location_address?.street_number &&
                `${groupDetail?.site_default?.location_address?.street_number},`}
              {groupDetail?.site_default?.location_address?.street}
            </div>
            {groupDetail?.site_default?.location_address?.additional && (
              <>
                <div>Complément d'adresse : </div>
                <div className="text-secondary-color font-semibold">
                  {groupDetail?.site_default?.location_address?.additional}
                </div>
              </>
            )}
            <div>Code Postal - Ville :</div>
            <div className="text-secondary-color font-semibold">
              {`${groupDetail?.site_default?.location_address?.zipcode} -
              ${groupDetail?.site_default?.location_address?.city}`}
            </div>
            <div>Pays :</div>
            <div className="text-secondary-color font-semibold">
              {groupDetail?.site_default?.location_address?.country}
            </div>
          </div>

          {/* <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Form.Item
              label="Raison sociale"
              name="name"
              // rules={[
              //   {
              //     required: true,
              //     message: "Veuillez entrer la raison sociale / Nom de votre société",
              //   },
              // ]}
            >
              <Input placeholder="" readOnly />
            </Form.Item>
            <div className="grid grid-cols-4 gap-0 lg:gap-2">
              <div className="col-span-4 xl:col-span-1">
                <Form.Item label="N° adresse" name="locationNumber">
                  <Input type="number" placeholder="" readOnly />
                </Form.Item>
              </div>
              <div className="col-span-4 xl:col-span-3">
                <Form.Item label="Rue" name="locationStreet">
                  <Input placeholder="" readOnly />
                </Form.Item>
              </div>
            </div>

            <Form.Item label="Complément d'adresse" name="locationAdditional">
              <Input placeholder="" readOnly />
            </Form.Item>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-2">
              <Form.Item label="Code Postal - Ville" name="locationCity">
                <CityInput
                  defaultValue={selectedCity}
                  setValue={setSelectedCity}
                  readOnly
                />
              </Form.Item>
              <Form.Item label="Pays" name="country">
                <Input placeholder="" readOnly />
              </Form.Item>
            </div>

           
            //TODO fonctionnalité à rajouter quand l'api update user detail est prête
            <Form.Item>
              <SubmitButton onClick={() => handleSubmit()} label="ENREGISRTER" />
            </Form.Item> 
          </Form> */}
        </>
      )}
    </>
  );
}

export default GroupInformationsForm;
