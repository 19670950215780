import React, { useState, useEffect } from "react";
import {
  Input,
  Spinner,
} from "../../modUtils/components/componentsLibrary/componentsLibrary";
import { useDispatch, useSelector } from "react-redux";
import { get_transaction_sale_paginated_get } from "../slices/bankingSlice";
import { dateFormat } from "../../modUtils/functions/dateFormat";
import { amountFormatWithCurrencySymbol } from "../../modUtils/functions/numberFormat";
import { message } from "antd";
import AlertMessage from "../../modUtils/components/AlertMessage";

export default function PaiementCBInfos({ saleId }) {
  const dispatch = useDispatch();
  const { saleTransactions } = useSelector((state) => state.banking);
  const { groupId } = useSelector((state) => state.manager);
  const [loading, setLoading] = useState(true);

  const getTransactionsBySale = async () => {
    setLoading(true);
    try {
      await dispatch(
        get_transaction_sale_paginated_get({ groupId: groupId, saleId: saleId })
      ).unwrap();
    } catch (error) {
      message.error({
        content: (
          <AlertMessage
            status={error?.status}
            alertMessage={`Une erreur est survenue lors de la récupération des transactions de la vente`}
            errorDetail={error?.data?.detail}
          />
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (groupId && saleId) {
      getTransactionsBySale();
    }
  }, [groupId, saleId]);

  return (
    <div className="transactionDetailsForm px-9">
      {loading ? (
        <Spinner />
      ) : (
       
        
  <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
    <div className="col-span-1 lg:col-span-2">
      <span>Date</span>
      <Input
        id="date_creation"
        value={dateFormat(saleTransactions[0]?.date_creation)}
        size={30}
        className="w-full"
        disabled
      />
    </div>
    <div className="col-span-1 lg:col-span-2">
      <span>Montant</span>
      <Input
        id="amount"
        value={amountFormatWithCurrencySymbol(
          saleTransactions[0]?.amount
        )}
        size={30}
        className="w-full"
        disabled
      />
    </div>
    <div className="col-span-1">
      <span>Autorisation</span>
      <Input
        id="autorisation"
        value={saleTransactions[0]?.autorisation}
        size={30}
        className="w-full"
        disabled
      />
    </div>
    <div className="col-span-1">
      <span>N° remise</span>
      <Input
        id="remise_number"
        value={saleTransactions[0]?.remise_number}
        size={30}
        className="w-full"
        disabled
      />
    </div>
    <div className="col-span-1">
      <span>N° transaction</span>
      <Input
        id="transaction_number"
        value={saleTransactions[0]?.transaction_number}
        size={30}
        className="w-full"
        disabled
      />
    </div>
    <div className="col-span-1">
      <span>Code erreur</span>
      <Input
        id="code_erreur"
        value={""}
        size={30}
        className="w-full"
      />
    </div>
    <div className="col-span-1">
      <span>Siret</span>
      <Input
        id="siret"
        value={saleTransactions[0]?.siret}
        size={30}
        className="w-full"
        disabled
      />
    </div>
    <div className="col-span-1 lg:col-span-3">

      <span>N° carte de crédit</span>
      <Input
        id="credit_card_number"
        value={saleTransactions[0]?.credit_card_number}
        size={30}
        className="w-full"
        disabled
      />
    </div>
    <div className="col-span-1">

      <span>Enseigne</span>
      <Input
        id="enseigne"
        value={saleTransactions[0]?.enseigne}
        size={30}
        className="w-full"
        disabled
      />
    </div>
    <div className="col-span-1">

      <span>N° logique</span>
      <Input
        id="logical_number"
        value={saleTransactions[0]?.logical_number}
        size={30}
        className="w-full"
        disabled
      />
    </div>
    <div className="col-span-1">

      <span>Code de forçage</span>
      <Input
        id="force_code"
        value={saleTransactions[0]?.force_code}
        size={30}
        className="w-full"
        disabled
      />
    </div>      <div className="col-span-1">

      <span>Mode de lecture</span>
      <Input
        id="reading_mod"
        value={saleTransactions[0]?.reading_mod}
        size={30}
        className="w-full"
        disabled
      />
    </div>
    <div className="col-span-1">

      <span>Identifiant de l'application</span>
      <Input
        id="application_id"
        value={saleTransactions[0]?.application_id}
        size={30}
        className="w-full"
        disabled
      />
    </div>
    <div className="col-span-1 lg:col-span-3">

      <span>Label de l'application</span>
      <Input
        id="application_label"
        value={saleTransactions[0]?.application_label}
        size={30}
        className="w-full"
        disabled
      />
    </div>
  
  </div>

      )}
    </div>
  );
}
