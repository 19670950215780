import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, Button } from "../../../modUtils/components/componentsLibrary/componentsLibrary";
import { imachines_gb_machine_kinds_by_equipment_by_site_by_group_get } from "../../slices/machineSlice";
import MachineCard from "../machineCard/MachineCard";

export default function MachineList({siteId, equipment, setSelectedMachine, openModalMachineAction}) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const { groupId, loadingGroup } = useSelector((state) => state.manager);
    const { machines } = useSelector((state) => state.machine);

    async function fetchData() {
        setLoading(true);
        await dispatch(
            imachines_gb_machine_kinds_by_equipment_by_site_by_group_get({
                groupId: groupId,
                equipmentId: equipment.id,
                siteId: siteId,
            })
        );
        setLoading(false);
    };

    useEffect(() => {
        if (groupId || equipment.id) {
            fetchData();
        }},
        [groupId, equipment.id, siteId]
    );
  
    return (
        <div className="font-bold text-base text-white text-center mt-4">
            {loading ? 
                <Spinner />
            : machines[0] !== undefined && machines[0].length > 0 ? (
                <div className="flex flex-wrap justify-center bg-slate-200">
                    <Button type="image" className="absolute right-10 top-1.5"> {/* btn refresh */}
                        <img 
                            src={process.env.PUBLIC_URL + "/img/refreshIcon.png"}
                            alt="refresh"  
                            onClick={() => fetchData()}
                        />
                    </Button>

                    <MachineCard 
                        machines={machines}
                        setSelectedMachine={setSelectedMachine}
                        openModalMachineAction={openModalMachineAction}
                    />
                </div>
            ) : (
                <div className="flex flex-col items-center"> {/* Aucune machine */}
                    <img
                        src={process.env.PUBLIC_URL + "/img/design/noData.png"}
                        alt="no data to display"
                        width={50}
                    />
                    <div className="text-black">
                        Aucune machine
                        <span title="Pensez à vérifier la connectivité de vos installations"> ⓘ</span>
                    </div>
                </div>
            )}
        </div>
    );
};