import React , {useState} from 'react'
import "./Widget.scss"
function Widget({title, content}) {
    const [isVisible, setIsVisible]=useState(true)
    function hideWidget(){
        setIsVisible(false)
    }
  return (
   isVisible && <div className='widget'>
        <div className='widget-header flex-display flex-row align-items-center justify-content-between'>
            <div className='widget-title'>{title}</div>
             <div onClick={hideWidget}> X </div> 
        </div>
        <div className='widget-content'>{content}</div>
    </div>
  )
}

export default Widget