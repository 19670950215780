import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Tabs, { TabPane } from '../../../modUtils/components/componentsLibrary/tabs/Tabs';
import { Modal } from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import DevicesHistory from '../devicesHistory/DevicesHistory';
import EquipmentDevicesList from '../equipmentDevicesList/EquipmentDevicesList';
import DeviceDetailedHistory from '../deviceDetailedHistory/DeviceDetailedHistory';
import { hasPermission } from "ck_commun/src/app/modUtils/functions/handlePermissions";
import PermissionDeniedPage from "ck_commun/src/app/modUtils/components/errorPage/PermissionDeniedPage";
import ButtonDisabled from '../../../modUtils/components/buttons/ButtonDisabled';
import './DevicesManageModel.scss';
import Button from '../../../modUtils/components/buttons/Button';

export default function DevicesManageModal({
    closeModal,
    siteName,
    equipment,
    buttonType
}) {
    const [modalVisible, setModalVisible] = useState(false)
    const [openTab, setOpenTab] = useState()
    const { equipmentDevices } = useSelector((state) => state.equipment);
    const [selectedDevice, setSelectedDevice] = useState(
        equipmentDevices.length > 0 ? equipmentDevices[0] : null
    );
    const { selectedGroup } = useSelector((state) => state.manager);
    const permissions = selectedGroup?.permissions;
    const verifyPermission = hasPermission('mod_equipment:show_device', permissions);
    const groupPermissions = selectedGroup?.plan?.permissions;
    const verifyGroupPermission = hasPermission('mod_equipment:show_device', groupPermissions);

    // Update selectedDevice when equipmentDevices changes
    useEffect(() => {
        if (equipmentDevices.length > 0) {
            setSelectedDevice(equipmentDevices[0]);
        }
    }, [equipmentDevices]);

    const openModal = () => {
        setModalVisible(true);
        closeModal();
    };

    const closeDevicesManageModal = () => {
        setOpenTab('1');
        setSelectedDevice(equipmentDevices[0]);
        setModalVisible(false);
    };

    const onChange = (tabKey) => {
        setOpenTab(tabKey);
    };

    const selectDevice = (id) => {
        setSelectedDevice(equipmentDevices.find((e) => e.id == id));
        setOpenTab('2');
    };

    return (
        <>
        {buttonType === 'button' ?
            verifyPermission && verifyGroupPermission ?
                <Button
                    onClick={openModal}
                    color="primary"
                    className="h-10 w-full whitespace-nowrap ml-[5px]"
                    size="sm"
                >
                    GESTION DES PERIPHERIQUES
                </Button>
            :
                <ButtonDisabled
                    onClick={openModal}
                    className="h-10 w-full whitespace-nowrap ml-[5px]"
                    size="sm"
                >
                    GESTION DES PERIPHERIQUES
                </ButtonDisabled>
        : (
            <p className={`${!verifyPermission || !verifyGroupPermission ? 'disabled' : ''}`} onClick={openModal}>Gestionnaire des périphériques</p>
        )}
        
        {modalVisible && (
            <Modal
                title="GESTION DES PÉRIPHÉRIQUES"
                isOpen={modalVisible}
                onClose={closeDevicesManageModal}
                width={1100}
                zIndex={999}
            >
            {!verifyPermission ?
                <PermissionDeniedPage
                    permission={verifyGroupPermission}
                />
            :
                <div className="flex-display flex-row justify-content-around">
                    <div style={{ width: '50%', marginRight: '25px' }}>
                        <EquipmentDevicesList
                            siteName={siteName}
                            equipment={equipment}
                            selectDevice={selectDevice}
                        />
                    </div>
                    <div style={{ width: '50%' }}>
                        <Tabs
                            key={selectedDevice?.id + '_' + openTab}
                            onChange={onChange}
                            headerMatchesBodyWidth
                            defaultActiveKey={null}
                            openTab={openTab}
                            tabHeight="710px"
                        >
                            <TabPane label="DERNIERS HISTORIQUES" tabKey="1">
                                <DevicesHistory equipment={equipment} />
                            </TabPane>
                            <TabPane label="HISTORIQUES PAR DÉTAIL" tabKey="2" disabled={!selectedDevice ? true : false}>
                                <DeviceDetailedHistory
                                    equipment={equipment}
                                    device={selectedDevice}
                                />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            }
            </Modal>
        )}
        </>
    );
};
