/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/device/e_device_code_machines.py
Aucune modification manuelle acceptée
*/

// Codes erreurs spécifiques à la catégorie de périphériques machines
// 
// Anciennement EFEATURECID_MACHINES_CODE
const eDeviceCodeMachines = Object.freeze({
  // Machines Action Avant
  100: 'eDeviceCodeMachines.actionBefore',

  // Machines au repos
  101: 'eDeviceCodeMachines.idle',

  // Machines en fonctionnement
  102: 'eDeviceCodeMachines.running',

  // Machines fermées
  103: 'eDeviceCodeMachines.closed',

  // Machines en pause
  104: 'eDeviceCodeMachines.pause',

  // Machines non démarrées
  110: 'eDeviceCodeMachines.noStart',

  // Durée excessive des machines
  111: 'eDeviceCodeMachines.durationExcessive',

  // Machines hors service
  112: 'eDeviceCodeMachines.outOfOrder',

  // Erreur externe des machines
  113: 'eDeviceCodeMachines.externalError',

  // Arrêt d'urgence des machines
  114: 'eDeviceCodeMachines.emergencyStop',
});

export default eDeviceCodeMachines;
