/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/tb/e_application_type.py
Aucune modification manuelle acceptée
*/

// Type des applications de paiement
const eApplicationType = Object.freeze({
  // Inconnu
  0: 'eApplicationType.unknown',

  // Cb par insertion
  1: 'eApplicationType.cbEmv',

  // CB sans contact
  2: 'eApplicationType.cbCl',
});

export default eApplicationType;
