import React, { useState } from "react"
import { useTranslation } from 'react-i18next';
import Language from "../../locale/language"

import "./LanguageSwitcher.scss"

export function CountryFlag({ flagIcon, languageLabel, countryCode, onClick }) {
    return (
        <div onClick={onClick} key={countryCode} className="country-flag flex-display flex-column align-items-center" >
            <img src={process.env.PUBLIC_URL + `/img/flags/${flagIcon}`}></img>
            <div className="language-label">{languageLabel}</div>
        </div>)
}
export default function LanguageSwitcher() {
    const { i18n } = useTranslation();
    const [showFlags, setShowFlags] = useState(false);

    const toggleFlags = () => {
        setShowFlags(!showFlags);
    };

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        setShowFlags(false); // Pour cacher la liste après avoir sélectionné une langue
    };

    return (
        <div className="flex-display flex-row justify-content-end language-switcher">
            <img
                className={`language-expand-icon ${showFlags && 'expanded'}`}
                src={process.env.PUBLIC_URL + "/img/Fleche.svg"}
                onClick={toggleFlags}
            ></img>

            <div className="flag-list flex-display flex-row justify-content-end">
                {showFlags ? (
                    <>
                        <CountryFlag flagIcon="en.png" languageLabel="English" countryCode={Language.EN} onClick={() => changeLanguage(Language.EN)} />
                        <CountryFlag flagIcon="fr.png" languageLabel="Français" countryCode={Language.FR} onClick={() => changeLanguage(Language.FR)} />
                        {/* Ajoutez d'autres drapeaux de pays ici */}
                    </>
                ) : (
                    <CountryFlag
                        flagIcon={i18n.language === Language.EN ? "en.png" : "fr.png"}
                        countryCode={i18n.language}
                    />
                )}
            </div>

        </div>
    );
}