import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Input,
  Spinner,
} from "../../modUtils/components/componentsLibrary/componentsLibrary";
import { get_transactions_by_support_by_group_paginated } from "../slices/walletLocalSlice";
import { hasPermission } from "../../modUtils/functions/handlePermissions";
import PermissionDeniedPage from "../../modUtils/components/errorPage/PermissionDeniedPage";
import { message } from "antd";
import AlertMessage from "../../modUtils/components/AlertMessage";
import PermissionFadeContainer from "../../modUtils/components/permissionFadeContainer/PermissionFadeContainer";

export default function SupportTransactionDetails({ saleId, supportId }) {
  const dispatch = useDispatch();
  const { supportTransactionsById } = useSelector((state) => state.walletLocal);
  const { groupId, selectedGroup } = useSelector((state) => state.manager);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const permissions = selectedGroup?.permissions;
  const verifyPermission = hasPermission('mod_wallet_local:manage_support', permissions);
  const groupPermissions = selectedGroup?.plan?.permissions;
  const verifyGroupPermission = hasPermission('mod_wallet_local:manage_support', groupPermissions);
  const getSupportTransactionsDetails = async () => {
    setLoadingDetails(true);
    try {
      await dispatch(
        get_transactions_by_support_by_group_paginated({
          groupId,
          supportId,
          saleId,
        })
      ).unwrap();
    } catch (error) {
      message.error({
        content: (
          <AlertMessage
            status={error?.status}
            alertMessage={
              "Une erreur est survenue lors de la récupération des informations de paiement par badge!"
            }
            errorDetail={error?.data?.detail}
          />
        ),
      });
    } finally {
      setLoadingDetails(false);
    }
  };

  useEffect(() => {
    if (verifyPermission) {
      if (groupId && supportId && saleId) {
        getSupportTransactionsDetails();
      }
    }
  }, [groupId, supportId, saleId]);

  return (
    <div
      className="transactionDetailsForm px-4"
    >
      {!verifyPermission ?
        <PermissionDeniedPage
            direction="row"
            permission={verifyGroupPermission}
        />
      : loadingDetails ?
        <Spinner/>
      :
        <PermissionFadeContainer
            permissions={verifyGroupPermission}
        >
            <div className="flex-display align-items-end">
            <div style={{ width: "50%" }}>
                <table>
                <tbody>
                    <tr>
                    <td>
                        <span>N° Utilisateur</span>
                    </td>
                    <td>
                        <Input label="" value="" disabled size={30} />
                    </td>
                    </tr>
                    <tr>
                    <td>
                        <span>Crédit début transaction</span>
                    </td>
                    <td>
                        <Input
                        value={supportTransactionsById[0]?.account_balance_before}
                        disabled
                        size={30}
                        />
                    </td>
                    </tr>
                    <tr>
                    <td>
                        <span>Montant transaction</span>
                    </td>
                    <td>
                        <Input
                        size={30}
                        value={supportTransactionsById[0]?.amount}
                        disabled
                        />
                    </td>
                    </tr>
                    <tr>
                    <td></td>
                    <td>
                        <hr />
                    </td>
                    </tr>
                    <tr>
                    <td>
                        <span>Solde fin transaction</span>
                    </td>
                    <td>
                        <Input
                        value={supportTransactionsById[0]?.account_balance}
                        size={30}
                        disabled
                        />
                    </td>
                    </tr>
                </tbody>
                </table>
            </div>
            </div>
        </PermissionFadeContainer>
      }
    </div>
  );
}
