import React from "react";
import SharedEquipmentPage from "ck_commun/src/app/modSharedEquipment/pages/sharedEquipmentPage/SharedEquipmentPage";
import { useSelector } from "react-redux";
import { hasPermission } from "ck_commun/src/app/modUtils/functions/handlePermissions";
import PermissionDeniedPage from "ck_commun/src/app/modUtils/components/errorPage/PermissionDeniedPage";

export default function SharedEquipment() {
  const { selectedGroup } = useSelector((state) => state.manager);
  
  const permissions = selectedGroup?.permissions;
  const verifyPermission = hasPermission('mod_equipment_shared:can_access', permissions);
  const groupPermissions = selectedGroup?.plan?.permissions;
  const verifyGroupPermission = hasPermission('mod_equipment_shared:can_access', groupPermissions);


  return (
    <>
    {!verifyPermission ?
        <PermissionDeniedPage
            permission={verifyGroupPermission}
        />
    :
        <SharedEquipmentPage/>
    }
    </>
  );
}
