import React from 'react'
 import { Button } from '../componentsLibrary/componentsLibrary'
 import { useTranslation } from "react-i18next";
function DismissButton({ onClick }) {
    const { t } = useTranslation();
  return (
    <Button
      type="primary"
      onClick={onClick}
      htmltype='reset'
      style={{ backgroundColor: '#FF0000', borderColor:"#FF0000", height: '40px' }}
    >
       {t("common.transButtonDismiss")}
    </Button>
  )
}

export default DismissButton