import React, { useState } from "react";
import OptionCollapsed from "./OptionCollapsed";
import Options from "./Options";
import OptionHidden from "./OptionHidden";
import OptionLoading from "./OptionLoading";

export default function Select({id, label, options, onClick, fetchAPI}) {
    const [optionsHidden, setOptionsHidden] = useState(false);
    const [searchValue, setSearchValue] = useState("Tous");
    const [loading , setLoading] = useState(false);

    function handleClick(option) {
        setOptionsHidden(!optionsHidden);
        document.getElementById(id).value = option.id;
        setSearchValue(option.name);
        if (typeof onClick === "function") {
            onClick();
        }
    };

    function handleClickfetchAPI() {
        if (typeof fetchAPI === "function" && options.length === 0) {
            fetchAPI(setLoading);
        }
        setOptionsHidden(!optionsHidden);
    };

    return (
        <div className={`flex flex-col text-primary-color mr-2.5 mb-4`}>
            <OptionCollapsed
                onClick={() => handleClickfetchAPI()}
                optionsHidden={optionsHidden}
                searchValue={searchValue}
                label={label}
            />

            <div className={`${!optionsHidden && "hidden"}`}>
                {loading ?
                    <OptionLoading/>
                :
                    <Options
                        options={options}
                        id={id}
                        onClick={(option) => handleClick(option)}
                    />
                }
            </div>

            <OptionHidden
                onClick={() => optionsHidden && setOptionsHidden(!optionsHidden)}
                optionsHidden={optionsHidden}
            />
        </div>
    );
};