import React from "react";
import { Routes, Route } from "react-router-dom";
import Error404Page from "ck_commun/src/app/modUtils/pages/Error404Page";

export default function CkRoutes({...props}) {
    return (
        <Routes>
            {props.children}

            <Route path="*" element={<Error404Page/>}/>
        </Routes>
    );
};