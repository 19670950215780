/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/device/e_device_code_banking_system.py
Aucune modification manuelle acceptée
*/

// Codes erreurs spécifiques à la catégorie de périphériques bancaires
// 
// Anciennement EFEATURECID_BANKINGSYSTEM_CODES
const eDeviceCodeBankingSystem = Object.freeze({
  // Système bancaire en maintenance
  100: 'eDeviceCodeBankingSystem.inMaintenance',
});

export default eDeviceCodeBankingSystem;
