import React, { useState } from "react";
import Calendar from "react-calendar";
import moment from "moment";
import PropTypes from "prop-types";
import "react-calendar/dist/Calendar.css";
import "./DatePicker.scss";

const DatePicker = ({
  format,
  selectedDate,
  onDateChange,
  label,
  placeholder,
}) => {
  // State pour gérer l'affichage ou non du calendrier
  const [showCalendar, setShowCalendar] = useState(false);

  // Fonction pour basculer l'affichage du calendrier
  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  // Fonction pour gérer le changement de date
  const handleDateChange = (date) => {
    // Appeler la fonction de changement de date fournie par le composant parent
    onDateChange(formatSelectedDate(date));
    // Masquer le calendrier après la sélection d'une date
    toggleCalendar();
  };

  // Fonction pour formater la date sélectionnée selon le format spécifié
  const formatSelectedDate = (date) => {
    return date ? moment(date, format).format(format) : "";
  };

  const parsedDate = moment(selectedDate, format);
  // Format the parsed date into "MM/DD/YYYY" format (format par défaut de Calendar)
  const formattedDate = parsedDate.isValid()
    ? parsedDate.format("MM/DD/YYYY")
    : null;

  //console.log('selected Date', selectedDate, '|||', formatSelectedDate(selectedDate), '|||',  formattedDate)

  return (
    <div className="date-picker">
      <div className="select-label">{label}</div>
      <div className="flex-display flex-row select-date">
        <input
          type="text"
          value={formatSelectedDate(selectedDate)}
          onClick={toggleCalendar}
          placeholder={placeholder}
        />
        <img
          src={process.env.PUBLIC_URL + "/img/calendar.png"}
          alt="calendar-icon"
        />
      </div>
      {/* Affichage conditionnel du calendrier en fonction de l'état showCalendar */}
      {showCalendar && (
        <div className="calendar">
          <Calendar
            value={formattedDate}
            onChange={(date) => handleDateChange(date)}
          />
        </div>
      )}
    </div>
  );
};

DatePicker.defaultProps = {
  placeholder: "Select a date",
  format: "DD/MM/YYYY HH:mm",
};

DatePicker.propTypes = {
  format: PropTypes.string,
};

export default DatePicker;
