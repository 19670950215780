import jwt_decode from "jwt-decode";
import { httpPost } from "../apis/httpConfig";
export const getAccessToken = () => {
  return localStorage.getItem("access");
};
export const setAccessToken = (token) => {
  return localStorage.setItem("access", token);
};
export const getRefreshToken = () => {
  return localStorage.getItem("refresh");
};
export const setRefreshToken = (token) => {
  return localStorage.setItem("refresh", token);
};
export const decodeToken = (token) => {
  return jwt_decode(token);
};
export const isAccessTokenExpired = () => {
  var accessToken = getAccessToken();
  var isExpired =
    decodeToken(accessToken).exp * 1000 < Date.now() ? true : false;
  return isExpired;
};
export const refreshAuthToken = async () => {
  const expiredToken = getRefreshToken();
  try {
    const response = await httpPost(
      `/mod_authentication/tokens/from_refresh/`,
      {
        refresh: expiredToken,
      }
    );
    setAccessToken(response.data.access);
    setRefreshToken(response.data.refresh);
  } catch (error) {
    console.error("Erreur lors du rafraîchissement du token", error);
    localStorage.clear();
  }
};
