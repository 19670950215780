import React, { useEffect, useState } from "react";
import moment from "moment";
// import "moment/locale/fr";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setMomentLocale } from "../../../modUtils/functions/dateFormat";
import IncreaseIcon from "../../assets/images/increase.svg"
import DecreaseIcon from "../../assets/images/decrease.svg"
import EqualIcon from "../../assets/images/equal.svg"
import {
  amountFormatWithCurrencySymbol,
  amountFormat,
} from "../../../modUtils/functions/numberFormat";
import { eCdbsPaymentType } from "../../../../commun_global/enums";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Card } from "../../../modUtils/components/componentsLibrary/componentsLibrary";

//import ChartDataLabels  from "chartjs-plugin-datalabels";
import "./ActivityCard.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  //ChartDataLabels
);

export default function ActivityCard({ title, indexOfPeriod }) {
  const { t } = useTranslation();
  const [chartLabels, setchartLabels] = useState([]);
  const [chartData, setchartData] = useState([]);
  const {
    dashboardCumulativeByGroup,
    dashboardCumulativeLatestByGroup,
    loading,
  } = useSelector((state) => state.cumulative);

  //mettre à jour moment.locale() selon la langue choisie dans le switcher du rightSiderBar
  setMomentLocale();

  /*
   * indexOfPeriod=0 ; title=aujourd'hui
   * indexOfPeriod=1 ; title=cette semaine
   * indexOfPeriod=2 ; title=ce mois
   * indexOfPeriod=3 ; title=cette année
   */

  useEffect(() => {
    async function fetchData() {
      setchartLabels([]);
      setchartData([]);
      //fournir les labels et les données correspondants au graphique à barres selon la période
      if (indexOfPeriod === 0) {
        [...dashboardCumulativeLatestByGroup?.days]
          ?.reverse()
          .map((item, index) => {
            setchartLabels((chartLabels) => [
              ...chartLabels,
              moment.unix(item.date_start).format("ddd"),
            ]);
            setchartData((chartData) => [...chartData, item.amount]);
          });
      } else if (indexOfPeriod === 1) {
        [...dashboardCumulativeLatestByGroup?.weeks]
          ?.reverse()
          .map((item, index) => {
            setchartLabels((chartLabels) => [
              ...chartLabels,
              moment.unix(item.date_start).format("W"),
            ]);
            setchartData((chartData) => [...chartData, item.amount]);
          });
      } else if (indexOfPeriod === 2) {
        [...dashboardCumulativeLatestByGroup?.months]
          ?.reverse()
          .map((item, index) => {
            setchartLabels((chartLabels) => [
              ...chartLabels,
              moment.unix(item.date_start).format("MMM"),
            ]);
            setchartData((chartData) => [...chartData, item.amount]);
          });
      } else {
        [...dashboardCumulativeLatestByGroup?.years]
          ?.reverse()
          .map((item, index) => {
            setchartLabels((chartLabels) => [
              ...chartLabels,
              moment.unix(item.date_start).format("YYYY"),
            ]);
            setchartData((chartData) => [...chartData, item.amount]);
          });
      }
    }
    fetchData();
  }, [dashboardCumulativeByGroup]);

  // les options du graphique
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      
      title: {
        display: false,
      },
      datalabels: {
        anchor: "start",
        align: "end",
        rotation : -90,
        formatter: (value) => {
          return amountFormatWithCurrencySymbol(value);
        },
        font: {
          size:10
        }
      },
      tooltip: {
        callbacks: {
            label: function(context) {
                let label = context.dataset.label || '';
  
                if (label) {
                    label += ': ';
                }
                if (context.parsed.y !== null) {
                    label += amountFormatWithCurrencySymbol(context.parsed.y);
                }
                return label;
            },
        }
    }
    },
    scales: { y: { display: false } },
   

  };

  //les données du graphique à afficher dans le tooltip lors du passage avec le curseur sur un élément du graphique
  const data = {
    labels: chartLabels,
    datasets: [
      {
        label: "Total",
        data: chartData,
        backgroundColor: "#7DC900",
       
      },
    ],
  };

  // la fonction renderDateSwitch prend en paramètre l'index de la période et retourne la date correspondante à cette période sous le format correspondant
  function renderDateSwitch(indexOfPeriod) {
    switch (indexOfPeriod) {
      case 0:
        // pour la date d'aujourd'hui afficher sous le format "dddd DD MMMM YYYY HH[h]mm" exemple lundi 02 janvier 2022 à 09h51
        return (
          <>
            {moment().format("dddd DD MMMM YYYY")}
            <div> à {moment().format("HH[h]mm")}</div>
          </>
        );
      /*
       *pour case 1 cette semaine, case 2 ce mois et case 3 cette année, la date fin de la période correspond à la date d'aujourd'hui
       */
      case 1:
        // pour cette semaine afficher la période sous le format du "dddd DD" au "dddd DD MMMM YYYY" exemple du lundi 09 au mercredi 11 janvier 2023
        // dans le cas ou date debut de semaine et la date d'aujoud'hui sont dans deux années différentes afficher la période sous le format du "dddd DD MMMM YYYY" au "dddd DD MMMM YYYY" exemple du lundi 26 décembre 2022 au dimanche 01 janvier 2023
        var beginWeek = moment().startOf("week");
        var fromDate = moment
          .unix(dashboardCumulativeByGroup?.weeks[0]?.date_start)
          .format("dddd DD");
        var toDate = moment
          .unix(dashboardCumulativeByGroup?.weeks[0]?.date_end)
          .format("dddd DD MMMM YYYY");
        // modifier le format des dates si debut et fin de semaine ne sont pas dans les mêmes mois et année
        if (
          moment(beginWeek).year() != moment(toDate, "dddd DD MMMM YYYY").year()
        ) {
          fromDate = moment(beginWeek).format("dddd DD MMMM YYYY");
        }
        return (
          <>
            <div>du {fromDate}</div>
            <div>au {toDate}</div>
          </>
        );
      case 2:
        // pour la date ce mois afficher sous le format du "dddd DD" au "dddd DD MMMM YYYY" exemple dimanche 01 au mercredi 11 janvier 2023
        return (
          <>
            <div>
              du{" "}
              {moment
                .unix(dashboardCumulativeByGroup?.months[0]?.date_start)
                .format("dddd DD")}
            </div>
            <div>
              au{" "}
              {moment
                .unix(dashboardCumulativeByGroup?.months[0]?.date_end)
                .format("dddd DD MMMM YYYY")}
            </div>
          </>
        );
      case 3:
        // pour la date cette année afficher sous le format du "dddd DD" au "dddd DD MMMM YYYY" exemple dimanche 01 au mercredi 30 janvier 2023
        return (
          <>
            <div>
              du{" "}
              {moment
                .unix(dashboardCumulativeByGroup?.years[0]?.date_start)
                .format("dddd DD MMMM")}
            </div>
            <div>
              au{" "}
              {moment
                .unix(dashboardCumulativeByGroup?.years[0]?.date_end)
                .format("dddd DD MMMM YYYY")}
            </div>
          </>
        );
      default:
        return 0;
    }
  }

  // la fonction renderDateOneBeforeSwitch prend en paramètre l'index de la période et retourne la date correspondante à la période précédente
  function renderDateOneBeforeSwitch(indexOfPeriod) {
    var previousDate;
    var fromDate;
    var toDate;

    switch (indexOfPeriod) {
      case 1:
        // pour la période semaine précédente afficher date début et date fin de la semaine N-1 sous le format du "DD" au "DD MMMM YYYY" exemple du 02 au 08 janvier 2023
        // dans le cas ou date début et date fin de la semaine sont dans deux années différentes afficher la période sous le format du "DD MMM YYYY" au "DD MMMM YYYY" exemple du 26 décembre 2022 au 01 janvier 2023
        previousDate = moment().subtract(1, "week"); //retourne date aujourd'hui - 7 jours
        var beginWeek = moment(previousDate).startOf("week"); //retourne date debut de la semaine précédente
        fromDate = moment
          .unix(dashboardCumulativeByGroup?.weeks[1]?.date_start)
          .format("DD");
        toDate = moment
          .unix(dashboardCumulativeByGroup?.weeks[1]?.date_end)
          .format("DD MMM YYYY");
        // modifier le format des dates si debut et fin de semaine ne sont pas dans les mêmes mois et année
        // if (moment(beginWeek).year() != moment(toDate, "DD MMMM YYYY").year()) {
        //   fromDate = moment(beginWeek).format("DD MMM YYYY");
        //   toDate = moment(previousDate).format("DD MMM YYYY");
        // }
        return (
          <>
            <div>Evolution</div>
            <div>{t("dates.lastWeekLabel")}</div>
            <div>
              <span>du</span> {fromDate}
              <span> au </span>
              {toDate}
            </div>
          </>
        );

      case 2:
        // pour la date mois précédent afficher sous le format du "DD" au "DD MMMM YYYY" exemple 01 au 31 décembre 2022
        previousDate = moment().subtract(1, "month");
        fromDate = moment
          .unix(dashboardCumulativeByGroup?.months[1]?.date_start)
          .format("DD");
        toDate = moment
          .unix(dashboardCumulativeByGroup?.months[1]?.date_end)
          .format("DD MMM YYYY");
        return (
          <>
            <div>Evolution</div>
            <div>{t("dates.lastMonthLabel")}</div>
            <div>
              <span>du</span> {fromDate}
              <span> au </span>
              {toDate}
            </div>
          </>
        );

      case 3:
        // pour la date année précédente afficher sous le format du "DD MMM" au "DD MMM YYYY" exemple 01 janv. au 31 déc. 2022
        previousDate = moment().subtract(1, "year");
        fromDate = moment
          .unix(dashboardCumulativeByGroup?.years[1]?.date_start)
          .format("DD MMM");
        toDate = moment
          .unix(dashboardCumulativeByGroup?.years[1]?.date_end)
          .format("DD MMM YYYY");
        return (
          <>
            <div>Evolution</div>
            <div>{t("dates.lastYearLabel")}</div>
            <div>
              <span>du</span> {fromDate}
              <span> au </span>
              {toDate}
            </div>
          </>
        );
      default:
        return 0;
    }
  }

  // la fonction renderDatePreviousYearSwitch prend en paramètre l'index de la période et retourne la date correspondante à la même periode dans l'année précédente
  function renderDatePreviousYearSwitch(indexOfPeriod) {
    var previousDate;
    var fromDate;
    var toDate;
    switch (indexOfPeriod) {
      case 1:
        // pour la période même semaine dans l'année précédente afficher date début et date fin de la semaine N-1année sous le format du "DD" au "DD MMMM YYYY" exemple du 10 au 16 janvier 2022
        // dans le cas ou date début et date fin de la semaine sont dans deux années différentes afficher la période sous le format du "DD MMM YYYY" au "DD MMMM YYYY" exemple du 26 décembre 2022 au 01 janvier 2023
        var dayOfWeek = moment().format("e"); //retourne le nombre local d'un jour dans une semaine : dayOfWeek de 0 à 6
        var currentWeek = moment().week(); //retourne le nombre de la semaine dans une année : currentWeek de 1 à 52/53
        previousDate = moment().subtract(1, "year").format("yyyy"); //retourne l'année précédente exp 2022
        var beginWeek = moment()
          .year(previousDate)
          .week(currentWeek)
          .startOf("week");
        fromDate = moment
          .unix(dashboardCumulativeByGroup?.weeks[2]?.date_start)
          .format("DD");
        toDate = moment
          .unix(dashboardCumulativeByGroup?.weeks[2]?.date_end)
          .format("DD MMM YYYY");
        // modifier le format des dates si debut et fin de semaine ne sont pas dans les mêmes mois et année
        // if (moment(beginWeek).year() != moment(toDate, "DD MMM YYYY").year()) {
        //   fromDate = moment(beginWeek).format("DD MMM YYYY");
        //   toDate = moment(previousDate).format("DD MMM YYYY");
        // }
        return (
          <>
            <div>Evolution</div>
            <div>{t("dates.weekOfLastYear")} du</div>
            <div>
              {fromDate}
              <span> au </span>
              {toDate}
            </div>
          </>
        );

      case 2:
        // pour la date même mois dans l'année précédente afficher mois N-1année sous le format du "DD" au "DD MMMM YYYY" exemple 01 au 31 décembre 2022
        previousDate = moment().subtract(1, "year");
        fromDate = moment
          .unix(dashboardCumulativeByGroup?.months[2]?.date_start)
          .format("DD");
        toDate = moment
          .unix(dashboardCumulativeByGroup?.months[2]?.date_end)
          .format("DD MMMM YYYY");

        return (
          <>
            <div>Evolution</div>
            <div>{t("dates.monthOfLastYear")}</div>
            <div>
              <span>du</span> {fromDate}
              <span> au </span>
              {toDate}
            </div>
          </>
        );

      case 3:
        // pour la date année N-2 afficher sous le format du "DD MMM" au "DD MMM YYYY" exemple 01 janv. au 31 déc. 2021
        previousDate = moment().subtract(2, "year");
        fromDate = moment
          .unix(dashboardCumulativeByGroup?.years[2]?.date_start)
          .format("DD MMM");
        toDate = moment
          .unix(dashboardCumulativeByGroup?.years[2]?.date_end)
          .format("DD MMM YYYY");

        return (
          <>
            <div>Evolution</div>
            <div>{t("dates.yearLabel")} N-2</div>
            <div>
              <span>du</span> {fromDate}
              <span> au </span>
              {toDate}
            </div>
          </>
        );

      default:
        return 0;
    }
  }

  // la fonction renderAmountSwitch prend en paramètre l'index de la période et retourne le CA correspondant à cette période
  function renderAmountSwitch(indexOfPeriod) {
    switch (indexOfPeriod) {
      case 0:
        return dashboardCumulativeByGroup?.day?.daily_total;
      case 1:
        return dashboardCumulativeByGroup?.weeks[0]?.amount;
      case 2:
        return dashboardCumulativeByGroup?.months[0]?.amount;
      case 3:
        return dashboardCumulativeByGroup?.years[0]?.amount;
      default:
        return 0;
    }
  }

  // la fonction renderAmountOneBeforeSwitch prend en paramètre l'index de la période et retourne le CA correspondant à la période-1
  function renderAmountOneBeforeSwitch(indexOfPeriod) {
    switch (indexOfPeriod) {
      case 1:
        return dashboardCumulativeByGroup?.weeks[1]?.amount;
      case 2:
        return dashboardCumulativeByGroup?.months[1]?.amount;
      case 3:
        return dashboardCumulativeByGroup?.years[1]?.amount;
      default:
        return 0;
    }
  }

  // la fonction renderAmountPreviousYearSwitch prend en paramètre l'index de la période et retourne le CA correspondant à la même periode dans l'année précédente
  function renderAmountPreviousYearSwitch(indexOfPeriod) {
    switch (indexOfPeriod) {
      case 1:
        return dashboardCumulativeByGroup?.weeks[2]?.amount;
      case 2:
        return dashboardCumulativeByGroup?.months[2]?.amount;
      case 3:
        return dashboardCumulativeByGroup?.years[2]?.amount;
      default:
        return 0;
    }
  }
  //TODO cette fonction est effectué en backend growoth dans le retour de l'api
  // la fonction calculateCAPercent prend en paramètre la CA année N et CA année N-1 pour calculer l'évolution du chiffre d'affaires et retourne un composant html contenant le pourcentage
  function calculateCAPercent(actualCA, lastCA) {
    //tester si CA actuel = 0 et CA année N-1 = 0 alors l'évolution = 0%
    if (lastCA == 0 && actualCA == 0) var percent = 0;
    //tester si CA actuel > 0 et CA année N-1 est egal à 0 alors l'évolution = 100%
    else if (lastCA == 0 && (actualCA > 0)) var percent = 100;
    //sinon calculer l'évolution selon la formule (( CA année N - CA année N-1) * 100 ) / CA année N-1 )
    else var percent = ((actualCA - lastCA) * 100) / lastCA;
    // retourner l'élément à afficher selon le signe de pourcentage
    if (percent < 0) {
      return (
        <>
          <div className="activityEvolutionDecrease">
            {amountFormat(percent)} %
          </div>
          <img         
            src={DecreaseIcon}
            alt="arrow-down"
          ></img>
        </>
      );
    } else if (percent == 0) {
      return (
        <>
          <div className="activityEvolutionStable align-items-center">
            {amountFormat(percent)} %
          </div>
          <img src={EqualIcon} alt="="/>
        </>
      );
    } else
      return (
        <>
          <div className="activityEvolutionIncrease">
            +{amountFormat(percent)} %
          </div>
          <img           
            src={IncreaseIcon}
            alt="arrow-up"
          ></img>
        </>
      );
  }

  //titre de la carte
  var cardTitle = (
    <>
      <img
        src={process.env.PUBLIC_URL + "/img/design/IconCalendar.svg"}
        alt="calendar-icon"
      />
      <span>{title}</span>
    </>
  );

  return (
    <Card title={cardTitle}>
      <>
        <div>
          <div className="cn_app_cardDate activityCardDate">
            {renderDateSwitch(indexOfPeriod)}
            {/* {moment().calendar()} */}
          </div>
          <div className="cn_app_cardAmount cn_app_cardAmountLg activityCardAmount">
            {amountFormatWithCurrencySymbol(renderAmountSwitch(indexOfPeriod))}
          </div>
        </div>
        <div className="cn_app_cardSeparator">
          <hr />
        </div>
        {/*s'il s'agit de la carte aujourd'hui j'affiche la liste des modes de payement avec les montants correspondants*/}
        {indexOfPeriod === 0 ? (
          <div className="activityPaymentModesContainer">
            <div className="activityPaymentModesLabel">
              {t("activity.paymentModeLabel")}
            </div>
            <div className="activityPaymentModesTable">
              <table>
                <tbody>
                  {dashboardCumulativeByGroup?.day?.payment?.map(
                    (payment, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <img src={payment?.type_image} />
                          </td>
                          <td className="activityPaymentLabel">
                            {t(eCdbsPaymentType[payment.type])}
                          </td>
                          <td className="activityPaymentAmount">
                            {amountFormatWithCurrencySymbol(payment?.amount)}
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div>
            {/*s'il s'agit d'une carte autre que aujourd'hui j'affiche les évolutions précédentes et les évolutions de l'année précédente*/}
            <div className="activityEvolutionContainer">
              <div className="cn_app_cardDate activityEvolutionDate">
                {renderDateOneBeforeSwitch(indexOfPeriod)}
              </div>
              <div className="activityEvolutionNumbers">
                <div className="cn_app_cardAmount cn_app_cardAmountSm">
                  {amountFormatWithCurrencySymbol(
                    renderAmountOneBeforeSwitch(indexOfPeriod)
                  )}
                </div>
                <div className="activityEvolutionPercent">
                  {
                    //appel de la fonction calculateCAPercent en lui fournissant le CA actuel et le CA N-1
                    calculateCAPercent(
                      renderAmountSwitch(indexOfPeriod),
                      renderAmountOneBeforeSwitch(indexOfPeriod)
                    )
                  }
                </div>
              </div>
            </div>
            <div className="cn_app_cardSeparator">
              <hr />
            </div>
            <div className="activityEvolutionContainer">
              <div className="cn_app_cardDate activityEvolutionDate">
                {renderDatePreviousYearSwitch(indexOfPeriod)}
              </div>
              <div className="activityEvolutionNumbers">
                <div className="cn_app_cardAmount cn_app_cardAmountSm">
                  {amountFormatWithCurrencySymbol(
                    renderAmountPreviousYearSwitch(indexOfPeriod)
                  )}
                </div>
                <div className="activityEvolutionPercent">
                  {
                    //appel de la fonction calculateCAPercent en lui fournissant le CA actuel et le CA N-1année
                    calculateCAPercent(
                      renderAmountSwitch(indexOfPeriod),
                      renderAmountPreviousYearSwitch(indexOfPeriod)
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="cn_app_cardSeparator">
          <hr />
        </div>
        {/* afficher les graphiques à bars */}
        <div className="activityCardChart">
          <Bar data={data} options={options}  />
        </div>
      </>
    </Card>
  );
}
