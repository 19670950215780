
import bankingSlice from "ck_commun/src/app/modBanking/slices/bankingSlice";
import cumulativeSlice from "ck_commun/src/app/modCumulative/slices/cumulativeSlice";
import entitySlice from "ck_commun/src/app/modEntity/slices/entitySlice";
import fixingSlice from "ck_commun/src/app/modFixing/slices/fixingSlice";
import machineSlice from "ck_commun/src/app/modImachine/slices/machineSlice";
import weatherSlice from "ck_commun/src/app/modWeather/slices/weatherSlice";
import sharedEquipmentSlice from "ck_commun/src/app/modSharedEquipment/slices/sharedEquipmentSlice";
import walletLocalSlice from "ck_commun/src/app/modWalletLocal/slices/walletLocalSlice";
import statementSlice from "ck_commun/src/app/modStatement/slices/statementSlice";

const rootReducer = {
  
  banking: bankingSlice,
  cumulative: cumulativeSlice,
  entity: entitySlice,
  fixing: fixingSlice,
  machine: machineSlice,
  sharedEquipment: sharedEquipmentSlice,
  statement: statementSlice,
  walletLocal: walletLocalSlice,
  weather: weatherSlice,
  };
  
  export default rootReducer;