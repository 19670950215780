import React from "react";
import PropTypes from "prop-types";
import "./Card.scss";

const Card = ({ title, width, height, style = {}, className, ...props }) => { //ajouter dans scss test dépassement height

  return (  

    <div className={`c-card ${className}`} style={{width:width, height:height, ...style.card}}>
      {title &&(
        <div className="c-card-header flex-display justify-content-center align-items-center" style={{...style.cardHeader}}                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         >
          <span className="c-card-title" style={{...style.cardTitle }}>{title}</span>
        </div>
      )}
      <div className="c-card-body flex-display flex-column justify-content-center" style={{...style.cardBody }}>{props.children}</div>
    </div>
  );
};

Card.defaultProps = {
  width: 'max-width', 
  height: 'max-height'
}

Card.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default Card;