import React, { useState, useEffect } from "react";
import ActivityCAPage from "ck_commun/src/app/modCumulative/pages/activityCAPage/ActivityCAPage";

export default function ActivityCA() {
  return (
    <>
      <ActivityCAPage />
    </>
  );
}
