import React from 'react'
import PropTypes from 'prop-types'
import './Layout.scss'

export default function Sider({
  width,
  position,
  collapsed,
  collapsible,
  collapseWidth,
  setCollapsed,
 // onCollapse,
 // style = {},
  ...props
}) {
const sidebarWidth = collapsed ? collapseWidth :width;

  return (
    <div className='flex-display flex-row'>
      {position === 'right' && collapsible &&(
        <div
          className={`trigger trigger-right-position
           ${!collapsed
              ? 'trigger-reverse'
              : ''
          }`}
          onClick={() => setCollapsed(!collapsed)}
        >
          <img
            src={
              process.env.PUBLIC_URL + '/img/baseline-arrow_downward-24px.svg'
            }
          />
        </div>
      )}

      <div
        id={'layout-sider-' + position}
        className={`sider ${collapsed? 'sider-collapsed' : ''}`}
        style={{ width: sidebarWidth }}
      >
        {props.children}
      </div>

      {position === 'left' && collapsible &&(
        <div
          className={`trigger trigger-left-position
            ${!collapsed
              ? ''
              : ' trigger-reverse'
            }`}
          onClick={() => setCollapsed(!collapsed)}
        >
          <img
            src={
              process.env.PUBLIC_URL + '/img/baseline-arrow_downward-24px.svg'
            }
          />
        </div>
      )}
    </div>
  )
}

Sider.defaultProps = {
  width: 225,
  collapseWidth: 80,
  collapsed: false,
  collapsible: true,
}

Sider.propTypes = {
  collapsed: PropTypes.bool, //To set the current status of the sider (wether is actually collapsed or not)
  collapsible: PropTypes.bool, //To define if the sider is collapsible or not (show trigger or not)
  collapseWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // Width of the collapsed sidebar
  position: PropTypes.oneOf(['left', 'right']).isRequired, // To define wether it's a left sider or a right sider
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // Width of the sidebar
}
