/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/payment/e_monetique_type.py
Aucune modification manuelle acceptée
*/

// Enumération des types de monétique disponibles
// La valeur est en binaire car on peut cumuler les types
const eMonetiqueType = Object.freeze({
  // Ok
  0x01: 'eMonetiqueType.changer',

  // Occupé
  0x02: 'eMonetiqueType.coinValidator',

  // Pas terminé
  0x04: 'eMonetiqueType.billValidator',

  // Terminé
  0x08: 'eMonetiqueType.preCashRegister',

  // Tous
  0xff: 'eMonetiqueType.all',
});

export default eMonetiqueType;
