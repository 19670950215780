import React from 'react';
import { Button } from "../../componentsLibrary/componentsLibrary";
import { useTranslation } from 'react-i18next';
import ExportButtonSvg from "../../../assets/images/exportButtons/exportButton.svg";

export default function ExportButton({onClick}) {
    const { t } = useTranslation();

    return (
        <Button type="image">
            <img
                src={ExportButtonSvg}
                onClick={onClick}
            />

            <span>{t('common.transButtonExport')}</span>
        </Button>
    );
};