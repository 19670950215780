/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/system/e_support_type.py
Aucune modification manuelle acceptée
*/

// type d'identifiant possible permettant de définir qui a réalisé une action (paiement, démarrage)
// 
// Anciennement TTYPE_ID
const eSupportType = Object.freeze({
  // Correspond à une fin d'identification (lorsqu'elle est nécessaire)
  0: 'eSupportType.stop',

  // Correspond à l'identifiant local user id du badge et local manager id.
  // Ce numéro est programmé lors de la création du badge.
  // Il n'y a pas de garantie d'unicité.
  1: 'eSupportType.badgeUserId',

  // Ce numéro correspond à l'UID du support qui est normalement unique au monde
  // Souvent appelé NSE
  2: 'eSupportType.rfidHardwareUid',

  // Correspond à l'identifiant d'une fiche client
  // (entity de type société, ou utilisateur) dans mod_entity du serveur
  3: 'eSupportType.serverEntityId',

  // Correspond à la combinaison d'un identifiant de société (mod_manager:company) avec un
  // identifiant d'utilisateur (de mod_authentication:user)
  4: 'eSupportType.serverCompanyUserId',

  // Correspond à une action par le serveur web de la borne
  5: 'eSupportType.web',

  // DEPRECATED
  // Des bornes plus ancienne utilise ce numéro pour le web. Pour la compatibilité
  // nous sommes obligé de le garder actuellement
  6: 'eSupportType.webOld',
});

export default eSupportType;
