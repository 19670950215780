import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "ck_commun/src/app/modUtils/components/languageSwitcher/LanguageSwitcher";
import LoginForm from "ck_commun/src/app/modAuthentication/components/LoginForm";
import PasswordResetForm from "ck_commun/src/app/modAuthentication/components/PasswordResetForm";
import "./Login.scss";

export default function Login() {
  const { t } = useTranslation();
  const [showLoginForm, setShowLoginForm] = useState(true);

  const handleForgetPasswordClick = () => {
    setShowLoginForm(false);
  };

  const handleBackToLoginClick = () => {
    setShowLoginForm(true);
  };
  return (
    <div className="loginContainer">
      <div className="loginMain">
        <div className="loginPresentationContainer">
          <div className="flex justify-center">
            <img
              className="my-3 h-2/3"
              src={process.env.PUBLIC_URL + "/laundryLogo.png"}
              alt="Logo laundry"
            />
          </div>
          <div className="loginPresentation">
            {t("login.loginPresentation")}
          </div>
          <div>
            <img
              className="loginHelpIcon"
              src={process.env.PUBLIC_URL + "/img/design/help.png"}
              alt="help"
            />
          </div>
          <div className="loginHelp">
            <div>{t("login.loginHelp")}</div>
            <div>{t("login.loginCallToHelp")}</div>
          </div>
          <div className="loginPhoneNumber">0 473 289 246</div>
        </div>
        <div className="loginFormContainer">
          <div className="loginFormLanguageSelector">
            <LanguageSwitcher />
          </div>
          <div className="loginForm">
            <div className="loginTitle">{t("login.loginTitle")}</div>
            <div className="keyIcon">
              <img
                src={process.env.PUBLIC_URL + "/img/design/cle.png"}
                alt="clé"
              />
            </div>
            {showLoginForm ? (
              <LoginForm />
            ) : (
              <PasswordResetForm onBackToLoginClick={handleBackToLoginClick} />
            )}
            {showLoginForm && (
              <div
                className="loginResetPassword"
                onClick={handleForgetPasswordClick}
              >
                {t("login.loginForgetPassword")}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
