import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Input,
  Spinner,
  Badge,
} from "../../modUtils/components/componentsLibrary/componentsLibrary";
import { get_support_detail_by_group } from "../slices/walletLocalSlice";
import { dateFormat } from "../../modUtils/functions/dateFormat";
import { amountFormatWithCurrencySymbol } from "../../modUtils/functions/numberFormat";
import { message } from "antd";
import AlertMessage from "../../modUtils/components/AlertMessage";

export default function SupportDetailsModal({
  modalVisible,
  setModalVisible,
  supportId,
}) {
  const { supportDetails } = useSelector((state) => state.walletLocal);
  const { groupId, loadingGroup } = useSelector((state) => state.manager);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getSupportDetails = async () => {
    setLoading(true);
    try {
      await dispatch(
        get_support_detail_by_group({ groupId, supportId })
      ).unwrap();
      setLoading(false);
    } catch (error) {
      message.error({
        content: (
          <AlertMessage
            status={error?.status}
            alertMessage={
              "Une erreur est survenue lors de la récupération des détails de ce badge!"
            }
            errorDetail={error?.data?.detail}
          />
        ),
      });
      setLoading(false);
      setModalVisible(false);
    }
  };

  useEffect(() => {
    if (supportId && groupId) {
      getSupportDetails();
    }
  }, [groupId, supportId]);

  var modalStyle = {
    modalContent: {
      minHeight: "60%",
      minWidth: "400px",
      width: "40%",
    },
  };
  return (
    <Modal
      // title={t("badge.badgeCreditModalTitle")}
      title="Détails du badge"
      isOpen={modalVisible}
      onClose={() => setModalVisible(false)}
      style={modalStyle}
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          {supportDetails?.suspected_fraud_count > 0 && (
            <div className="flex-display justify-content-end">
              <Badge
                color="#000"
                overflowCount={99}
                count={supportDetails?.suspected_fraud_count}
                offset="8px"
              >
                <img
                  width={40}
                  src={
                    process.env.PUBLIC_URL + "/img/badgesIcons/fraudeIcone.svg"
                  }
                  alt={supportDetails?.suspected_fraud_count + "fraudes"}
                />
              </Badge>
            </div>
          )}
          <Input
            label="Badge N°"
            value={supportDetails?.badge_number}
            disabled
          />

          <Input
            label="Type"
            value={supportDetails?.amount_type_label}
            disabled
          />

          <Input label="Statut" value={supportDetails?.status_label} disabled />

          <Input
            label="Solde"
            value={
              supportDetails?.amount &&
              amountFormatWithCurrencySymbol(supportDetails?.amount)
            }
            disabled
          />
          <Input
            label="Dernière visite"
            value={dateFormat(supportDetails?.last_visit_date)}
            disabled
          />
          {supportDetails?.entity && (
            <Input
              label="Entité"
              value={supportDetails?.entity?.name}
              disabled
            />
          )}
        </>
      )}
    </Modal>
  );
}
