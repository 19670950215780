import React from 'react';
import { useTranslation } from "react-i18next";
import { eCdbsPaymentType } from "ck_commun/src/commun_global/enums";
import { dateFormat } from "../../../modUtils/functions/dateFormat";
import NoData from '../../../modUtils/components/noData/NoData';
import { amountFormatWithCurrencySymbol } from "../../../modUtils/functions/numberFormat";
import { ReactComponent as AwaitStatus } from "ck_commun/src/app/modStatement/assets/images/await.svg";
import { ReactComponent as OkStatus } from "ck_commun/src/app/modStatement/assets/images/checked.svg";
import { ReactComponent as KOStatus } from "ck_commun/src/app/modStatement/assets/images/x-red.svg";
import { ReactComponent as NotReadyStatus } from "ck_commun/src/app/modStatement/assets/images/circle-gray.svg";
import MapTableCell from "../mapTableCell/MapTableCell";

export default function PickupsTable({ statements }) {
    const { t } = useTranslation();

    //retourne l'icone à afficher dans la ligne relevé par type de paiement
    const renderStatementDifferenceIcon = (
        amountCashbox,
        amountCashboxCounted
    ) => {
        if (amountCashboxCounted == null) return <NotReadyStatus className="w-4"/>;
        else {
            if (amountCashboxCounted !== amountCashbox) return <KOStatus className="w-4"/>;
            else if (amountCashboxCounted === amountCashbox) return <OkStatus className="w-4"/>;
        }
    };

    //retourne l'icone à afficher dans la ligne relevé par équipement
    const renderEquipmentStatementStatusIcon = (status) => {
        switch (status) {
        case 0:
            return <AwaitStatus className="w-4"/>;
        case 1:
            return <KOStatus className="w-4"/>;
        case 2:
            return <OkStatus className="w-4"/>;
        }
    };

    //calcul différence entre montant relevé attendu et montant comptabilisé
    const getStatementDifference = (amountCashbox, amountCashboxCounted) => {
        if (amountCashboxCounted == null) return <span>-</span>;
        else {
            let differenceAmount = amountCashboxCounted - amountCashbox;
            if (differenceAmount == 0) return differenceAmount;
            else return amountFormatWithCurrencySymbol(differenceAmount);
        }
    };

    return (
        <>
        <table className="table table-stripped">
            <thead>
                <tr>
                    <th>{t("table.tabColDate")}</th>
                    <th>{t("table.tabColCashbox")}</th>
                    <th>{t("table.tabColEquipment")}</th>
                    <th>{t("table.tabColStatus")}</th>
                    <th>{t("table.tabColPickuper")}</th>
                    <th>{t("table.tabColPaymentType")}</th>
                    <th>{t("table.tabColPickupAmount")}</th>
                    <th>{t("table.tabColPickupCount")}</th>
                    <th>{t("table.tabColPickupDifference")}</th>
                    <th>{t("table.tabColState")}</th>
                </tr>
            </thead>
            <tbody>
                {statements.map((statement) => (
                    <tr key={statement?.id}>
                        <td>{dateFormat(statement?.date)}</td>
                        <td>{statement?.cash_box?.name}</td>
                        <td className="!p-0">
                            {statement?.equipment_statements?.map((eq_statement, index) => (
                                <MapTableCell
                                    index={index}
                                    table={statement?.equipment_statements}
                                >
                                    {eq_statement?.equipment?.name}
                                </MapTableCell>
                            ))}
                        </td>
                        <td className="!p-0">
                            {statement?.equipment_statements?.map((eq_statement, index) => (
                                <MapTableCell
                                    index={index}
                                    table={statement?.equipment_statements}
                                >
                                    {renderEquipmentStatementStatusIcon(
                                        eq_statement?.status
                                    )}
                                </MapTableCell>
                            ))}
                        </td>
                        <td>{statement?.identification?.label?.replace("Utilisateur: ", "")}</td>
                        <td className="!p-0">
                            {statement?.details?.map((detail, index) => (
                                <MapTableCell
                                    index={index}
                                    table={statement?.details}
                                >
                                    {t(eCdbsPaymentType[detail?.payment_type])} 
                                </MapTableCell>
                            ))}
                        </td>
                        <td>
                            {statement?.details?.map((detail, index) => (
                                <MapTableCell
                                    index={index}
                                    table={statement?.details}
                                >
                                    {detail?.amount_cashbox ? amountFormatWithCurrencySymbol(detail?.amount_cashbox) : "-"}
                                </MapTableCell>
                            ))}
                        </td>
                        <td>
                            {statement?.details?.map((detail, index) => (
                                <MapTableCell
                                    index={index}
                                    table={statement?.details}
                                >
                                    {detail?.amount_cashbox_counted == null
                                        ? "-"
                                        : amountFormatWithCurrencySymbol(
                                            detail?.amount_cashbox_counted
                                    )}
                                </MapTableCell>
                            ))}
                        </td>
                        <td>
                            {statement?.details?.map((detail, index) => (
                                <MapTableCell
                                    index={index}
                                    table={statement?.details}
                                >
                                    { detail?.amount_cashbox && detail?.amount_cashbox_counted ? getStatementDifference(
                                        detail?.amount_cashbox,
                                        detail?.amount_cashbox_counted
                                    ): "-"}
                                </MapTableCell>
                            ))}
                        </td>
                        <td>
                            {statement?.details?.map((detail, index) => (
                                <MapTableCell
                                    index={index}
                                    table={statement?.details}
                                >
                                    {renderStatementDifferenceIcon(
                                        detail?.amount_cashbox,
                                        detail?.amount_cashbox_counted
                                    )}
                                </MapTableCell>
                            ))}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        {statements.length === 0 &&
            <NoData 
                text={t("pickupsTable.noResults")}
            />
        }
        </>
    );
};