import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpGet } from "ck_commun/src/app/modUtils/apis/httpConfig";

//get_city_paginated : Permet récupérer la liste des villes
export const get_city_paginated = createAsyncThunk(
  "mod_location/get_city_paginated",
  async (values, { rejectWithValue }) => {
    try {
      const response = await httpGet(`mod_location/cities/`, {
        params: {
          limit: values.limit,
          offset: values.offset,
          search: values.search,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

const locationSlice = createSlice({
  name: "location",
  initialState: {
    cities: [],
    loading: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(get_city_paginated.fulfilled, (state, action) => {
        state.loading = false;
        state.cities = action.payload.results;
      })
      .addCase(get_city_paginated.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default locationSlice.reducer;
