/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/machines/e_laundry_service_type.py
Aucune modification manuelle acceptée
*/

// Identifiant indiquant le type de service produit par le matériel dans une laverie
const eLaundryServiceType = Object.freeze({
  // Ne pas utiliser sur les équipements. Utile pour le serveur qui peux recevoir ceci.
  0: 'eLaundryServiceType.undefined',

  // Machine
  1: 'eLaundryServiceType.machine',

  // Séchoir
  2: 'eLaundryServiceType.dryer',

  // Lessive
  3: 'eLaundryServiceType.washingPowder',

  // Adoucissant
  4: 'eLaundryServiceType.softener',
});

export default eLaundryServiceType;
