import React from "react";

export default function PermissionFadeContainer({permission, opacity=30, className="", ...props}) {

    const opacityVariant = {
        30: "opacity-30",
        50: "opacity-50"
    };

    return (
        <>
        {permission ? 
            props.children
        :
            <div className={`${opacityVariant[opacity]} ${className}`}>
                {props.children}
            </div>
        }
        </>
    );
};