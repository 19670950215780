import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Widget from "ck_commun/src/app/modUtils/components/widget/Widget";
import "moment/locale/fr";
import { amountFormatWithCurrencySymbol } from "ck_commun/src/app/modUtils/functions/numberFormat";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { hasPermission } from "ck_commun/src/app/modUtils/functions/handlePermissions";
import PermissionFadeContainer from "ck_commun/src/app/modUtils/components/permissionFadeContainer/PermissionFadeContainer";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

function WidgetCADaily({ loading }) {
    const [chartLabels, setchartLabels] = useState([]);
    const [chartData, setchartData] = useState([]);
    const { dashboardCumulativeLatestByGroup } = useSelector(
      (state) => state.cumulative
    );
    const { selectedGroup } = useSelector((state) => state.manager);
    const groupPermissions = selectedGroup?.plan?.permissions;
    const verifyGroupPermission = hasPermission('mod_cumulative:show_statistic', groupPermissions);

    useEffect(() => {
      if (dashboardCumulativeLatestByGroup?.days) {
        const lastDays = dashboardCumulativeLatestByGroup?.days;
  
        const newChartLabels = [];
        const newChartData = [];
  
        [...lastDays].reverse().forEach((item) => {
          newChartLabels.push(moment.unix(item.date_start).format("ddd"));
          newChartData.push(item.amount);
        });
  
        setchartLabels(newChartLabels);
        setchartData(newChartData);
      }
    }, [dashboardCumulativeLatestByGroup]);
  
    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        datalabels: {
          anchor: "start",
          align: "end",
          rotation: -90 ,
          formatter: (value) => {
            return amountFormatWithCurrencySymbol(value);
          },
        },
        tooltip: {
          callbacks: {
              label: function(context) {
                  let label = context.dataset.label || '';
    
                  if (label) {
                      label += ': ';
                  }
                  if (context.parsed.y !== null) {
                      label += amountFormatWithCurrencySymbol(context.parsed.y);
                  }
                  return label;
              },
          }
      }
      },
      scales: {
        y: {
          display: false
          // min: 0,
          // max: Math.max(...chartData),
          // ticks: {
          //   maxTicksLimit: 2,
          // },
        },
      },
    };
    const data = {
      labels: chartLabels,
      datasets: [
        {
          label: "Total",
          data: chartData,
          backgroundColor: "#7DC900",
        },
      ],
    };
  
    return (
        !loading && (
            <PermissionFadeContainer
                permission={verifyGroupPermission}
            >
                <Widget
                    title="chiffres d'affaires journalier"
                    content={<Bar data={data} options={options}/>}
                />
            </PermissionFadeContainer>
        )
    );
};

export default WidgetCADaily;