/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/system/e_cmd_state.py
Aucune modification manuelle acceptée
*/

// Valeurs des états de commandes
const eCmdState = Object.freeze({
  // Basse
  0: 'eCmdState.cmdStateLow',

  // Haute
  1: 'eCmdState.cmdStateHigh',
});

export default eCmdState;
