/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/device/e_device_code_printer.py
Aucune modification manuelle acceptée
*/

// Codes erreurs spécifiques à la catégorie de périphériques imprimantes
// 
// Anciennement EFEATURECID_PRINTER_ERRORCODE
const eDeviceCodePrinter = Object.freeze({
  // Imprimante Tête levée
  102: 'eDeviceCodePrinter.headerUp',

  // Imprimante sans papier
  103: 'eDeviceCodePrinter.noPaper',

  // Erreur de coupe de l'imprimante
  107: 'eDeviceCodePrinter.cutterError',
});

export default eDeviceCodePrinter;
