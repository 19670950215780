import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpGet, httpPost } from "../../modUtils/apis/httpConfig";

// Récupère la liste des équipements partagés où l'on a accès.
export const shared_equipments_paginated_get = createAsyncThunk(
    "mod_equipment_shared/shared_equipments",
    async (values) => {
        const response = await httpGet(
            `/mod_equipment_shared/shared_equipments/`,
            {
                params: {
                    limit: values.limit, 
                    offset: values.offset,
                    serial_number: values.serial_number,
                    groups: values.groups,
                    sites: values.sites,
                }
            }
        );
        return response.data;
    }
);

// Demande d'obtention d'un partage.
export const sharing_request_create = createAsyncThunk(
    "mod_equipment_shared/sharing_request_create",
    async (values) => {
        try {
            const response = await httpPost(
                `mod_equipment_shared/groups/${values.groupId}/sharing_requests/`,
                {
                    message: values.message,
                    equipment_to_shared: values.equipment_to_shared
                }
            );
            if (response.status === 200) {
                const response = {status: "success", message: "Operation réalisée avec succès"}
                return response;
            }
        } catch (error) {
            switch (error?.response?.status ) {
                case 400:
                    if (typeof error?.response?.data?.detail === "object") {
                        if (error?.response?.data?.detail[0] === "La demande partagée existe déjà") {
                            const response = {status: "error", message: error?.response?.data?.detail[0]};
                            return response;
                        }else if (error?.response?.data?.detail?.equipment_to_shared[0] === "Équipement non trouvé") {
                            const response = {status: "error", message: error?.response?.data?.detail?.equipment_to_shared[0]};
                            return response;
                        }
                    }else if (typeof error?.response?.data?.detail === "string" &&
                        error?.response?.data?.detail === "Cet équipement n'a pas d'utilisateur pouvant accepter votre demande") {
                        const response = {status: "error", message: error?.response?.data?.detail};
                        return response;
                    }
                default:
                    const response = {status: "error", message: "Un problème est survenu"};
                    return response;
            }
        }
    }
);

// Récupère la liste des groupes partagés où l'on a accès.
export const shared_groups_paginated_get = createAsyncThunk(
    "mod_equipment_shared/shared_groups_paginated_get",
    async (values) => {
        const response = await httpGet(
            `/mod_equipment_shared/shared_groups/`,
            {
                params: {
                    limit: 100,
                    offset: 0,
                    name: values.name
                }
            }
        );
        if (response.data.results.length === 0) {
            return [{id: null, name: 'no data'}];
        }
        return response.data.results;
    }
);

// Récupère la liste des sites partagés où l'on a accès
export const shared_sites_paginated_get = createAsyncThunk(
    "mod_equipment_shared/shared_sites_paginated_get",
    async (values) => {
        const response = await httpGet(
            `/mod_equipment_shared/shared_sites/`,
            {
                params: {
                    limit: 100,
                    offset: 0,
                    name: values.name,
                    groups: values.groups
                },
            }
        );
        if (response.data.results.length === 0) {
            return [{id: null, name: 'no data'}];
        }
        return response.data.results;
    }
);

const sharedEquipmentSlice = createSlice({
    name: "sharedEquipment",
    initialState: {
        sharedEquipment: [],
        sharedEquipmentCount: 0,
        messageAlert: [],
        sharedGroups: [],
        sharedSites: [],
        loading: true
    },
    extraReducers: (builder) => {
        builder
            // Récupère la liste des équipements partagés où l'on a accès.
            .addCase(shared_equipments_paginated_get.pending, (state, action) => {
                state.sharedEquipment = [];
                state.sharedEquipmentCount = 0;
            })
            .addCase(shared_equipments_paginated_get.fulfilled, (state, action) => {
                state.sharedEquipment = action.payload.results;
                state.sharedEquipmentCount = action.payload.count;
                state.loading = false;
            })
            .addCase(shared_equipments_paginated_get.rejected, (state, action) => {
                state.loading = false;
            })

            // Récupère la liste des groupes partagés où l'on a accès.
            .addCase(shared_groups_paginated_get.pending, (state, action) => {
                state.sharedGroups = [];
            })
            .addCase(shared_groups_paginated_get.fulfilled, (state, action) => {
                state.sharedGroups = action.payload;
                state.loading = false;
            })
            .addCase(shared_groups_paginated_get.rejected, (state, action) => {
                state.loading = false;
            })

            // Récupère la liste des sites partagés où l'on a accès
            .addCase(shared_sites_paginated_get.pending, (state, action) => {
                state.sharedSites = [];
            })
            .addCase(shared_sites_paginated_get.fulfilled, (state, action) => {
                state.sharedSites = action.payload;
                state.loading = false;
            })
            .addCase(shared_sites_paginated_get.rejected, (state, action) => {
                state.loading = false;
            })

            // Demande d'obtention d'un partage.
            .addCase(sharing_request_create.pending, (state, action) => {
                state.messageAlert = [];
            })
            .addCase(sharing_request_create.fulfilled, (state, action) => {
                state.messageAlert = action.payload;
                state.loading = false;
            })
            .addCase(sharing_request_create.rejected, (state, action) => {
                state.loading = false;
            })
    },
});

export default sharedEquipmentSlice.reducer;