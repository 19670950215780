import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "../../../modUtils/components/componentsLibrary/componentsLibrary";
import { dateFormat } from "../../../modUtils/functions/dateFormat";
import { get_sales_equipment_by_group_paginated } from "../../slices/saleSlice";
import { eCdbsPaymentType } from "../../../../commun_global/enums";
import EquipmentSaleDetails from "../equipmentSaleDetails/EquipmentSaleDetails";
import { amountFormatWithCurrencySymbol } from "../../../modUtils/functions/numberFormat";
import { hasPermission } from "ck_commun/src/app/modUtils/functions/handlePermissions";
import PermissionDeniedPage from "ck_commun/src/app/modUtils/components/errorPage/PermissionDeniedPage";
import PermissionFadeContainer from "../../../modUtils/components/permissionFadeContainer/PermissionFadeContainer";

export default function EquipmentSales({ equipment }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { groupId, loadingGroup, selectedGroup } = useSelector((state) => state.manager);
  const { equipmentSales } = useSelector((state) => state.sale);
  const [loadingMore, setLoadingMore] = useState(false);
  const [limit, setLimit] = useState(50);
  const [offset, setOffset] = useState(0);
  const [nextUrl, setNextUrl] = useState();
  const tableRef = useRef(null);
  const [equipmentSalesLoader, setEquipmentSalesLoader] = useState(equipmentSales);
  const permissions = selectedGroup?.permissions;
  const verifyPermission = hasPermission('mod_sale:show_sale', permissions);
  const groupPermissions = selectedGroup?.plan?.permissions;
  const verifyGroupPermission = hasPermission('mod_sale:show_sale', groupPermissions);

  const style = {
    tr: {
      position: "relative",
    },
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await dispatch(
        get_sales_equipment_by_group_paginated({
          groupId: groupId,
          equipmentId: equipment.id,
          ordering: "-date",
          limit,
          offset,
        })
      ).then((result) => {
        setNextUrl(result.payload.next);
        setLoading(false);
        setLoadingMore(false);
        setEquipmentSalesLoader((prevSales) => [
          ...prevSales,
          ...result.payload.results,
        ]);
      });
      setLoading(false);
    }
    if (verifyPermission && groupId && equipment.id) {
      fetchData();
    }
  }, [groupId, equipment.id, limit, offset]);

  const loadMoreData = () => {
    if (nextUrl && !loadingMore) {
      setLoadingMore(true);

      dispatch(
        get_sales_equipment_by_group_paginated({
          groupId: groupId,
          equipmentId: equipment.id,
          ordering: "-date",
          limit: limit,
          offset: offset + limit,
        })
      ).then((result) => {
        setNextUrl(result.payload.next);
        setOffset(offset + limit);
        setLoadingMore(false);

        // Ajoutez les nouvelles données aux données existantes
        setEquipmentSalesLoader((prevSales) => [
          ...prevSales,
          ...result.payload.results,
        ]);
      });
    }
  };

  const handleScroll = () => {
    const tableElement = tableRef.current;
    if (
      tableElement &&
      tableElement.scrollTop + tableElement.clientHeight >=
        tableElement.scrollHeight - 10 // seuil à ajuster
    ) {
      loadMoreData();
    }
  };

  useEffect(() => {
    const tableElement = tableRef.current;
    tableElement.addEventListener("scroll", handleScroll);

    return () => {
      if (tableElement) {
        tableElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  const columns = [
    {
      title: t("table.tabColId"),
      dataIndex: "id",
      key: "id",
      width: "5%",
    },
    {
      title: t("table.tabColDate"),
      dataIndex: "date_probable",
      key: "date_probable",
      width: "15%",
      render: (text) => dateFormat(text),
    },
    {
      title: t("table.tabColProduct"),
      dataIndex: "retails",
      key: "retails",
      align: "left",
      bold: true,
      //width: "205",
      render: (text, record) =>
        record.retails
          ?.map(
            (retail) =>
              retail.label_local +
              "(" +
              retail.local_id +
              ") " +
              (retail.quantity == 1 ? "" : " x" + retail.quantity)
          )
          .join(", "),
    },
    {
      title: t("table.tabColPayment"),
      dataIndex: "payments",
      key: "payments",
      align: "left",
      //width: 182,
      ellipsis: true,
      bold: true,
      render: (text, record) =>
        record.payments
          ?.filter(
            (payment, index, arr) =>
              arr.findIndex((p) => p.type_payment === payment.type_payment) ===
              index
          )
          .map((payment) => t(eCdbsPaymentType[payment.type_payment]))
          .join(", "), //filter les payments de même type pour n'afficher le type qu'une seule fois
    },
    {
      title: t("table.tabColAmount"),
      dataIndex: "price_total_selected",
      key: "price_total_selected",
      bold: true,
      width: "15%",
      render: (text) => <span>{amountFormatWithCurrencySymbol(text)}</span>,
    },
    {
      title: "",
      dataIndex: "",
      key: "actions",
      width: "5%",
      render: (text, record) => <EquipmentSaleDetails sale={record} />,
    },
  ];

    return (
        <div ref={tableRef} style={{ overflowY: "auto", height: "690px" }}>
            {!verifyPermission ?
                <PermissionDeniedPage
                    permission={verifyGroupPermission}
                />
            :
                <PermissionFadeContainer
                    permission={verifyGroupPermission}
                    opacity={50}
                >
                    <Table
                        columns={columns}
                        dataSource={equipmentSalesLoader}
                        style={style}
                        scroll={{ y: 690 }}
                        onScroll={loadMoreData}
                    />
                </PermissionFadeContainer>
            }
        </div>
    );
};