/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/device/e_sensor_data_type.py
Aucune modification manuelle acceptée
*/

// Enumération des types de données pouvant etre mesurées par des capteurs
const eSensorDataType = Object.freeze({
  // Température en °C
  0: 'eSensorDataType.celsius',

  // Humidité relative en %
  1: 'eSensorDataType.rhPercent',

  // Humidité relative en %
  2: 'eSensorDataType.lux',

  // Accélération sur l'axe X
  3: 'eSensorDataType.xg',

  // Accélération sur l'axe Y
  4: 'eSensorDataType.yg',

  // Accélération sur l'axe Z
  5: 'eSensorDataType.zg',

  // Position angulaire sur l'axe X
  6: 'eSensorDataType.xdeg',

  // Position angulaire sur l'axe Y
  7: 'eSensorDataType.ydeg',

  // Position angulaire sur l'axe Z
  8: 'eSensorDataType.zdeg',

  // Latitude
  9: 'eSensorDataType.latitude',

  // Longitude
  10: 'eSensorDataType.longitude',

  // Altitude
  11: 'eSensorDataType.altitude',
});

export default eSensorDataType;
