import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { store } from "redux/store";
import { Provider } from "react-redux";
import ThemeContextWrapper from "./themeContextWrapper";
import "./index.scss";
import "./locale/i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeContextWrapper>
    <Provider store={store}>
      <BrowserRouter basename="/manager">
        <App />
      </BrowserRouter>
    </Provider>
  </ThemeContextWrapper>,
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
