import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "../../../modUtils/components/componentsLibrary/componentsLibrary";
import { get_weather_by_site_list } from "../../slices/weatherSlice";
import { setMomentLocale } from "../../../modUtils/functions/dateFormat";
import moment from "moment";
import { Spin } from "antd";

export default function WeatherCard() {
  const dispatch = useDispatch();
  const [weather, setWeather] = useState(null);
  const [loading, setLoading] = useState(false);
  const { selectedGroup } = useSelector((state) => state.manager);
  const forecastDayNb = 5;
  const siteId = selectedGroup?.site_default?.id;
  const [errorMsg, setErrorMsg] = useState("Données météo non disponibles");
  setMomentLocale();
  const getDaysWeather = async () => {
    setLoading(true);      
    setWeather(null)
    try {
      let result = await dispatch(
        get_weather_by_site_list({ siteId, forecastDayNb })
      ).unwrap();
      if (result) {
        setWeather(result);
      }       
    } catch (error) {
      setErrorMsg(error.data.detail.detail);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (siteId) {
      getDaysWeather();
    }
  }, [siteId]);

  const meteoStyle = {
    card: {
      boxShadow: "none",
      borderRadius: "5px",
      margin: "4px 0",
      paddingBottom: "10px",
      border: "1px solid #338EF5",
      height: "fit-content",
    },
    cardBody: {
      padding: "5px 0",
    },
  };
  function dateMeteo(timestamp) {
    if (timestamp === null) return "";
    else {
      const d = parseInt(timestamp);
      return moment.unix(d).format("ddd DD");
    }
  }
  return (
    <Card width={"100%"} style={meteoStyle}>
      <div className="text-neutral-color-400 mb-2">
        Prévisions météo 5 jours
      </div>
      {(siteId && (selectedGroup?.site_default?.location_address !== null)) && (
        <div className="font-bold text-md	text-secondary-color-300 mb-2">
          {`${selectedGroup?.site_default?.location_address?.city}${selectedGroup?.site_default?.location_address?.zipcode ? ` - ${selectedGroup?.site_default?.location_address?.zipcode}`:""}`}
        </div>
      )}
      {loading ? (
        <Spin />
      ) : (
        <div className="text-secondary-color-300">
          {(siteId && weather) ? (
            <>
              {weather?.map((weather, index) => (
                <div
                  key={index}
                  id={`weather-row-${index}`}
                  className="px-2 py-0.5"
                >
                  <div className="flex-display flex-row justify-content-around align-items-center">
                    <div className="w-1/5">
                      <img
                        width={30}
                        src={weather?.day_image}
                        alt={weather?.day_weather_label}
                      />
                    </div>
                    <div className="w-4/5 text-center truncate ...">
                      {weather?.day_weather_label}
                    </div>
                  </div>
                  <div className="flex-display flex-row justify-content-around align-items-center text-[13px] font-semibold">
                    <div className="text-left" style={{ width: "30%" }}>
                      {dateMeteo(weather?.day_date)}
                    </div>
                    <div className="text-center" style={{ width: "70%" }}>
                      {weather?.day_mintemp}°C / {weather?.day_maxtemp}°C
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="font-semibold text-xs px-4">{errorMsg}</div>
          )}
        </div>
      )}
    </Card>
  );
}
