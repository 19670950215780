/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/event/e_event_severity.py
Aucune modification manuelle acceptée
*/

// Severité d'un évènement
const eEventSeverity = Object.freeze({
  // Légé
  0: 'eEventSeverity.slight',

  // Modéré
  1: 'eEventSeverity.moderate',

  // Haute
  2: 'eEventSeverity.high',
});

export default eEventSeverity;
