import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary";
import HomeHero from "ck_commun/src/app/modUtils/components/homeHero/HomeHero";
import { get_equipments_gb_sites_by_group_paginated } from "ck_commun/src/app/modEquipment/slices/equipmentSlice";
import { get_dashboard_cumulatives_latest_by_group } from "ck_commun/src/app/modCumulative/slices/cumulativeSlice";
import { hasPermission } from "ck_commun/src/app/modUtils/functions/handlePermissions";
import WidgetIncident from "ck_commun/src/app/modEquipment/components/widgetIncident/WidgetIncident";
import WidgetCAHebdo from "ck_commun/src/app/modCumulative/components/WidgetCAHebdo";
import WidgetCAMensual from "ck_commun/src/app/modCumulative/components/WidgetCAMensual";
import WidgetCAAnnual from "ck_commun/src/app/modCumulative/components/widgetCAAnnual";
import WidgetCADaily from "ck_commun/src/app/modCumulative/components/WidgetCADaily";
import moment from "moment";
import "./Home.scss";

export default function Home() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { groupId, loadingGroup, selectedGroup } = useSelector(
    (state) => state.manager,
  );
  const { user } = useSelector((state) => state.authentication);

  const permissions = selectedGroup?.permissions;
  const verifyPermission = hasPermission('mod_cumulative:show_statistic', permissions);
  const groupPermissions = selectedGroup?.plan?.permissions;
  const verifyGroupPermission = hasPermission('mod_cumulative:show_statistic', groupPermissions);

  const range = 7;

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        // Create an array of promises
        const promises = [
          dispatch(get_equipments_gb_sites_by_group_paginated({ groupId, limit:100, offset:0 })),
          dispatch(
            get_dashboard_cumulatives_latest_by_group({ groupId, range }),
          ),
        ];

        await Promise.all(promises);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    }
    if (groupId) {
      fetchData();
    }
  }, [groupId]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="home-container">
      <h1 id="home-msg">
        {!loading && `Bonjour  ${user.first_name} ${user.last_name}`}
      </h1>
      <h3 id="home-msg">
        {!loading && `Nous sommes le ${moment().format("DD-MM-YYYY HH:mm")}`}
      </h3>
      {!verifyPermission ? 
                <HomeHero logo={process.env.PUBLIC_URL + "/laundryLogo.png"}/>
            : 
                
                <div className="widget-container flex-display flex-row flex-wrap  justify-content-center ">
                    <WidgetCADaily loading={loading}/>
                    <WidgetCAHebdo loading={loading}/>
                    <WidgetCAMensual loading={loading}/>
                    <WidgetCAAnnual loading={loading}/>
                    <WidgetIncident loading={loading}/>
                </div>
            }
    </div>
  );
}
