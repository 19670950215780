/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/system/e_language.py
Aucune modification manuelle acceptée
*/

// Enumération des langues potentiellement prise en charge
const eLanguage = Object.freeze({
  // Français
  0: 'eLanguage.fr',

  // Anglais
  1: 'eLanguage.en',
});

export default eLanguage;
