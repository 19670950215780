import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpGet, httpPost, httpPut } from "../../modUtils/apis/httpConfig";

export const get_cash_boxes_by_group_paginated = createAsyncThunk(
  "mod_statement/get_cash_boxes_by_group_paginated",
  async (values, { rejectWithValue }) => {
    try {
      const response = await httpGet(
        `/mod_statement/groups/${values.groupId}/cash_boxes/`,
        {
          params: {
            limit: values.limit,
            offset: values.offset,
            is_old: isOld,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const create_cash_boxes_by_group = createAsyncThunk(
  "mod_statement/create_cash_boxes_by_group",
  async (values, { rejectWithValue }) => {
    try {
      const response = await httpPost(
        `mod_statement/groups/${values.groupId}/cash_boxes/`,
        {
          params: {
            name: values.name,
            site: values.site,
            cash_box_equipments: values.cashBoxEquipments,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const update_cash_box_detail_by_group = createAsyncThunk(
  "mod_statement/update_cash_box_detail_by_group",
  async (values, { rejectWithValue }) => {
    try {
      const response = await httpPut(
        `mod_statement/groups/${values.groupId}/cash_boxes/${values.cashBoxId}`,
        {
          is_old: isOld,
          name: values.name,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const get_statements_by_group_paginated = createAsyncThunk(
  "mod_statement/get_statements_by_group_paginated",
  async (values, { rejectWithValue }) => {
  
    try {
      const response = await httpGet(
        `/mod_statement/groups/${values.groupId}/statements/`,
        {
          params: {
            groupId: values.groupId,
            date_start: values.dateStart,
            date_end: values.dateEnd,
            cash_boxes: values.cashBoxes,
            sites: values.sites ? values.sites : null,
            equipments: values.equipments ? values.equipments : null,
            limit: values.limit,
            offset: values.offset
          },
        }
      );  
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const update_statement_detail_detail_by_statement_by_group =
  createAsyncThunk(
    "mod_statement/update_statement_detail_by_statement_by_group",
    async (values, { rejectWithValue }) => {
      try {
        const response = await httpPut(
          `mod_statement/groups/${values.groupId}/statements/${values.statementId}/details/${values.detailId}/`,
          { amount_cashbox_counted: values.amountCashboxCounted }
        );
        return response.data;
      } catch (error) {
        return rejectWithValue({
          status: error.response.status,
          data: error.response.data,
        });
      }
    }
  );

export const create_statements_start_by_cashbox_by_group = createAsyncThunk(
  "mod_statement/create_statements_start_by_cashbox_by_group",
  async (values, { rejectWithValue }) => {
    try {
      const response = await httpPost(
        `mod_statement/groups/${values.groupId}/cash_boxes/${values.cashBoxId}/statements/start/`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

const statementSlice = createSlice({
  name: "statement",
  initialState: {
    statements: [],
    statementsCount: 0,
    cashBoxes: [],
    cashBoxDetail: {},
    statementDetailDetail: {},
    loading: true,
  },
  extraReducers: (builder) => {
    builder
      //get_cash_boxes_by_group_paginated
      .addCase(get_cash_boxes_by_group_paginated.fulfilled, (state, action) => {
        state.loading = false;
        state.cashBoxes = action.payload.results;
      })
      .addCase(get_cash_boxes_by_group_paginated.rejected, (state, action) => {
        state.loading = false;
      })
      //create_cash_boxes_by_group
      .addCase(create_cash_boxes_by_group.fulfilled, (state, action) => {
        state.loading = false;
        state.cashBoxes = [...state.cashBoxes, { ...action.payload }];
      })
      .addCase(create_cash_boxes_by_group.rejected, (state, action) => {
        state.loading = false;
      })
      //update_cash_box_detail_by_group
      .addCase(update_cash_box_detail_by_group.fulfilled, (state, action) => {
        const updatedCashBox = action.payload;
        state.cashBoxes = state.cashBoxes?.map((cashBox) =>
          cashBox.id === updatedCashBox.id
            ? { ...cashBox, ...updatedCashBox }
            : cashBox
        );
        state.loading = false;
      })
      .addCase(update_cash_box_detail_by_group.rejected, (state, action) => {
        state.loading = false;
      })
      // get_statements_by_group_paginated
      .addCase(get_statements_by_group_paginated.fulfilled, (state, action) => {
        state.loading = false;
        state.statements = action.payload.results;
        state.statementsCount = action.payload.count;
      })
      .addCase(get_statements_by_group_paginated.rejected, (state, action) => {
        state.loading = false;
      })
      // update_statement_detail_detail_by_statement_by_group
      .addCase(
        update_statement_detail_detail_by_statement_by_group.fulfilled,
        (state, action) => {
          const updatedStatementDetail = action.payload;
          state.statementDetailDetail = updatedStatementDetail;
          state.statements = state.statements?.details?.map((statementDetail) =>
            statementDetail.id === updatedStatementDetail.id
              ? { ...statementDetail, ...updatedStatementDetail }
              : statementDetail
          );
          state.loading = false;
        }
      )
      .addCase(
        update_statement_detail_detail_by_statement_by_group.rejected,
        (state, action) => {
          state.loading = false;
        }
      )
      // create_statements_start_by_cashbox_by_group
      .addCase(
        create_statements_start_by_cashbox_by_group.fulfilled,
        (state, action) => {
          state.loading = false;
          state.statements = [...state.statements, { ...action.payload }];
        }
      )
      .addCase(
        create_statements_start_by_cashbox_by_group.rejected,
        (state, action) => {
          state.loading = false;
        }
      );
  },
});

export default statementSlice.reducer;
