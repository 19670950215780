import React, { useState, useEffect } from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  Input,
  Button,
} from "../../../modUtils/components/componentsLibrary/componentsLibrary";
import {
  delete_credit_by_equipment,
  get_credit_by_equipment,
  create_credit_by_equipment,
} from "../../../modFixing/slices/fixingSlice";
import PermissionDeniedPage from "../../../modUtils/components/errorPage/PermissionDeniedPage";
import { hasPermission } from "../../../modUtils/functions/handlePermissions";
import { message } from "antd";
import AlertMessage from "../../../modUtils/components/AlertMessage";
import "./EquipmentCredit.scss";

export default function EquipmentCredit({ equipment, siteId }) {
  const { groupId, selectedGroup } = useSelector((state) => state.manager);
  const { equipmentCredit } = useSelector((state) => state.fixing);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState();
  const permissions = selectedGroup?.permissions;
  const verifyPermission = hasPermission(
    "mod_equipment:can_fixing",
    permissions
  );
  const groupPermissions = selectedGroup?.plan?.permissions;
  const verifyGroupPermission = hasPermission(
    "mod_equipment:can_fixing",
    groupPermissions
  );
  const getCreditByEquipment = async () => {
    setInputValue();
    try {
      setLoading(true);
      await dispatch(
        get_credit_by_equipment({
          groupId,
          siteId,
          equipmentId: equipment.id,
        })
      ).unwrap();
    } catch (error) {
      message.error({
        content: (
          <AlertMessage
            status={error?.status}
            alertMessage={`Une erreur est survenue!`}
            errorDetail={error?.data?.detail}
          />
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (verifyPermission && groupId && siteId && equipment) {
      getCreditByEquipment();
    }
  }, [equipment.id]);
  const deleteCreditEquipment = async () => {
    setLoading(true);
    try {
      await dispatch(
        delete_credit_by_equipment({
          groupId,
          siteId,
          equipmentId: equipment.id,
        })
      ).unwrap();
    } catch (error) {
      message.error({
        content: (
          <AlertMessage
            status={error?.status}
            alertMessage={`Une erreur est survenue!`}
            errorDetail={error?.data?.detail}
          />
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  const createCreditEquipment = async (type) => {
    try {
      setLoading(true);
      await dispatch(
        create_credit_by_equipment({
          groupId,
          siteId,
          equipmentId: equipment.id,
          value: inputValue,
        })
      ).unwrap();
    } catch (error) {
      message.error({
        content: (
          <AlertMessage
            status={error?.status}
            alertMessage={`Une erreur est survenue!`}
            errorDetail={error?.data?.detail}
          />
        ),
      });
    } finally {
      setLoading(false);
      setInputValue();
    }
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };
  return (
    <>
      {!verifyPermission ? (
        <PermissionDeniedPage size="sm" permission={verifyGroupPermission} />
      ) : (
        <div className="flex-display flex-column align-items-center">
          <div
            className="flex-display flex-column align-items-center"
            style={{ position: "relative" }}
          >
            <Input
              label="Crédit actif"
              name="creditActif"
              value={equipmentCredit}
              disabled
            />
            <Button
              type="primary"
              onClick={deleteCreditEquipment}
              htmltype="reset"
              style={{
                backgroundColor: "#FF0000",
                borderColor: "#FF0000",
                height: "40px",
              }}
            >
              Supprimer crédit
            </Button>{" "}
            <img
              className="refreshAmount"
              src={process.env.PUBLIC_URL + "/img/refreshIcon.png"}
              alt="refresh"
              onClick={() => getCreditByEquipment()}
            />
          </div>

          <div
            className="flex-display flex-column align-items-center "
            style={{ marginTop: "10%" }}
          >
            <Input
              type="number"
              label="Montant à créditer"
              defaultValue={inputValue}
              name="montantACrediter"
              onChange={handleChange}
            />

            <Button
              htmltype="submit"
              type="primary"
              onClick={createCreditEquipment}
              style={{
                backgroundColor: "#36C31D",
                borderColor: "#36C31D",
                height: "40px",
              }}
            >
              Créditer <ArrowRightOutlined />
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
