import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, DatePicker, Select } from "antd";
import {
  Modal,
  Input,
  Spinner,
} from "../../../modUtils/components/componentsLibrary/componentsLibrary";
import DismissButton from "../../../modUtils/components/buttons/DismissButton";
import FormLegend from "../../../modUtils/components/FormLegend";
import moment from "moment";
import {
  create_entity_customers,
  update_entity_customer_detail,
  create_entity_companies,
  get_entity_company_detail,
  get_entity_customer_detail,
  update_entity_company_detail,
} from "../../../modEntity/slices/entitySlice";
import { update_support_entity_detail } from "../../../modWalletLocal/slices/walletLocalSlice";
import CityInput from "../../../modLocation/components/CityInput";
import { message } from "antd";
import AlertMessage from "../../../modUtils/components/AlertMessage";
import "./SupportCustomerInfosModal.scss";

const entityTypeOptions = [
  { label: "Société", value: "company" },
  { label: "Utilisateur / Particulier", value: "customer" },
];
var modalStyle = {
  modalContent: {
    minWidth: "500px",
    width: "40%",
    minHeight: "50%",
  },
};
function SupportCustomerInfosModal({
  modalVisible,
  setModalVisible,
  entity,
  supportId,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { groupId } = useSelector((state) => state.manager);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
  const formatBirthDate = "DD/MM/YYYY";
  let entityCustomer = entity?.entity_customer;
  let entityCompany = entity?.entity_company;
  const { entityCompanyDetail, entityCustomerDetail } = useSelector(
    (state) => state.entity
  );
  const isEdit = !!entity;
  const [entityType, setEntityType] = useState(
    (entityCompany && "company") ||
      (entityCustomer && "customer") ||
      entityTypeOptions[0].value
  );
  const [selectedCity, setSelectedCity] = useState();
  //récupérer les détails de l'entité
  const getEntityDetail = async (type) => {
    setEntityType(type);
    let promise = null;
    switch (type) {
      case "company":
        promise = get_entity_company_detail({
          groupId,
          entityCompanyId: entity?.id,
        });

        break;
      case "customer":
        promise = get_entity_customer_detail({
          groupId,
          entityCustomerId: entity?.id,
        });

        break;
    }
    if (promise !== null)
      try {
        setLoading(true);
        await dispatch(promise).unwrap();
        setLoading(false);
      } catch (error) {
        message.error({
          content: (
            <AlertMessage
              status={error?.status}
              alertMessage={`Une erreur est survenue lors de la récupération des informations de ce client !`}
              errorDetail={error?.data?.detail}
            />
          ),
        });
        setLoading(false);
        setModalVisible(false);
      }
  };
  useEffect(() => {
    groupId && entity && getEntityDetail(entityType);
  }, [groupId, entity, entityCompany, entityCustomer]);

  useEffect(() => {
    !isEdit && form.resetFields();
    if (entity) {
      setEntityType(
        (entityCompany && "company") || (entityCustomer && "customer")
      );
      let address =
        entityCustomerDetail?.location_address ||
        entityCompanyDetail?.location_address;

      setSelectedCity({
        id: address?.city_id,
        postalCode: address?.zipcode,
        name: address?.city,
      });
      form.setFieldsValue({
        entityType:
          entityType ||
          entityCustomerDetail?.entity_type ||
          entityCompanyDetail?.entity_type ||
          null,
        name:
          entityType === "customer"
            ? entityCustomerDetail?.name
            : entityType == "company"
            ? entityCompanyDetail?.name
            : "",
        firstName: entityCustomerDetail?.first_name || "",
        email: entityCustomerDetail?.email || entityCompanyDetail?.email || "",
        locationStreet:
          entityCustomerDetail?.location_address?.street ||
          entityCompanyDetail?.location_address?.street ||
          "",
        locationNumber:
          entityCustomerDetail?.location_address?.street_number ||
          entityCompanyDetail?.location_address?.street_number ||
          "",

        country:
          entityCustomerDetail?.location_address?.country ||
          entityCompanyDetail?.location_address?.country ||
          "",
        phoneMobile: entityCustomerDetail?.phone_mobile || "",
        dateOfBirth: entityCustomerDetail?.date_of_birth
          ? moment.unix(entityCustomerDetail?.date_of_birth)
          : null,
        siret: entityCompanyDetail?.siret || "",
      });
    }
  }, [entityCompanyDetail, entityCustomerDetail, entity, form]);
  //ajouter une entité
  const addNewEntity = async (values, type) => {
    let promise = null;
    switch (type) {
      case "company":
        promise = create_entity_companies({ groupId, ...values });
        break;
      case "customer":
        promise = create_entity_customers({ groupId, ...values });
        break;
    }
    if (promise !== null)
      try {
        setConfirmLoading(true);
        await dispatch(promise).unwrap();
        setConfirmLoading(false);
        setModalVisible(false);
      } catch (error) {
        message.error({
          content: (
            <AlertMessage
              status={error?.status}
              alertMessage={`Une erreur est survenue lors de l'ajout d'un nouveau client !`}
              errorDetail={error?.data?.detail}
            />
          ),
        });
        setConfirmLoading(false);
      }
  };

  //modifier les informations de l'entité
  const updateEntity = async (values, type) => {
    let promise = null;
    let updatedEntity = null;

    switch (type) {
      case "company":
        promise = update_entity_company_detail({
          groupId,
          entityCompanyId: entity?.id,
          ...values,
        });
        break;
      case "customer":
        promise = update_entity_customer_detail({
          groupId,
          entityCustomerId: entity?.id,
          ...values,
        });
        break;
    }

    if (promise !== null) {
      try {
        setConfirmLoading(true);
        updatedEntity = await dispatch(promise).unwrap();
        // Si l'entité a été mise à jour avec succès, appelle update_support_entity_detail
        if (updatedEntity) {
          dispatch (update_support_entity_detail({ supportId, updatedEntity: values }));
        }
      } catch (error) {
        message.error({
          content: (
            <AlertMessage
              status={error?.status}
              alertMessage={`Une erreur est survenue lors de la modification des informations du client!`}
              errorDetail={error?.data?.detail}
            />
          ),
        });
      } finally {
        setConfirmLoading(false);
        setModalVisible(false);
      }
    }
  };

//onFinish appelée lors du submit du formulaire
  const onFinish = (values) => {
    const formattedValues = {
      ...values,
      locationCity: selectedCity?.id || null
    }
    const formattedValuesWithDate = {
      ...values,
      locationCity: selectedCity?.id || null,
      dateOfBirth: values?.dateOfBirth
        ? Math.floor(values?.dateOfBirth.valueOf() / 1000)
        : null, //timestamp en secondes
    };
    if (entity) {
      if (entityType == "company") {
        updateEntity(formattedValues, entityType);
      } else if (entityType == "customer") {
        updateEntity(formattedValuesWithDate, entityType);
      }
    } else {
      if (entityType == "company") {
        addNewEntity(formattedValues, entityType);
      } else if (entityType == "customer") {
        addNewEntity(formattedValuesWithDate, entityType);
      }
    }
  };

  const onReset = () => {
    setModalVisible(false);
  };

  return (
    <>
      <Modal
        title={isEdit ? "Informations Client" : t("badge.badgeAddModalTitle")}
        isOpen={modalVisible}
        onClose={onReset}
        zIndex={1001}
        style={modalStyle}
        //onCancel={() => setModalVisible(false)}
        // onOk={formik.handleSubmit}
        // okText={isEdit ? "Enregistrer" : "Ajouter"}
      >
        {loading ? (
          <Spinner />
        ) : (
          <div className="entity-infos-modal-form">
            <Form.Item
              label="Type d'entité"
              name="entityType"
              rules={[
                {
                  required: true,
                  message: "Veuillez choisir un type d'entité",
                },
              ]}
            >
              <Select
                placeholder="Choisir un type"
                onChange={setEntityType}
                defaultValue={entityType}
                disabled={entity}
                className="select-customize-input"
              >
                {entityTypeOptions?.map((type) => (
                  <Option value={type?.value}>{type?.label}</Option>
                ))}
              </Select>
            </Form.Item>

            <Form form={form} onFinish={onFinish} layout="vertical">
              {entityType == "customer" && (
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-2">
                  <Form.Item
                    label="Nom"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez entrer le nom du client",
                      },
                    ]}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                  <Form.Item
                    label="Prénom"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez entrer le prénom du client",
                      },
                    ]}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </div>
              )}
              {entityType == "company" && (
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-2">
                  <Form.Item
                    label="Nom de la société"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez entrer le nom de la société",
                      },
                    ]}
                  >
                    <Input placeholder="" />
                  </Form.Item>

                  <Form.Item
                    label="SIRET"
                    name="siret"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez entrer le SIRET",
                      },
                    ]}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </div>
              )}
              <div className="grid grid-cols-4 gap-0 lg:gap-2">
                <div className="col-span-4 xl:col-span-1">
                  <Form.Item label="N° adresse" name="locationNumber">
                    <Input type="number" placeholder="" />
                  </Form.Item>
                </div>
                <div className="col-span-4 xl:col-span-3">
                  <Form.Item label="Rue" name="locationStreet">
                    <Input placeholder="" />
                  </Form.Item>
                </div>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-2">
                <Form.Item label="Code Postal - Ville" name="locationCity">
                  <CityInput
                    defaultValue={selectedCity}
                    setValue={setSelectedCity}
                  />
                </Form.Item>
                <Form.Item label="Pays" name="country">
                  <Input placeholder="" />
                </Form.Item>
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-2">
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Veuillez entrer l'email",
                    },
                    {
                      type: "email",
                      message: "Veuillez entrer un E-mail valide",
                    },
                  ]}
                >
                  <Input type="email" placeholder="" />
                </Form.Item>
                <Form.Item label="N° Téléhpone" name="phoneNumber">
                  <Input type="number" placeholder="" />
                </Form.Item>
              </div>
              {entityType == "customer" && (
                <div className="flex flex-col items-start">
                  <Form.Item label="Date de naissance" name="dateOfBirth">
                    <DatePicker
                      format={formatBirthDate}
                      //suffixIcon={}
                    />
                  </Form.Item>
                </div>
              )}
              <FormLegend requiredFormItemLegend={true} />
              <div className="justify-center flex flex-row gap-2 mt-2">
                <DismissButton onClick={onReset} />
                <button
                  htmltype="submit"
                  className="bg-success-color px-4 py-2 rounded shadow cursor-pointer font-bold ease-in-out border-0 text-white text-nowrap hover:bg-success-color-600"
                >
                  {isEdit ? "Enregistrer" : "Ajouter"}
                </button>
              </div>
            </Form>
          </div>
        )}
      </Modal>

      {confirmLoading && <Spinner />}
    </>
  );
}

export default SupportCustomerInfosModal;
