/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/payment/e_cdbs_group_payment_type.py
Aucune modification manuelle acceptée
*/

// Type du groupe de paiements
const eCdbsGroupPaymentType = Object.freeze({
  // Réel
  0: 'eCdbsGroupPaymentType.real',

  // Support Intermédiaire
  1: 'eCdbsGroupPaymentType.intermediate',

  // Groupe gratuit
  2: 'eCdbsGroupPaymentType.free',
});

export default eCdbsGroupPaymentType;
