import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import "./Select.scss";

const Select = ({
  defaultActiveFirstOption,
  defaultOpen,
  defaultValue,
  disabled,
  dropdownMatchSelectWidth,
  fieldNames = { label: "label", value: "value", options: "options" },
  open: controlledOpen,
  options,
  placeholder,
  placement,
  width,
  selectDropdownMaxHeight,
  value: controlledValue,
  onDeselect,
  onSelect,
  multiple,
  onlyOption,
  selectLabel,
  searchInput,
  searchToSelect,
  onSearchToSelectInputChange,
  showArrow,
  style = {},
  block,
  onPressEnter,
  onPopupScroll,
  msgDisplayedWhenNoOptions,
  searchInputValue,
  resetValue
}) => {
  const [open, setOpen] = useState(defaultOpen);
  const [selectedOptionsList, setSelectedOptionsList] = useState(
    defaultValue || (defaultActiveFirstOption && [options[0].value]) || []
  );
  const [filteredOptions, setFilteredOptions] = useState(options);
  const selectWidth = block ? "100%" : width;
  const selectRef = useRef(null);
  const menuRef = useRef(null); // Ref to track dropdown menu container

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onPressEnter(selectedOptionsList, event?.target?.value);
    }
  };
  useEffect(() => {
    // Attach scroll event listener to dropdown menu
    if (menuRef.current) {
      menuRef.current.addEventListener("scroll", handlePopupScroll);
      return () => {
        if (menuRef.current) {
          menuRef.current.removeEventListener("scroll", handlePopupScroll);
        }
      };
    }
  }, []);

  const handlePopupScroll = () => {
    if (onPopupScroll) {
      // Call onPopupScroll if provided
      onPopupScroll(menuRef.current.scrollTop);
    }
  };

  useEffect(() => {
    // Add event listener to detect clicks outside the select component
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (event) => {
    // Check if the clicked element is outside the select component
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setOpen(false);
      searchInputValue && resetValue && resetValue()  
    }
  };

  /** handleClick function:
It takes an event object as a parameter.
It first checks if the select component is not disabled and if the clicked element does not have the class name "select-option-element".
If the conditions are met, it toggles the state of open to open/close the select dropdown. */

  const handleClick = (event) => {
    if (
      !disabled &&
      !event.target.classList.contains("select-option-element") &&
      !event.target.classList.contains("select-option-label")
    ) {
      setOpen(!open);
    }
  };
  {
    /** handleSelect function:
It takes an option object as a parameter, representing the selected option from the dropdown.
It extracts the selected value from the option object using the fieldNames.value property.
It checks if the selectedOptionsList (an array of currently selected options) includes the selected value.
If the value is already in the selectedOptionsList, it calls the handleDeselect function, passing the selected value as an argument.
If the value is not in the selectedOptionsList, it performs the following steps:
  -If multiple is true (indicating multiple selection is allowed), it adds the selected value to the selectedOptionsList array using the spread operator, and calls the onSelect function with the selected value and the option object as arguments.
  -If multiple is false (indicating single selection), it sets the selectedOptionsList to an array containing only the selected value, and calls the onSelect function.
If multiple is false, it closes the select dropdown by setting the open state to false. */
  }
  const handleSelect = (option, groupValue) => {
    const selectedValue = option[fieldNames.value];
    if (onlyOption) {
      onSelect(selectedValue, selectedOptionsList, setSelectedOptionsList);
    } else {
      if (selectedOptionsList?.includes(selectedValue)) {
        handleDeselect(selectedValue);
      } else {
        let tmpSelectedList = [];
        if (multiple) {
          tmpSelectedList = [...selectedOptionsList, selectedValue];
        } else {
          tmpSelectedList = [selectedValue];
        }
        setSelectedOptionsList(tmpSelectedList);
        onSelect(selectedValue, option, tmpSelectedList);
      }
      if (!multiple) {
        setOpen(false);
      }
    }
  };
  {
    /**handleDeselect function removes the selectedValue from the selectedOptionsList array, 
    updates the state with the new array (or an empty array if all options are deselected), 
    and triggers the onDeselect callback function to notify the parent component about the deselection event. */
  }
  const handleDeselect = (selectedValue) => {
    const newValue = selectedOptionsList?.filter(
      (val) => val !== selectedValue
    );
    if (newValue?.length > 0) {
      //checks if the length of the newValue array is greater than 0. If it is, it means there are still selected options remaining, so it calls setSelectedOptionsList(newValue) to update the state with the new array of selected options
      setSelectedOptionsList(newValue);
    } else {
      let tab =
        defaultValue || (defaultActiveFirstOption && [options[0].value]) || [];
      //If the length of newValue is 0, it means all options have been deselected, so it calls setSelectedOptionsList(defaultValue || (defaultActiveFirstOption && [options[0].value]) || []) to reset the state with defaultValue if it exists, or the first option if defaultActiveFirstOption is true or with an empty array .
      setSelectedOptionsList(
        defaultValue || (defaultActiveFirstOption && [options[0].value]) || []
      );
    }

    onDeselect(selectedValue, newValue);
  };

  // retourner la liste des options
  const renderOption = (option) => {
    const isSelected = selectedOptionsList?.includes(option[fieldNames.value]);

    if (option.options) {
      // Render option group
      return (
        <div key={option.groupValue} className="select-option-group">
          <div className="select-option-group-label">{option.label}</div>
          {option.options.map((groupOption) => (
            <div
              className={`select-option-element ${
                isSelected ? "selected" : ""
              }`}
              key={groupOption.value}
              onClick={() => handleSelect(groupOption, option.groupValue)}
            >
              {multiple ? (
                <label
                  htmlFor={groupOption.value}
                  className="select-option-label"
                >
                  <input
                    className="select-option-label"
                    readOnly
                    type="checkbox"
                    value={groupOption.value}
                    checked={selectedOptionsList.includes(groupOption.value)}
                  />
                  <span className="select-option-label">
                    {groupOption[fieldNames.label]}
                  </span>
                </label>
              ) : (
                <span className="select-option-label">
                  {groupOption[fieldNames.label]}
                </span>
              )}
            </div>
          ))}
        </div>
      );
    }

    // Render regular option
    return (
      <div
        className={`select-option-element ${isSelected ? "selected" : ""}`}
        key={option[fieldNames.value]}
        onClick={() => handleSelect(option)}
      >
        {multiple ? ( //si le select à choix multiple ajouter un checkbox avant
          <label htmlFor={option.value} className="select-option-label">
            <input
              className="select-option-label"
              readOnly
              type="checkbox"
              value={option.value}
              checked={selectedOptionsList.includes(option.value)}
            />
            <span className="select-option-label">
              {option[fieldNames.label]}
            </span>
          </label>
        ) : (
          // sinon afficher que le label de l'option
          <span className="select-option-label">
            {option[fieldNames.label]}
          </span>
        )}
      </div>
    );
  };
  const handleSearch = (searchTerm) => {
    const filteredOptions = options?.filter((option) =>
      option[fieldNames.label].toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredOptions(filteredOptions);
  };

  const getOptionLabel = (val) => {
    if (val?.length > 0) {
      const label = options.find(
        (option) => option[fieldNames.value] === val[0]
      )?.[fieldNames.label];
      return label;
    }
  };

  return (
    <div className="flex-display flex-row align-items-end">
      <div
        className="flex-display flex-column select-container"
        ref={selectRef}
      >
        <div className="select-label" style={{ ...style.selectLabel }}>
          {selectLabel}
        </div>
        {searchToSelect ? (
          <div
            className={`select search-to-select ${open ? "open" : ""} ${
              disabled ? "disabled" : ""
            }`}
            style={{
              width: selectWidth,
              ...style.select,
              ...style.open,
              //paddingLeft: '10px',
            }}
            onClick={handleClick}
          >
            <div
              className="select-to-search-input flex-display flex-row justify-content-between"
              style={{ ...style.selectToSearchInput, width: '100%' }}
            >
              <input
                type="text"
                className="select-to-search-input"
                placeholder={placeholder}
                onChange={(e) => {

                    handleSearch(e.target.value); // Call the callback function if passed as prop , call the handleSearch function if not
                }}
                value={searchInputValue}
              />
              <img
                src={process.env.PUBLIC_URL + "/img/design/zoom.svg"}
                alt="zoom"
              />
            </div>
            {open && (
              <div
                className={`select-options ${placement} ${
                  !dropdownMatchSelectWidth ? "dropdownMatchSelectWidth" : ""
                }`}
                style={{
                  width: dropdownMatchSelectWidth && selectWidth,
                  maxWidth: dropdownMatchSelectWidth && selectWidth,
                  minWidth: dropdownMatchSelectWidth && selectWidth,
                  maxHeight : selectDropdownMaxHeight && selectDropdownMaxHeight,
                  overflowY : selectDropdownMaxHeight && 'auto',
                  ...style.selectOptions,
                }}
                ref={menuRef}
              >      
                { filteredOptions.length > 0
                  ? filteredOptions?.map((option) => renderOption(option)) : options.length > 0
                  ? options?.map((option) => renderOption(option))
                  : msgDisplayedWhenNoOptions}
              </div>
            )}
          </div>
        ) : (
          <div
            className={`select ${open ? "open" : ""} ${
              disabled ? "disabled" : ""
            }`}
            style={{ width: selectWidth, ...style.select, ...style.open }}
            onClick={handleClick}
          >
            <div className="select-input" style={{ ...style.selectInput }}>
              {!multiple && selectedOptionsList?.length > 0 ? (
                <div
                  className="selected-value"
                  style={{ ...style.selectedValue }}
                >
                  {selectedOptionsList?.map((val) => (
                    <span key={val}>
                      {
                        options.find(
                          (option) => option[fieldNames.value] === val
                        )?.[fieldNames.label]
                      }
                    </span>
                  ))}
                </div>
              ) : (
                <>
                  {defaultValue && !multiple ? (
                    getOptionLabel(defaultValue)
                  ) : (
                    <div
                      className="select-placeholder"
                      style={{ ...style.selectPlaceholder }}
                    >
                      {placeholder}
                    </div>
                  )}
                </>
              )}
            </div>
            {showArrow && (
              <div
                className={`select-arrow ${open ? "open" : ""}`}
                style={{ ...style.selectArrow }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/img/chevron-right.svg"}
                  alt="chevron"
                />
              </div>
            )}
            {open && (
              <div
                className={`select-options ${placement} ${
                  !dropdownMatchSelectWidth ? "dropdownMatchSelectWidth" : ""
                }`}
                style={{
                  width: dropdownMatchSelectWidth && selectWidth,
                  maxWidth: dropdownMatchSelectWidth && selectWidth,
                  minWidth: dropdownMatchSelectWidth && selectWidth,
                  maxHeight : selectDropdownMaxHeight && selectDropdownMaxHeight,
                  overflowY : selectDropdownMaxHeight && 'scroll',
                  ...style.selectOptions,
                }}
                ref={menuRef}
              >
                {options.length > 0
                  ? options?.map((option) => renderOption(option))
                  : msgDisplayedWhenNoOptions}
              </div>
            )}
          </div>
        )}
        <div className="flex-display flex-row flex-nowrap select-to-search-list">
          {searchToSelect &&
            multiple &&
            selectedOptionsList?.map((val) => (
              <div>
                <span key={val} className="select-to-search-selected">
                  {
                    options.find(
                      (option) => option[fieldNames.value] === val
                    )?.[fieldNames.label]
                  }
                </span>
                <span
                  className="select-to-search-remove"
                  onClick={() => {
                    handleDeselect(val);
                  }}
                >
                  X
                </span>
              </div>
            ))}
        </div>
      </div>
      {!multiple && searchInput && (
        <div
          className="select-search-input"
          style={{ ...style.selectSearchInput }}
        >
          <input
            type="text"
            placeholder={
              selectedOptionsList?.length > 0
                ? getOptionLabel(selectedOptionsList)
                : "Search"
            }
            onKeyDown={handleKeyPress}
          ></input>
        </div>
      )}
    </div>
  );
};

Select.propTypes = {
  defaultActiveFirstOption: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  defaultValue: PropTypes.array,
  disabled: PropTypes.bool,
  dropdownMatchSelectWidth: PropTypes.bool,
  fieldNames: PropTypes.object,
  open: PropTypes.bool,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  selectLabel: PropTypes.string,
  placement: PropTypes.oneOf([
    "bottom",
    "top",
    "bottom-left",
    "bottom-right",
    "top-left",
    "top-right",
  ]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.array,
  onDeselect: PropTypes.func,
  onSelect: PropTypes.func,
  multiple: PropTypes.bool,
  searchInput: PropTypes.bool,
  searchToSelect: PropTypes.bool,
  showArrow: PropTypes.bool,
  style: PropTypes.object,
  msgDisplayedWhenNoOptions: PropTypes.node,
  onSearchToSelectInputChange: PropTypes.func
};

Select.defaultProps = {
  placement: "bottom",
  open: false,
  options: [],
  fieldNames: { label: "label", value: "value", options: "options" },
  defaultOpen: false,
  multiple: false,
  width: 160,
  dropdownMatchSelectWidth: true,
  searchInput: false,
  showArrow: true,
  searchToSelect: false,
  onDeselect: () => {},
  onSelect: () => {},
  //onChange: () => {},
  msgDisplayedWhenNoOptions: "no data"
};

export default Select;
