import { Outlet, Navigate } from "react-router-dom";
import {
  isAccessTokenExpired,
  getAccessToken,
  decodeToken,
} from "ck_commun/src/app/modUtils/functions/handleTokens";
const PrivateRoutes = () => {
  const token = getAccessToken();
  let isLogged = token !== null && !isAccessTokenExpired();
  return isLogged ? <Outlet /> : <Navigate to="/" />;
};
export default PrivateRoutes;
