/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/domotic/e_domotic_status.py
Aucune modification manuelle acceptée
*/

// Status d'un évènement sur une domotique
const eDomoticStatus = Object.freeze({
  // Off
  0: 'eDomoticStatus.off',

  // On
  1: 'eDomoticStatus.on',
});

export default eDomoticStatus;
