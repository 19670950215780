/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/machines/e_machines_power.py
Aucune modification manuelle acceptée
*/

// Choix du mode de fonctionnement de l'alimentation d'une machine
const eMachinesPower = Object.freeze({
  // Allumer
  0: 'eMachinesPower.on',

  // Éteindre
  1: 'eMachinesPower.off',

  // Redémarrer
  2: 'eMachinesPower.offOn',
});

export default eMachinesPower;
