import React, { useState } from "react";
import PropTypes from "prop-types";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import "./Input.scss";

const Input = ({
  bordered,
  defaultValue,
  disabled,
  id,
  maxLength,
  classnames,
  status,
  size,
  width,
  type,
  value,
  placeholder,
  name,
  onBlur,
  onFocus,
  onChange,
  onPressEnter,
  label,
  style,
}) => {
  const [showPassword, setShowPassword] = useState(false); // État pour suivre si le mot de passe est visible ou non

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Inverse l'état actuel de visibilité du mot de passe
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && onPressEnter) {
      onPressEnter();
    }
  };

  return (
    <div className="input flex-display flex-column">
      <label htmlFor={id} className="input-label">
        {label}
      </label>
      <div
        className="input-wrapper flex-display flex-row justify-content-between align-items-center"
        style={{
          width: width,
          height: size,
          fontSize: size*0.35,
          ...style,
        }}
      >
        <input
          name={name}
          type={type === "password" && showPassword ? "text" : type} // Affiche le texte brut si showPassword est true
          id={id}
          defaultValue={defaultValue}
          value={value}
          disabled={disabled}
          maxLength={maxLength}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={handleKeyPress}
          className={classnames}
          style={{width:'100%'}}
        />
        {type === "password" && ( // Affiche l'icône d'œil uniquement pour les champs de type mot de passe
          <span
            className="password-toggle-icon"
            onClick={handleTogglePasswordVisibility}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        )}
      </div>
    </div>
  );
};

Input.defaultProps = {
  bordered: true,
  disabled: false,
  type: "text",
  size: 40
};

Input.propTypes = {
  bordered: PropTypes.bool,
  // defaultValue,
  disabled: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // maxLength,
  // classnames,
  // status,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // type,
  // value,
  // placeholder,
  // onChange,
  //onPressEnter,
};
export default Input;
