
import React , {FC} from "react";
import { useNavigate } from "react-router-dom";



interface HomeHeroProps {
    logo: string,
  }

const HomeHero : FC<HomeHeroProps> = ({logo}) => {
    const navigate = useNavigate();

    return (
    <section className="bg-white p-4">
        <div className="container flex flex-col items-center px-4 py-12 mx-auto">
        <img src={logo} className="w-75 h-75 bg-primary-color p-6 rounded shadow-xl" alt="logo" />
        <button 
            className="bg-primary-color px-4 py-2 mt-10 rounded shadow cursor-pointer font-bold ease-in-out border-0 text-white text-nowrap hover:bg-primary-color-600"
            onClick={() => {navigate("/equipment/sites-equipments")}}
            > Voir mes équipements
            </button> 
        </div>
    </section>                            
    )
  }
  

export default HomeHero;
