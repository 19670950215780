import React from 'react';
import Label from "../label/Label";

export default function Textarea({label, defaultValue, onBlur, onChange, className, ...props}) {

    return (
        <>
        {label && 
            <Label 
                label={label}
            />
        }
        
        <textarea
            defaultValue={defaultValue}
            onBlur={onBlur}
            onChange={onChange}
            className={`w-full border border-[#4D4D4D42] rounded-md text-primary-color ${className}`}
        >
            {props.children}
        </textarea>
        </>
    );
};